/* eslint-disable react-hooks/exhaustive-deps */
import FileInput from 'react-fine-uploader/file-input'
import Dropzone from 'react-fine-uploader/dropzone'
import FineUploaderTraditional from 'fine-uploader-wrappers'
import { useEffect, useState } from 'react'
import IconFile from '@material-ui/icons/FileCopy';
import colors from 'tailwindcss/colors'
import ProgressBar from '@ramonak/react-progress-bar';
import { Modal } from '../Modal';
import Alert from "@material-ui/lab/Alert";
import { nFormatter } from '../../helpers/nFormatter';

export default function DropzoneComponent({ allowedExtensions = ["txt"], multiple = true, showProgress = false, setUploadItems = null, type, concourseID, refresh, optionalParams = {} }) {
  const [uploadItem, setUploadItem] = useState([])

  const [showErrors, setShowErrors] = useState(false)
  const [openModalErrors, setOpenModalErrors] = useState(false)
  const authUserToken = JSON.parse(localStorage.getItem('unindo_sonhos_admin_data'));
  const uploadErrorList = uploadItem.filter((item) => item.error?.message)
  const allowedExtensionsText = allowedExtensions.join(', ').toUpperCase()

  useEffect(() => {
    setUploadItems && setUploadItems(uploadItem)
  }, [uploadItem])

  const uploader = new FineUploaderTraditional({
    options: {
      chunking: {
        concurrent: {
          enabled: true,
        },
        enabled: true,
        partSize: 5000000,
      },
      validation: {
        allowedExtensions,
      },
      callbacks: {
        onValidateBatch: () => {
          setUploadItems && setUploadItems([])
          setUploadItem([])
          setShowErrors(false)
        },
        onSubmit: async (id, fileName) => {
          setUploadItem((prevState) =>
            [
              ...prevState,
              {
                id: id + fileName,
                current: 0,
                total: 1,
                status: "uploading",
                finished: false,
                error: null,
                show: true,
                original_name: fileName,
              }
            ]
          )
        },
        onProgress: async (id, fileName, current, total) => {
          setUploadItem((state) => {
            return state.map((item) => {
              if (item.id === id + fileName) {
                const newItem = {
                  ...item,
                  current,
                  total,
                  processing: current === total,
                  status: "loading",
                }
                return newItem
              }
              return item
            })
          })
        },
        onAllComplete: async (succeeded, failed) => {
          if (succeeded.length > 0) {
            await refresh()
          }

          if (failed.length > 0 && showProgress) {
            setShowErrors(true)
          }

          setUploadItem((state) => {
            return state.map((item) => {
              return {
                ...item,
                show: false,
              }
            })
          })
        },
        onComplete: async (id, fileName, responseJSON) => {
          setUploadItem((state) => {
            return state.map((item) => {
              if (item.id === id + fileName) {
                const newItem = {
                  ...item,
                  finished: true,
                  status: "finished",
                  show: showProgress ? true : false,
                  error: {
                    fileName,
                    message: responseJSON.message,
                  },
                }
                return newItem
              }
              return item
            })
          })
        },
      },
      maxConnections: 3,
      request: {
        endpoint: `${process.env.REACT_APP_API_JACKPOT_URL}/uploads/${type}`,
        params: {
          concourse_id: parseInt(concourseID),
	        ...optionalParams,
        },
        customHeaders: {
          'Authorization': `Bearer ${authUserToken.token}`,
        },
      },
    },
  })

  function getFormattedBytes(item) {
    if (item.total === 1) {
      return "Aguardando..."
    }

    if (item?.processing && item.finished) {
      return "Processando..."
    }

    return `${nFormatter(item.current)} / ${nFormatter(item.total)}`
  }

  return (
    <div className='flex flex-col items-center justify-center w-full max-w-3xl mx-auto'>
      <Dropzone multiple={multiple} uploader={uploader} className="cursor-pointer rounded-lg w-full p-10 flex items-center justify-center border hover:border-solid transition border-dashed border-primary bg-[#F4F5F6]">
        <FileInput multiple={multiple} uploader={uploader} className="w-full">
          <div className='flex flex-col items-center justify-center'>
            <IconFile style={{ fill: colors.green[700] }} />
            <button type="button" className='mt-3 text-base font-semibold text-primary'>Clique ou arraste aquivos para essa área</button>
            <p className='mt-1 text-neutral-2 text-2xs'>Só são permitidos arquivos em {allowedExtensionsText}</p>
          </div>
        </FileInput>
      </Dropzone>
      {showProgress &&
        <Modal
          title="Erros de upload"
          noSubmit
          open={openModalErrors}
          setOpen={setOpenModalErrors}
        >
          <>
            <Alert className="cursor-pointer w-fit" severity="error">
              <div className="flex flex-col">
                <span className="font-semibold">Alguns arquivos não foram enviados.</span>
                <span>{uploadErrorList.length} arquivos falharam para importar para o sistema, verifique os erros abaixo e tente novamente.</span>
              </div>
            </Alert>
            <span className="mt-4 mb-3 font-bold">
              Arquivos com falha ({uploadErrorList.length})
            </span>
            <div className="table-main">
              <table>
                <thead>
                  <tr>
                    <th>
                      Arquivo
                    </th>
                    <th>
                      Descrição
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {uploadErrorList.map((item) => (
                    <>
                      <tr key={item.error?.fileName}>
                        <td>
                          {item.error?.fileName}
                        </td>
                        <td>
                          {item.error?.message}
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        </Modal>
      }
      {showErrors && (
        <div className="flex items-center justify-center my-4 mb-6">
          <Alert
            onClick={() => setOpenModalErrors(true)}
            className="cursor-pointer w-fit"
            severity="error"
          >
            {`De ${uploadItem.length === 1 ? uploadItem.length + ' arquivo' : uploadItem.length + " arquivos"}, ${uploadErrorList.length} estão com erro. `}
            <span className="font-medium underline">
              Clique aqui para ver os erros.
            </span>
          </Alert>
        </div>
      )}
      {showProgress && !showErrors && (
        <div className='flex flex-col gap-4 self-start w-full my-5 min-h-[50px]'>
          {uploadItem.map((item) => {
            if (item.finished && !item.show) {
              return null
            }

            return (
              <div key={item.id} className="relative flex flex-col w-full gap-2">
                <div className="flex items-center justify-between w-full">
                  <div className="flex items-center gap-2">
                    <span className="inline-block px-2 py-1 text-xs font-semibold text-gray-600 uppercase bg-gray-200 rounded-full">
                      {item.original_name}
                    </span>
                    <svg className={`${item.finished ? "block" : "hidden"} animate-spin h-4 w-4 text-primary`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                  </div>
                  <span className="text-xs font-semibold text-gray-600">
                    {getFormattedBytes(item)}
                  </span>
                </div>
                <ProgressBar
                  className="progress-bar-widget"
                  isLabelVisible={false}
                  height="9px"
                  bgColor={((item.current / item.total) * 100) === 100 ? "#209869" : "#D8C364"}
                  baseBgColor="#F4F5F6"
                  completed={(item.finished ? 100 : (item.current / item.total) * 100).toFixed()}
                />
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}
