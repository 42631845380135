import React, { useState } from 'react';

import { history }    from '../../../helpers/history';
import Button         from '../../../components/Button';
import termsService     from '../../../services/terms';
import Title          from '../../../components/Title';
import RichEditor          from '../../../components/RichEditor';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';

import Alert    from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';

import './style.scss';
import "react-datepicker/dist/react-datepicker.css";

const TermsNew = (props) => {
    const [ name,     setName ] = useState('');
    const [ contentText,   setContentText ] = useState('');
    const [ termFilePreview, setTermFilePreview ] = useState('');
    const [ termFile,    setTermFile    ] = useState('');
    

    const [ alert,     setAlert ] = useState({ show: false, severity: 'info', message: '' });
    const [ loading, setLoading ] = useState(false);

    const attachFileDesktop = (event) => {
        setTermFile(event.target.files[0]);
    };

    const removeImage = (type) => {
        setTermFile(''); setTermFilePreview('');
    }
    
    const submit = async (event) => {
        event.preventDefault();

        if(!name) {
            setAlert({ show: true, severity: 'warning', message: 'O campo Nome é obrigatório.' });
            setTimeout(() => setAlert({ show: false, severity: 'warning', message: '' }), 5000)
            return false;
        }

        if(!termFile && !termFilePreview) {
            setAlert({ show: true, severity: 'warning', message: 'O campo Arquivo é obrigatório.' });
            setTimeout(() => setAlert({ show: false, severity: 'warning', message: '' }), 5000)
            return false;
        }

        if(!contentText) {
            setAlert({ show: true, severity: 'warning', message: 'O campo Conteúdo do termo é obrigatório.' });
            setTimeout(() => setAlert({ show: false, severity: 'warning', message: '' }), 5000)
            return false;
        }

        setLoading(true);
        const formData = new FormData();
              formData.append('name',          name);
              formData.append('content_text',   contentText);
              formData.append('file', termFile);
        
        const response = await termsService.addTerm(formData);
        
        if(response) {
            setAlert({ show: true, severity: 'success', message: 'Cadastrado com sucesso!' });
            setLoading(false);
            
            setTimeout(() => {
                setAlert({ show: false, severity: 'warning', message: '' });
                history.push('/configuracoes/termos');
            }, 3000);
        }
        else {
            setAlert({ show: true, severity: 'warning', message: 'Não conseguimos salvar esse termo. Tente novamente mais tarde.' });
            setLoading(false);
            setTimeout(() => {  setAlert({ show: false, severity: 'warning', message: '' }); }, 5000);
        }
    }

    return (
        <main id="new-game">
            <Title label="Cadastrar Termo" />
            <form onSubmit={ submit } noValidate>
                <Collapse className="feedback" in={ alert.show }>
                    <Alert severity={ alert.severity }>{ alert.message }</Alert>
                </Collapse>
                <div className="row">
                    <div className="col-6 form-group">
                        <label htmlFor="term-title">Nome:</label>
                        <input type="text" className="form-control" id="term-title" required disabled={ loading } onChange={ event => setName(event.target.value) } />
                    </div>
                    <div className="col-6 form-group">
                        {
                            termFile 
                            ?   (<>
                                    <label>Arquivo:</label>
                                    <div style={{display: "flex", alignItems: "center", justifyContent:"space-between", width: "100%"}}>
                                        <p style={{margin: 0}}>{termFile.name}</p>
                                        <span style={{cursor: "pointer"}}><DeleteForeverOutlinedIcon onClick={ event => removeImage('desktop') } /></span>
                                    </div>
                                </>)
                            :   (<>
                                    <label htmlFor="term-file">Arquivo:</label>
                                    <input type="file" className="form-control" name="term-file" id="term-file" required disabled={ loading } onChange={ attachFileDesktop } accept=".docx, .pdf"/>
                                    <small>Atenção é aceito somente arquivos PDF e DOCX.</small>
                                </>)
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 form-group">
                        <label htmlFor="banner-title">Conteúdo:</label>
                        <RichEditor value={contentText} setValue={setContentText}/>
                    </div>
                </div>
                <div className="form-group">
                    <Button type="submit" width="40%" color="green submit-button" label="Enviar" loading={ loading } disabled={ loading } />
                </div>
            </form>
        </main>
    );
}

export default TermsNew;