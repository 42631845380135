import { addDays, addMonths, endOfMonth, endOfWeek, startOfMonth, startOfWeek, startOfYear, endOfYear, subDays } from 'date-fns'

export const predefinedPastRanges = [
	{ label: 'Hoje', value: [new Date(), new Date()], placement: 'left' },
	{ label: 'Ontem', value: [addDays(new Date(), -1), addDays(new Date(), -1)], placement: 'left' },
	{ label: 'Essa semana', value: [startOfWeek(new Date()), endOfWeek(new Date())], placement: 'left' },
	{ label: 'Últimos 7 dias', value: [subDays(new Date(), 6), new Date()], placement: 'left'	},
	{ label: 'Últimos 30 dias', value: [subDays(new Date(), 29), new Date()], placement: 'left'	},
	{ label: 'Esse mês', value: [startOfMonth(new Date()), new Date()], placement: 'left' },
	{ label: 'Mês passado', value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))], placement: 'left' }
]

export const predefinedFutureRanges = [
	{ label: 'Hoje', value: [new Date(), new Date()], placement: 'left' },
	{ label: 'Amanhã', value: [new Date(), addDays(new Date(), +1)], placement: 'left' },
	{ label: 'Essa semana', value: [startOfWeek(new Date()), endOfWeek(new Date())], placement: 'left' },
	{ label: 'Próximos 7 dias', value: [new Date(), addDays(new Date(), 6)], placement: 'left'	},
	{ label: 'Próximos 30 dias', value: [new Date(), addDays(new Date(), 29)], placement: 'left'	},
	{ label: 'Este mês', value: [startOfMonth(new Date()), endOfMonth(new Date())], placement: 'left' },
	{ label: 'Próximo mês', value: [startOfMonth(addMonths(new Date(), 1)), endOfMonth(addMonths(new Date(), 1))], placement: 'left' },
	{ label: 'Esse ano', value: [startOfYear(new Date()), endOfYear(new Date())], placement: 'left' }
]

const statusColors = [
	{
		color: '#D8C364',
		status: [
			{ "raw": "pending", "label": "Pendente" },
			{ "raw": "awaiting", "label": "Aguardando" },
		],
	},
	{
		color: '#4E9DCB',
		status: [
			{ "raw": "processing", "label": "Processando" },
			{ "raw": "paying", "label": "Pagando" },
		],
	},
	{
		color: '#209869',
		status: [
			{ "raw": "finished", "label": "Encerrado"	},
			{ "raw": "production", "label": "Em produção"	},
			{ "raw": "completed", "label": "Completo" },
			{ "raw": "paid_out", "label": "Pago" },
			{ "raw": "paid", "label": "Pago" },
			{ "raw": "active", "label": "Ativo" },
		],
	},
	{
		color: '#B1B5C4',
		status: [
			{ "raw": "draft", "label": "Rascunho"	},
			{ "raw": "expired", "label": "Expirado" },
			{ "raw": "inactive", "label": "Inativo" },
		],
	},
	{
		color: '#D86470',
		status: [
			{ "raw": "blocked", "label": "Bloqueado"	},
			{ "raw": "canceled", "label": "Cancelado" },
			{ "raw": "cancelled", "label": "Cancelado"	},
			{ "raw": "refunded", "label": "Reembolsado" },
			{ "raw": "no_payment", "label": "Não pago" },
		],
	},
	{
		color: '#CB784E',
		status: [
			{ "raw": "failed", "label": "Falhou" },
			{ "raw": "requested", "label": "Solicitado" },
		],
	},
]

export const getStatusItem = (status) => {
	const filterListStatus = statusColors.find(statusGroup => statusGroup?.status?.find(statusItem => statusItem?.raw === status))

	const statusItem = {
		color: filterListStatus?.color || "#B1B5C4",
		...filterListStatus?.status?.find(statusItem => statusItem?.raw === status) || ({ raw: 'not-defined', label: 'Não definido' })
	}

	return statusItem
}
