import React, { useEffect, useState } from 'react'
import moment from 'moment'

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import Button from '../../../components/Button'
import { history } from '../../../helpers/history'
import withdrawSevices from '../../../services/withdraw'
import Table from '../../../components/Table'
import TagItem from '../../../components/TagItem'
import { DateRangePicker, Input, MaskedInput } from 'rsuite'
import { FilterAltOff } from '@mui/icons-material'
import DrawerFilters from '../../../components/DrawerFilters'
import { cpfMask, phoneMask } from '../../../constants/masks'
import { getStatusItem, predefinedPastRanges } from '../../../constants/default'
import couponService from '../../../services/coupon'
import Tooltip from '@material-ui/core/Tooltip'
import { OpenInNew, Receipt } from '@material-ui/icons'
import { NavLink } from 'react-router-dom'
import TrashIcon from '@material-ui/icons/DeleteOutline'

function TableFilter({filters, newItemAction = null, newItemLabel = '', queryFn, ...props}) {
	const [data, setData] = useState({})
	const [loading, setLoading] = useState(false)
	const [openDrawerFilters, setOpenDrawerFilters] = useState(false)
	const [apiFilters, setApiFilters] = useState({})
	const filterCount = Object.entries(getFilters()).length || 0

	function getFilters() {
		const activeFilters = {}

		Object.entries(apiFilters).filter(([key, value]) => {
			const currentValue = value?.formatted || value.raw
			if (!currentValue || currentValue === '' || currentValue.length === 0) return false
			return true
		}).map(([key, value]) => {
			activeFilters[key] = value?.formatted || value.raw
		})

		return activeFilters
	}

	useEffect(() => {
		getTableData()
	}, [])

	const getTableData = async (reset = false) => {
		setLoading(true)

		const data = await queryFn({
			page: 1,

			...(!reset && getFilters()),
		})

		if (!data) {
			setLoading(false)
			setData([])
			return
		}

		setData(data)
		setLoading(false)
	}

	const paginate = async (page) => {
		setLoading(true)
		const payload = {
			page,
			...getFilters(),
		}

		await queryFn(payload).then(data => {
			if (!data) return setLoading(false)

			setData(data)
		}).finally(() => setLoading(false))
	}

	async function onSubmitFilters(reset = false) {
		if (reset) {
			await setApiFilters({})
		}

		setOpenDrawerFilters(false)
		await getTableData(reset)
	}

	return (
		<>
      {filters && (
        <>
          <DrawerFilters
            open={openDrawerFilters}
            setOpen={setOpenDrawerFilters}
            onSubmit={() => onSubmitFilters()}
            filters={filters(apiFilters, setApiFilters)}
          />
        </>
      )}

			<div className="flex items-center w-full mt-10 mb-4 justify-between">
				<div className="flex items-center self-end flex-1 gap-4">
          {filters && (
            <div className="flex items-center gap-2">
              {!!filterCount && (<FilterAltOff onClick={() => onSubmitFilters(true)} style={{ cursor: 'pointer', fill: '#209869' }}/>)}
              <Button
                small
                color="green"
                className={'min-w-[200px]'}
                action={setOpenDrawerFilters}
                label={`Aplicar filtros ${!!filterCount ? `(${filterCount})` : ''}`}
                disabled={loading}
              />
            </div>
          )}
				</div>

				<div className={'flex flex-col items-end justify-center'}>
					{newItemAction && (
						<Button
							small
							color="green"
							className="min-w-[200px] mb-4"
							action={newItemAction}
							label={newItemLabel}
							disabled={loading}
						/>
					)}
					<span>Total de <strong>{data?.pagination?.total}</strong> registros encontrados.</span>
				</div>
			</div>

			<Table
				loading={loading}
				rows={data?.result}
				pagination={data?.pagination}
				paginate={paginate}
				{...props}
        renderRow={(row) => props.renderRow(row, getTableData)}
			/>
		</>
	)
}

const Coupons = () => {
  async function handleExclude(row, refetch) {
    await couponService.toggleStatusCoupon(row)
    await refetch()
  }

	return (
		<main id="withdraw">
			<div id="title">
			<span className="back" onClick={() => history.back()}>
				<ArrowBackIosIcon/>
				<span>Voltar</span>
			</span>
				<h1>Cupons</h1>
			</div>

			<TableFilter
				newItemAction={() => history.push('/promocoes/cupons/novo')}
				newItemLabel={'Criar novo cupom'}
				queryFn={couponService.getCoupons}
        renderRow={(row, refetch) => [
          { headerName: 'ID', field: 'id' },
          { headerName: 'Cupom', render: () => <TagItem item={{ label: row?.code }}/> },
          { headerName: 'Descrição', field: 'description' },
          { headerName: 'Status', render: () => <TagItem item={getStatusItem(row.active ? 'active' : 'inactive')}/> },
          { headerName: 'Data criação', render: () => moment(row.created_at, 'DD-MM-YYYY HH:mm').format('DD/MM/YYYY HH:mm') },
          { headerName: "Ações", render: () => (
              <>
                <Tooltip title="Ver" aria-label="show">
                  <NavLink to={`/promocoes/cupons/${row.id}`}>
                    <OpenInNew style={{ cursor: "pointer" }} />
                  </NavLink>
                </Tooltip>
                <Tooltip title="Excluir" aria-label="show">
                  <button type={'button'} className={"link"} onClick={() => handleExclude(row, refetch)}>
                    <TrashIcon style={{ cursor: "pointer" }} />
                  </button>
                </Tooltip>
              </>
            )
          },
        ]}
		  />
	</main>
)
}

export default Coupons
