import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { history }    from '../../../helpers/history';
import termsService from '../../../services/terms';
import Loading        from '../../../components/Loading';
import Button         from '../../../components/Button';
import Title          from '../../../components/Title';

import ArrowBackIosIcon          from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon       from '@material-ui/icons/ArrowForwardIos';
import Alert                     from '@material-ui/lab/Alert';
import Collapse                  from '@material-ui/core/Collapse';

import Dialog            from '@material-ui/core/Dialog';
import DialogActions     from '@material-ui/core/DialogActions';
import DialogContent     from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle       from '@material-ui/core/DialogTitle';

import './style.scss';
import Tooltip from "@material-ui/core/Tooltip";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import {OpenInNew} from "@material-ui/icons";

const Termos = () => {
    const _status = [
        { "option": "draft",    "label": "Rascunho" },
        { "option": "active",   "label": "Ativo"    },
        { "option": "inactive", "label": "Inativo"  }
    ]

    const formatDate = (value) => {
        if (value) {
            return new Date(value).toLocaleDateString('pt-BR', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
            });
        } else {
            return ""
        }
    }

    const [ terms,       setTerms ] = useState([ ]);
    const [ term,         setTerm ] = useState({ });
    const [ pagination, setPagination ] = useState({ });
    const [ loading,       setLoading ] = useState(false);
    const [ confirm,       setConfirm ] = useState(false);
    const [ status,         setStatus ] = useState('');
    const [ alert,           setAlert ] = useState({ show: false, severity: 'info', message: '' });

    useEffect(() => {
	    getAllTerms()
    }, [ ]);

    const getAllTerms = async () => {
        setLoading(true);
        const data = await termsService.getTerms({page:0});
        if(data) {
            setTerms(data.data.result);
            setPagination(data.data.pagination);
        }
        setLoading(false);
    }

    const paginate = async (page) => {
        setLoading(true);
        const _input   = { page, status, sort: 'date_desc' };
        const { data } = await termsService.getTerms(_input);

        if(!data)
            return setLoading(false);

        setTerms(data.data.result);
        setPagination(data.data.pagination);
        setLoading(false);
    }

    return (
        <main id="banners">

            <Title label="Termos" />

            <div id="filter">
                <div className="inputs"></div>
                <Button type="button" color="green" label="Cadastrar" action={ event => history.push('/configuracoes/termos/novo') } />
            </div>

            <div id="infos">
                <span>Total de <strong>{ pagination.total }</strong> termos encontrados.</span>
            </div>

            <div id="list">
                <Collapse className="feedback" in={ alert.show }>
                    <Alert severity={ alert.severity }>{ alert.message }</Alert>
                </Collapse><br/>

                <table  className="table table-responsive-sm">
                    <thead>
                    <tr>
                        <th scope="col">ID</th>
                        <th scope="col">Nome</th>
                        <th scope="col">Data de criação</th>
                        <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                        {
                            !loading && terms.length
                                ? terms.map(term =>
                                    (<tr key={term.id}>
                                        <td>{term.id}</td>
                                        <td>{term.name}</td>
                                        <td>{formatDate(term.created_at)}</td>
                                        <th scope="col" className="text-center">
                                            <Tooltip title="Visualizar" aria-label="edit"
                                                     onClick={() => history.push('/configuracoes/termos/' + term.id)}
                                            >
                                                <OpenInNew className="flex-shrink-0 link" />
                                            </Tooltip>
                                        </th>
                                    </tr>))
                                : (<tr key="empty">
                                    <td colSpan="8" className="text-center empty"><span>Não há termos cadastrados</span>
                                    </td>
                                </tr>)
                        }
                    </tbody>
                    <tfoot>
                    <tr>
                        <td colSpan="8">
                            <div>
                                {pagination.prev ?
                                    <ArrowBackIosIcon className="link" onClick={event => paginate(pagination.prev) }/> : null }
                                    { pagination.count ? <span className="labels">{ pagination.current } de { pagination.total_pages } { pagination.total_pages > 1 ? 'Páginas' : 'Página' }</span> : null }
                                    { pagination.next  ? <ArrowForwardIosIcon className="link" onClick={ event => paginate(pagination.next) }/> : null }
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </table>

                { loading && <Loading/> }
            </div>
        </main>
    );

}

export default Termos;
