import { onboardRequest, publicRequest } from './axiosInstances'

const auth = {
    async authenticate(user_id, password, authCode) {
			return await onboardRequest({
				method: 'POST',
				url: '/admin/login',
				data: { email: user_id, password, secret: authCode }
			})
				.then(({ data }) => data)
				.catch((error) => error.response.data)
    },
    async register(userData) {
        try {
            const { data } = await publicRequest.post('/register', userData);
            return data;
        }
        catch(error) {
            return false;
        }
    },
    async rescuePassword(email) {
        try {
            const { data } = await publicRequest.get('password/lost', { params: { email }});
            return data;
        }
        catch(error) {
            return false;
        }
    },
    async resetPassword(newPassword) {
        try {
            const { data } = await publicRequest.post('/password/reset', newPassword);
            return data;
        }
        catch(error) {
            if(error.response.status === 422)
                return { token: false, message: "Sua chave de criptografia é inválida." }

            return false;
        }
    }

}
export default auth;
