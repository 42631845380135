import React, { useState } from 'react';
import DatePicker          from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const DatePickerInput = (props) => {
    const [selectedDate, setSelectedDate] = useState() 
    const setDate = (date) => {
        setSelectedDate(date)
        props.onChange(date)
    }
    const months = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
    ];
    
    return (
        <DatePicker
            // renderCustomHeader={({
            //     date,
            //     changeMonth,
            //     decreaseMonth,
            //     increaseMonth,
            //     prevMonthButtonDisabled,
            //     nextMonthButtonDisabled,
            // }) => (
            //     <>
            //         <span>{new Date(date).getFullYear()}</span>
            //         <div
            //         style={{
            //             margin: 10,
            //             display: "flex",
            //             justifyContent: "center",
            //         }}
            //         >
            //         <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
            //             {"<"}
            //         </button>
            //         <select
            //             value={months[new Date(date).getMonth()]}
            //             onChange={({ target: { value } }) =>
            //             changeMonth(months.indexOf(value))
            //             }
            //         >
            //             {months.map((option) => (
            //             <option key={option} value={option}>
            //                 {option}
            //             </option>
            //             ))}
            //         </select>

            //         <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
            //             {">"}
            //         </button>
            //         </div>
            //     </>
            // )}
            selected={props.selectedDate}
            onChange={(date) => setDate(date)}
            locale="pt-BR"
            showTimeInput
            timeInputLabel="Hora:"
            timeFormat="p"
            dateFormat="dd/MM/yyyy HH:mm"
            className="form-control" 
            id={props.id}
        />
    );
}

export default DatePickerInput;