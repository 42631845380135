import Title from '../../../components/Title';
import React, {useEffect, useState} from "react";
import termsService from "../../../services/terms";
import {history} from "../../../helpers/history";
import Button from "../../../components/Button";
import Alert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import rules from "../../../services/rules";
import {Avatar, Card, CardContent, Stack, Typography} from "@mui/material";
import {formatCurrency} from "../../../helpers/formatCurrency";

const RulesConfig = () => {
    const [profit, setProfit] = useState('')
    const [investment, setInvestment] = useState('')
    const [data, setData] = useState({})

    const [loading, setLoading] = useState(false)
    const [alert, setAlert] = useState({show: false, severity: 'info', message: ''});

    useEffect(() => {
        getData()
    }, []);

    const getData = async () => {
        const response = await rules.getConfig();

        if (response) {
            setData(response)
            setProfit(response.profit_margin_percentage * 100)
            setInvestment(response.investment_percentage * 100)
        }
    }

    const onSubmit = async (event) => {
        event.preventDefault();

        if (!profit) {
            setAlert({show: true, severity: 'warning', message: 'O campo Nome é obrigatório.'});
            setTimeout(() => setAlert({show: false, severity: 'warning', message: ''}), 5000)
            return false;
        }

        if (!investment) {
            setAlert({show: true, severity: 'warning', message: 'O campo Valor é obrigatório.'});
            setTimeout(() => setAlert({show: false, severity: 'warning', message: ''}), 5000)
            return false;
        }

        setLoading(true);

        const response = await rules.updateConfig({ profit_margin_percentage: profit / 100, investment_percentage: investment / 100 });

        if (response) {
            setAlert({show: true, severity: 'success', message: 'Alterado com sucesso!'});
            setLoading(false);
            getData()

            setTimeout(() => {
                setAlert({show: false, severity: 'warning', message: ''});
            }, 3000);
        } else {
            setAlert({
                show: true,
                severity: 'warning',
                message: 'Não conseguimos salvar essa regra. Tente novamente mais tarde.'
            });
            setLoading(false);
            setTimeout(() => {
                setAlert({show: false, severity: 'warning', message: ''});
            }, 5000);
        }
    }

    const cards = [
        {
            title: 'Total de MUS na plataforma',
            value: `${new Intl.NumberFormat('pt-BR').format(data?.total_mus || 0)} MUS`
        },
        {
            title: 'Total de MUS agendado',
            value: `${new Intl.NumberFormat('pt-BR').format(data?.total_mus_scheduled || 0)} MUS`
        },
        {
            title: 'Faturamento',
            value: formatCurrency(data?.last_revenue),
            description: 'Últimos 7 dias'
        },
        {
            title: 'Cotação MUS',
            value: formatCurrency(data?.conversion_rate)
        },
    ]

    function handlePercent(e, fnState) {
        const value = parseInt(e.target.value, 10)
        fnState(value >= 100 ? 100 : value)
    }

    return (
        <main id="rules-config">
            <Title label="Configurações" />

            <Collapse className="feedback" in={alert.show}>
                <div className="mb-4">
                    <Alert severity={alert.severity}>{alert.message}</Alert>
                </div>
            </Collapse>

            <div className={'flex gap-x-10'}>
                {cards?.map((card, index) => (
                    <div className="bg-white flex h-[150px] flex-col justify-between border border-gray-100 shadow-md shadow-gray-200 rounded-lg w-1/4 p-4">
                        <div className={'flex flex-col'}>
                            <span className="uppercase text-gray-500 text-2xs mb-2">{card?.title}</span>
                            <span className={'text-2xl text-gray-600 font-semibold'}>{card?.value}</span>
                        </div>
                        <span className="uppercase text-gray-400 text-xs">{card?.description}</span>
                    </div>
                ))}
            </div>

            <form onSubmit={onSubmit} noValidate>
                <div className="row mb-4 mt-12">
                    <div className="col-4 form-group">
                        <label htmlFor="number">Margem de lucro:</label>
                        <input
                            type="number"
                            className="form-control"
                            required
                            value={profit}
                            onChange={(e) => handlePercent(e, setProfit)}
                        />
                        <small className={'text-gray-400 text-xs'}>
                            Valor em porcentagem
                        </small>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-4 form-group">
                        <label htmlFor="number">Margem de investimento:</label>
                        <input
                            type="number"
                            className="form-control"
                            required
                            value={investment}
                            onChange={(e) => handlePercent(e, setInvestment)}
                        />
                        <small className={'text-gray-400 text-xs'}>
                            Valor em porcentagem
                        </small>
                    </div>
                </div>

                <div className="form-group">
                    <Button
                        type="submit"
                        width="20%"
                        color="green submit-button"
                        label="Atualizar"
                        loading={loading}
                        disabled={loading}
                    />
                </div>
            </form>

        </main>
    );
}

export default RulesConfig;
