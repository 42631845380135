import RichEditor from "../RichEditor";
import { useRef, useState } from "react";
import { CircularProgress } from '@material-ui/core';
import { OpenInNew } from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';
import ProgressBar from "@ramonak/react-progress-bar";
import jackpots from "../../services/jackpots";
import { formatCurrency } from "../../helpers/formatCurrency";
import Loading from "../Loading";
import { Modal } from "../Modal";

import "./style.scss"
import moment from "moment";

export function GameRow({ allDisabled, show = true, concourse, description, refresh, loading, selectedRows, setSelectedRows, file }) {
  const [editorContent, setEditorContent] = useState(description)
  const [rowLoading, setRowLoading] = useState([])
  const [openDownload, setOpenDownload] = useState(false)
  const [downloadData, setDownloadData] = useState([])
  const [loadingDownload, setLoadingDownload] = useState(false)
  const percent = parseInt(((file.current / file.total) * 100), 10)
  const tags = [file.parameters?.channel, formatCurrency(file.parameters?.value), `${file.parameters?.quantity} cotas`]
  const emptyEditor = '<p><br></p>'

  async function handleBlurDescription(file) {
    if (editorContent && editorContent !== emptyEditor) {
      setRowLoading([...rowLoading, file.id])
      jackpots.updateUpload({
        description: editorContent,
        start_at: file.parameters?.start_at ? file.parameters?.start_at : moment(concourse.start_date, "DD-MM-yyyy HH:mm").format("yyyy-MM-DD HH:mm"),
        ids: [file.id]
      }).then(async () => {
        await refresh()
      }).finally(() => {
        setRowLoading((rowLoading) => rowLoading.filter(item => item !== file.id));
      })
    }
  }

  async function handleSelectRow(checked, file) {
    if (checked) {
      await setSelectedRows((otherRowsSelected) => [...new Set([...otherRowsSelected, {
        id: file.id,
        status: file.status,
        fileName: file.original_name,
      }])])
    } else {
      await setSelectedRows((otherRowsSelected) => otherRowsSelected.filter(item => item.id !== file.id));
    }
  }

  async function handleDownload() {
    setOpenDownload(true)
    setLoadingDownload(true)
    jackpots.downloadUpload(file.id).then((response) => {
      setDownloadData(response.split("\r\n"))
      setLoadingDownload(false)
    })
  }

  function getStatusColor(status) {
    if (file.error) {
      return "bg-error-light"
    }
    if (status === "uploaded") {
      return "bg-[#E6E8EC]"
    }
    if (status === "pending") {
      return "bg-[#209869]"
    }
    if (status === "finished") {
      return "bg-[#24FF00]"
    }
  }

  return (
    <>
      <Modal
        title={file.original_name}
        noSubmit
        open={openDownload}
        setOpen={setOpenDownload}
        onCancel={() => {
          setOpenDownload(false);
          setDownloadData([]);
        }}
      >
        {loadingDownload && (
          <div className="flex items-center justify-center flex-1 w-full h-full">
            <CircularProgress size={40} className="text-primary icon" />
          </div>
        )}
        {!loadingDownload && (
          <>
            <span className="mb-3 font-bold">
              Conteúdo do arquivo
            </span>
            <div className="table-main">
              <table>
                <thead>
                  <tr>
                    <th className="w-[100px]">
                      Linha
                    </th>
                    <th>
                      Números
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {downloadData.map((item, index) => (
                    <>
                      <tr key={item.error?.fileName}>
                        <td className="text-base text-black w-[90px]">
                          {index + 1}
                        </td>
                        <td className="text-base text-black whitespace-normal">
                          {item}
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </Modal>
      {show && !file.error &&
        <section
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            e.target.nodeName === "SECTION" && handleSelectRow(!selectedRows.find((row) => row.id === file.id), file)
          }}
          className={`${selectedRows.find((row) => row.id === file.id) ? "border-primary" : "border-neutral-6"} border-2 transition ${file.status === "finished" || rowLoading.indexOf(file.id) >= 0 ? "select-none" : ""} cursor-pointer overflow-hidden relative flex w-full py-6 px-4 border rounded-lg`}>
          {rowLoading.indexOf(file.id) >= 0 &&
            <div className="absolute top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-white/80">
              <Loading />
            </div>
          }
          {file.status === "loading" &&
            <div className="absolute top-0 left-0 w-full">
              <ProgressBar
                className="progress-bar-widget"
                borderRadius="0"
                isLabelVisible={false}
                height="9px"
                bgColor="#209869"
                baseBgColor="#F4F5F6"
                completed={percent}
              />
            </div>
          }
          <section className="flex flex-col items-start w-full gap-6">
            <div className="flex items-center gap-4">
              <Tooltip arrow placement="top-end" title={
                <>
                  <p style={{ padding: 0, margin: 0 }}>Pendente: Cinza</p>
                  <p style={{ padding: 0, margin: 0 }}>Pronto: Verde</p>
                </>
              }>
                <section className={`flex-shrink-0 status-icon ${getStatusColor(file.status)}`} />
              </Tooltip>
              <span onClick={(e) => { e.stopPropagation(); handleDownload() }} className="flex items-center gap-2 cursor-pointer !shadow-none text-base !p-0 !border-none text-dark-primary disabled:text-neutral-5" title={file.original_name}>
                {file.original_name}
                <OpenInNew className="text-base text-primary fill-primary" />
              </span>
            </div>
            <section className="px-3 py-1 text-xs text-[#0060B9] rounded-full border border-solid border-[#0060B9] bg-[#0060B9]/20">
              {file.parameters?.start_at ? moment(file.parameters?.start_at).format("DD/MM/YYYY HH:mm") : moment(concourse.start_date, "DD-MM-YYYY HH:mm").format("DD/MM/YYYY HH:mm")}
            </section>
            <textarea
              className="w-full p-3 scrollbar-track-rounded-full scrollbar-thumb-rounded-full min-h-[100px] scrollbar-thin scrollbar-thumb-neutral-5 scrollbar-track-neutral-6 resize-none placeholder:italic text-2xs text-neutral-1"
              onChange={(e) => {
                e.preventDefault()
                e.stopPropagation()
                setEditorContent(e.target.value)
              }}
              onBlur={(e) => {
                e.preventDefault()
                e.stopPropagation()
                handleBlurDescription(file)
              }}
              value={editorContent}
              placeholder="Descrição do jogo"
              disabled={file.status === "finished" || loading || file.status === "loading" || allDisabled}
            />
            <section className="w-full border border-solid border-neutral-6" />
            <div className="flex items-center w-full gap-1">
              {tags.map((tag) => (
                <section className="px-3 py-1 text-xs text-[#0060B9] rounded-full border border-solid border-[#0060B9] bg-[#0060B9]/20">
                  {tag}
                </section>
              ))}
            </div>
          </section>
        </section>
      }
    </>
  )
}