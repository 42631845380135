import React, { useState } from 'react'
import classNames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import { Tooltip } from '../Tooltip'

function WithTooltip ({children, action = "", tooltipMessage = null, showTooltip = null, setShowTooltip = null}) {
  if (tooltipMessage) {
    return (
      <Tooltip action={action} content={tooltipMessage} open={showTooltip} setOpen={setShowTooltip}>
        {children}
      </Tooltip>
    )
  }

  return children
}

export function LoadingButton({
  tooltipMessage,
  disabled,
  loading,
  label,
  onClick,
}) {
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipAction, setTooltipAction] = useState("");

  async function onButtonClick() {
    setTooltipAction("click");
    setShowTooltip(false);
    await onClick();

    setShowTooltip(true);
    await new Promise((resolve) => setTimeout(resolve, 5000));
    setShowTooltip(false);
    setTooltipAction("");
  }

  return (
    <>
      <WithTooltip tooltipMessage={tooltipMessage} showTooltip={showTooltip} action={tooltipAction} setShowTooltip={setShowTooltip}>
        <button
          onClick={() => !disabled && onButtonClick(showTooltip, setShowTooltip, onClick)}
          onMouseOver={() => {
            if (disabled) {
              setTooltipAction("hover");
              setShowTooltip(true)
            }
          }}
          onMouseOut={() => {
            if (disabled) {
              setTimeout(() => {
                setTooltipAction("");
                setShowTooltip(false)
              }, 2000)
            }
          }}
          className={
            classNames(
              "px-4 py-2 rounded-xl transition inline-flex items-center justify-center font-semibold leading-6 text-sm border border-primary shadow-none",
              disabled && !loading && "opacity-90 text-neutral-2 bg-neutral-7",
              loading && !disabled && "opacity-70 text-white bg-primary",

              (loading || disabled) && "border-transparent cursor-not-allowed select-none",
              !loading && !disabled && "bg-primary text-white hover:bg-white hover:text-primary",

              loading && disabled && "bg-neutral-7 !text-neutral-2 !opacity-90"
            )
          }
        >
          <div className="flex items-center justify-start gap-2 relative">
            <AnimatePresence presenceAffectsLayout>
              {loading &&
                <motion.svg
                  className={
                    classNames(
                      "absolute z-10 left-0 animate-spin",
                      disabled ? "text-primary" : "text-white"
                    )
                  }
                  width={20}
                  height={20}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  initial={{
                    opacity: 1,
                  }}
                  animate={{
                    opacity: 1, width: 20, height: 20,
                  }}
                  exit={{
                    opacity: 0,
                  }}
                >
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </motion.svg>
              }
            </AnimatePresence>
            <motion.span
              animate={{
                paddingLeft: loading ? 30 : 0,
              }}
              exit={{
                paddingLeft: 0,
              }}
              initial={{
                paddingLeft: 0,
              }}
            >
              {label}
            </motion.span>
          </div>
        </button>
      </WithTooltip>
    </>
  )
}
