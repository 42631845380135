import React, { useState, useRef } from 'react';
import DatePicker          from "react-datepicker";
import { format }          from 'date-fns';

import { history }    from '../../../../helpers/history';
import Button         from '../../../../components/Button';
import bannersSevices from '../../../../services/banners';
import Title          from '../../../../components/Title';

import Alert    from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';

import './style.scss';
import "react-datepicker/dist/react-datepicker.css";

const BannerNew = (props) => {
    const [ dateStart, setDateStart ] = useState('');
    const [ dateEnd,     setDateEnd ] = useState('');
    
    const [ title,     setTitle ] = useState('');
    const [ status,   setStatus ] = useState('');

    const [ desktopPreview, setDesktopPreview ] = useState('');
    const [ desktopFile,    setDesktopFile    ] = useState('');
    const [ mobilePreview,  setMobilePreview  ] = useState('');
    const [ mobileFile,     setMobileFile     ] = useState('');

    const [ alert,     setAlert ] = useState({ show: false, severity: 'info', message: '' });
    const [ loading, setLoading ] = useState(false);
    
    const desktopImage = useRef(null);
    const mobileImage  = useRef(null);

    const attachFileDesktop = (event) => {
        setDesktopFile(event.target.files[0]);
        setDesktopPreview(URL.createObjectURL(event.target.files[0]));
    };

    const attachFileMobile = (event) => {
        setMobileFile(event.target.files[0]);
        setMobilePreview(URL.createObjectURL(event.target.files[0]));
    };

    const removeImage = (type) => {
        if(type === 'desktop') {
            setDesktopFile(''); setDesktopPreview('');
        }
        else {
            setMobileFile(''); setMobilePreview('');
        }
    }

    const submit = async (event) => {
        event.preventDefault();

        if(!title) {
            setAlert({ show: true, severity: 'warning', message: 'O campo Título é obrigatório.' });
            setTimeout(() => setAlert({ show: false, severity: 'warning', message: '' }), 5000)
            return false;
        }

        if(!desktopFile && !desktopPreview) {
            setAlert({ show: true, severity: 'warning', message: 'O campo Imagem Desktop é obrigatório.' });
            setTimeout(() => setAlert({ show: false, severity: 'warning', message: '' }), 5000)
            return false;
        }

        if(!mobileFile && !mobilePreview) {
            setAlert({ show: true, severity: 'warning', message: 'O campo Imagem Mobile é obrigatório.' });
            setTimeout(() => setAlert({ show: false, severity: 'warning', message: '' }), 5000)
            return false;
        }


        if(dateEnd && dateEnd < dateStart) {
            setAlert({ show: true, severity: 'warning', message: 'A Data de Final não pode ser menor que a Data Inicial!' });
            setTimeout(() => setAlert({ show: false, severity: 'warning', message: '' }), 5000)
            return false;
        }

        setLoading(true);
        const formData = new FormData();
              formData.append('image_desktop', desktopFile);
              formData.append('image_mobile',  mobileFile);
              formData.append('title',         title);
              formData.append('status',        status    ? status                                   : 'draft');
              formData.append('start_date',    dateStart ? format(dateStart, "yyyy-MM-dd HH:mm:ss") : format(new Date(), "yyyy-MM-dd HH:mm:ss") );
              formData.append('end_date',      dateEnd   ? format(dateEnd, "yyyy-MM-dd HH:mm:ss")   : null );
        
        const response = await bannersSevices.addBanner(formData);
        
        if(response) {
            setAlert({ show: true, severity: 'success', message: 'Cadastrado com sucesso!' });
            setLoading(false);
            
            setTimeout(() => {
                setAlert({ show: false, severity: 'warning', message: '' });
                history.push('/campanhas/banners');
            }, 3000);
        }
        else {
            setAlert({ show: true, severity: 'warning', message: 'Não conseguimos salvar esse banner' });
            setLoading(false);
            setTimeout(() => {  setAlert({ show: false, severity: 'warning', message: '' }); }, 5000);
        }

    }

    return (
        <main id="new-game">
            
            <Title label="Cadastrar Banner" />

            <form onSubmit={ submit } noValidate>
                
                <Collapse className="feedback" in={ alert.show }>
                    <Alert severity={ alert.severity }>{ alert.message }</Alert>
                </Collapse>

                <div className="row">
                    <div className="col-12 form-group">
                        <label htmlFor="banner-title">Título:</label>
                        <input type="text" className="form-control" id="banner-title" required disabled={ loading } onChange={ event => setTitle(event.target.value) } />
                    </div>
                </div>
                           
                <div className="row">
                    <div className="col-6 form-group image">
                        {
                            desktopPreview 
                            ?   (<>
                                    <label>Desktop:</label>
                                    <figure className="desktop">
                                        <span><DeleteForeverOutlinedIcon onClick={ event => removeImage('desktop') } /></span>
                                        <img ref={ desktopImage } src={ desktopPreview } alt="Banner para Desktop"/>
                                    </figure>
                                </>)
                            :   (<>
                                    <label htmlFor="desktop-image">Imagem Desktop:</label>
                                    <input type="file" className="form-control" name="desktop-image" id="desktop-image" required disabled={ loading } onChange={ attachFileDesktop } accept=".jpg"/>
                                    <small>Atenção é aceito somente arquivos JPG.</small>
                                </>)
                        }
                    </div>
                    
                    <div className="col-6 form-group image">
                        {
                            mobilePreview 
                            ?   (<>
                                    <label>Mobile:</label>
                                    <figure className="desktop">
                                        <span><DeleteForeverOutlinedIcon onClick={ event => removeImage('mobile') } /></span>
                                        <img ref={ mobileImage }  src={ mobilePreview } alt="Banner para Mobile"/>
                                    </figure>
                                </>)
                            :   (<>
                                    <label htmlFor="mobile-image">Imagem Mobile:</label>
                                    <input type="file" className="form-control" name="mobile-image" id="mobile-image" required disabled={ loading } onChange={ attachFileMobile } accept=".jpg"/>
                                    <small>Atenção é aceito somente arquivos JPG.</small>
                                </>)
                        }
                    </div>
                </div>

                <div className="row">
                    <div className="col-6 form-group">
                        <label htmlFor="date-start" style={ { width: "100%" } }>Início da exibição:</label>
                        <DatePicker locale="pt" dateFormat="dd/MM/yyyy" className="form-control" id="date-start" selected={ dateStart } onChange={ date => setDateStart(date) } />
                    </div>
                
                    <div className="col-6 form-group">
                        <label htmlFor="date-end" style={ { width: "100%" } }>Fim da exibição:</label>
                        <DatePicker locale="pt" dateFormat="dd/MM/yyyy" className="form-control" id="date-end" selected={ dateEnd } onChange={ date => setDateEnd(date) } />
                    </div>
                </div>
                                
                <div className="row">
                    <div className="col-12 form-group">
                        <label htmlFor="banner-status">Status:</label>
                        <select name="banner-status" id="banner-status" className="form-control" onChange={ event => setStatus(event.target.value) }>
                            <option value="">Selecione um status</option>
                            <option value="active">Ativo</option>
                            <option value="inactive">Inativo</option>
                            <option value="draft">Rascunho</option>
                        </select>
                    </div>
                </div>

                <div className="form-group">
                    <Button type="submit" width="40%" color="green" label="Enviar" loading={ loading } disabled={ loading } />
                </div>

            </form>
        </main>
    );
}

export default BannerNew;