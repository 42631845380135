import { types } from './types';
const initialState  = { menu: true, sessionExpired: false };

const reducer = (state = initialState, action = { }) => { 
    switch(action.type) {
        case types.OPEN_MENU:
            return { ...state, menu: !state.menu };
        case types.SESSION_EXPIRED:
            return { ...state, sessionExpired: !state.sessionExpired };
        default:
            return state;
    }
}
export default reducer;