import moment from 'moment/moment'
import { AddCircleOutline, BlockOutlined, ErrorOutline, Update } from '@material-ui/icons'
import CheckIcon from '@material-ui/icons/CheckRounded'
import classNames from 'classnames'
import { LoadingButton } from '../../../../../components/Button/LoadingButton'
import React from 'react'

export function getValidationLabel(type) {
  if (type === 'cpf') return 'CPF'
  if (type === 'tax_id') return 'Documento (RG/CNH)'
  if (type === 'face_match') return 'Identidade'
}

export function responseMessage({
  requestedBy = '', error = false,
}) {
  switch (requestedBy) {
    case 'none':
      if (error) {
        return 'Solicitação já realizada, solicite autenticação para outro administrador.'
      }
      return 'Solicitação enviada para outros administradores.'
    case 'different':
      if (error) {
        return 'Solicitação já autenticada.'
      }
      return 'Validação autenticada com sucesso.'
    case 'same':
      return 'Para autenticar a validação, você precisa que outro administrador realize a ação.'
    case 'finished':
      return 'Validação autenticada com sucesso.'
    default:
      return "Mensagem não esperada. Contate o suporte técnico."
  }
}

export function ActivityInfo({ onRequest, manualRequest, userData, activity }) {
  if (!activity || !activity?.subject) return null

  const {
    type: activityType,
    created_at,
    values: {
      response_message,
      status,
      locked_until,
      tries,
	    api_requests,
      manual_requester_id,
    },
    subject: {
      data: {
        type: subjectType
      }
    }
  } = activity

  let title
  let icon
  let description = 'Nenhuma mensagem registrada.'
  const formattedType = getValidationLabel(subjectType)
  const date = moment(created_at).format('DD/MM/YYYY HH:mm')

  if (response_message) {
    if (status === 'finished') {
      description = `Validação de ${formattedType} concluída pelo usuário.`
    } else {
      description = response_message
    }
  }

  if (locked_until) {
    icon = <BlockOutlined className="text-error text-md"/>
    title = `Validação de ${formattedType} bloqueada.`
    description = `Esta validação está bloqueada temporariamente até ${moment(locked_until).format('DD/MM/YYYY HH:mm')}.`
  }

  if (status === 'finished') {
    icon = <CheckIcon className="text-primary text-md"/>
    if (manual_requester_id) {
      title = `Validação de ${formattedType} autenticada manualmente.`
      description = `Validação solicitada e autenticada respectivamente pelos administradores: 
        ${activity?.manual?.requester?.name} e ${activity?.manual?.accepter?.name}.`
    } else {
      title = `Validação de ${formattedType} concluída com sucesso.`
    }
  }

  if (status === 'error') {
    icon = <ErrorOutline className="text-error-light text-md"/>
    title = `Falha na validação de ${formattedType}.`
  }

  if (!icon) {
    switch (activityType) {
      case 'created':
        title = `Início de validação de ${formattedType}.`
        icon = <AddCircleOutline className="text-primary text-md"/>
        break
      case 'updated':
        title = api_requests + `ª tentativa de validação de ${formattedType}.`
        icon = <Update className="text-blue-500 text-md"/>
        break
      default:
        break
    }
  }

	console.log("subjectType", subjectType)
	console.log("manualRequest", manualRequest)
	console.log("manualRequest", manualRequest[subjectType])

  return (
    <div className={
      classNames(
        'border flex bg-white p-3 rounded-lg flex-col items-start w-full gap-2 lg:flex-row',
        locked_until && status !== "finished" && 'border-error-light',
        status === "finished" && "border-primary",
      )
    }>
      <div className="flex flex-col gap-1">
        <div className="flex flex-col">
          <div className="flex mb-2 items-center gap-x-2">
            {icon}
            <span className="text-base font-semibold">{title}</span>
            { manualRequest[subjectType]?.show && locked_until &&
              <LoadingButton
                tooltipMessage={manualRequest[subjectType]?.responseMessage}
                onClick={() => onRequest(subjectType)}
                loading={manualRequest[subjectType]?.loading}
                disabled={manualRequest[subjectType]?.disabled}
                type={manualRequest[subjectType]?.type}
                label={manualRequest[subjectType]?.label}
              />
            }
          </div>
          <p className="text-sm mt-1">{description}</p>
          <span className="text-2xs mt-2 font-medium">{date}</span>
        </div>
      </div>
    </div>
  )
}
