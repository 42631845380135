import React, { useState, useEffect } from "react";
import moment from "moment";

import Loading from "../../components/Loading";
import concoursesService from "../../services/concourse";
import Title from "../../components/Title";

import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";
import "./style.scss";
import TagItem from "../../components/TagItem";
import Table from "../../components/Table";
import Button from "../../components/Button";
import {history} from "../../helpers/history";
import rules from "../../services/rules";

const Rules = () => {
    const _status = [
        { option: "active", label: "Ativo" },
        { option: "draft", label: "Rascunho" },
        { option: "archived", label: "Arquivado" },
    ];

    const statusList = [
        {
            "raw": "draft",
            "label": "Rascunho",
            color: "#D8C364",
        },
        {
            "raw": "active",
            "label": "Ativo",
            color: "#209869",
        },
        {
            "raw": "archived",
            "label": "Arquivado",
            color: "#D86470",
        },
    ];

    const [status, setStatus] = useState('');

    const [concourses, setConcourses] = useState([]);
    const [pagination, setPagination] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getConcourses();
    }, []);

    const getConcourses = async () => {
        setLoading(true);
        const { data } = await rules.getRules();

        if (!data) return setLoading(false);

        setConcourses(data.result);
        setPagination(data.pagination);
        setLoading(false);
    };

    const paginate = async (page) => {
        setLoading(true);
        const _input = { page, status };
        const { data } = await rules.getRules(_input);

        if (!data) return setLoading(false);

        setConcourses(data.result);
        setPagination(data.pagination);
        setLoading(false);
    };

    const filterStatus = async (event) => {
        if (event.target.value) {
            setStatus(event.target.value);
        }
        else {
            setStatus('');
            getConcourses();
        }
        setLoading(true);
        const { data } = await rules.getRules({ status: event.target.value });

        if (!data)
            return setLoading(false);

        setConcourses(data.result);
        setPagination(data.pagination);
        setLoading(false);
    }
    
    return (
        <main id="concursos">
            {loading && <Loading />}
            <Title label="Regras" />
            
            <div id="filter">
                <div className="inputs">
                    <select name="status" id="status" onChange={filterStatus} className="form-control">
                        <option value="">Filtre por status</option>
                        <option value="">Todos</option>
                        {_status.map((item, index) => (<option key={index} value={item.option}>{item.label}</option>))}
                    </select>
                </div>
                <Button
                    type="button"
                    color="green"
                    label="Cadastrar"
                    action={(event) => history.push("/rules/new")}
                />
            </div>
            
            <div id="filter">
                <div className="inputs"></div>
            </div>
            
            <div id="infos">
                <span>
                    Total de <strong>{pagination.total}</strong> itens encontrados.
                </span>
            </div>
            
            <Collapse className="feedback" in={alert.show}>
                <Alert severity={alert.severity}>{alert.message}</Alert>
            </Collapse>

            <Table
                rows={concourses}
                renderRow={(data) => [
                    { headerName: "ID", render: () => data?.id },
                    { headerName: "Nome", render: () => data?.name },
                    {
                        headerName: "Status", render: () => {
                            const statusItem = statusList.filter((item) => item.raw === data.status)[0]
                            return (
                                <TagItem item={{ color: statusItem?.color, label: statusItem?.label }} />
                            )
                        }
                    },
                    { headerName: "Descrição", render: () => data?.description },
                    { headerName: "Modelo", render: () => data?.model === 'per_unit' ? 'Por cota' : 'Bateu ganhou' },
                    { headerName: "Data de criação", render: () => moment(data?.earned_date).format("DD/MM/YYYY HH:mm") },
                ]}
                pagination={pagination}
                paginate={paginate}
                loading={loading}
            />
        </main>
    );
};

export default Rules;
