import { useEffect, useState } from "react";
import Button from "../Button";

export function Modal({ cancelText = "Fechar", onCancel = null, children, handleSubmit, noSubmit = false, okText = "Ok", open, setOpen, title }) {
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (open) {
			document.body.classList.add("overflow-hidden")
			return
		}
		document.body.classList.remove("overflow-hidden")
	}, [open])

	async function onSubmit() {
		setLoading(true);
		await handleSubmit();
		setLoading(false);
	}

	return (
		<>
			<div className={`p-3 lg:p-0 fixed top-0 left-0 flex items-center justify-center w-screen h-screen z-[100] bg-black/70 transition ${open ? "opacity-100" : "opacity-0 invisible"}`}>
				<div className="min-h-[450px] flex flex-col relative w-full lg:max-w-2xl xl:max-w-6xl bg-white rounded-lg shadow">
					<div className="flex items-start justify-between p-4 border-b rounded-t">
						<span className="text-xl font-semibold text-gray-900">
							{title}
						</span>
					</div>
					<div className="flex flex-col flex-1 h-full p-4">
						{children}
					</div>
					<div className="flex items-center justify-end p-4 space-x-2 border-t border-gray-200 rounded-b">
						<Button
							small
							className="!px-2 bg-white border-primary border shadow-none text-primary hover:text-white hover:bg-primary"
							action={() => onCancel ? onCancel() : setOpen(false)}
							color="green"
							label={cancelText}
							width="202px"
						/>
						{!noSubmit && <Button
							small
							form="form"
							width="202px"
							action={onSubmit}
							loading={loading}
							disabled={loading}
							color="green"
							label={okText}
						/>}
					</div>
				</div>
			</div>
		</>
	);
}