import { history } from '../../../helpers/history'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Input as RSInput, InputGroup } from 'rsuite'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Autocomplete, DatePicker, Switch, cn, AutocompleteItem, Input, Divider, RadioGroup, Radio, SelectItem, Select } from '@nextui-org/react'
import CurrencyInput from 'react-currency-input-field'
import { I18nProvider } from '@react-aria/i18n'
import { blueprints, QueryBuilderCustom } from '../../../components/QueryBuilder/QueryBuilderCustom'
import Button from '../../../components/Button'
import moment from 'moment'
import couponService from '../../../services/coupon'
import Alert from '@material-ui/lab/Alert'
import Collapse from '@material-ui/core/Collapse'

const schema = yup.object({
  method: yup.string().required('Campo obrigatório'),
  code: yup.string().required('Campo obrigatório'),
  description: yup.string(),
  value_type: yup.string().required('Campo obrigatório'),
  value: yup.string().required('Campo obrigatório'),
  start_date: yup.date().test('start_date', 'A data inicial deve ser presente ou futura', function (value) {
    if (!value) return true

    return moment(value).diff(moment(), 'days') >= 0
  }),
  end_date: yup.date().test('end_date', 'A data final deve ser presente ou após a data inicial', function (value) {
    if (!value) return true

    return moment(value).diff(moment(), 'days') >= 0 && moment(value).diff(moment(this.parent.start_date), 'days') >= 0
  }),
  max_uses: yup.string(),
  max_uses_per_user: yup.string(),
  exclusive_use: yup.boolean(),
  first_order: yup.boolean(),
  minimum_value: yup.number(),
  maximum_value: yup.number(),
  rules: yup.array().required('Campo obrigatório'),
}).required()

export const CustomRadio = (props) => {
  const { children, ...otherProps } = props

  return (<Radio
    {...otherProps}
    classNames={{
      base: cn('inline-flex m-0 bg-neutral-7/50 items-center justify-between', 'flex-row-reverse max-w-[300px] cursor-pointer rounded-lg gap-4 p-4 border border-transparent', 'data-[selected=true]:!border-primary', 'data-[hover-unselected=true]:border-neutral-6'),
    }}
  >
    {children}
  </Radio>)
}

export default function CouponsNew () {
  const [loading, setLoading] = useState(false)
  const [alert, setAlert] = useState({ message: "", severity: ""})
  const {
    control, setValue, watch, formState, handleSubmit,
  } = useForm({
    resolver: yupResolver(schema), defaultValues: {
      method: 'cart',
      // code: 'BOLAO10',
      value_type: 'percentage',
      exclusive_use: true,
      // value: 25,
      // description: 'Descrição do cupom',
      // start_date: parseDate('2024-04-04'),
      // end_date: parseDate('2025-04-04'),
      // max_uses: '50',
      // max_uses_per_user: '1',
      // minimum_value: '50',
      // maximum_value: '100',
      rules: blueprints[0].blueprint,
    },
  })

  async function onSubmit(data) {
    setLoading(true)
    const response = await couponService.createCoupon({
      exclusive_use: false,
      active: 1,
      ...data,
      ...(data?.start_date && ({ start_date: moment(data?.start_date).toISOString() })),
      ...(data?.end_date && ({ end_date: moment(data?.end_date).toISOString() })),
    })
    
    if (response.name === "AxiosError") {
      setAlert({
        message: "Houve um erro ao criar o cupom.",
        severity: "error"
      })

      setTimeout(() => {
        setAlert({
          message: ""
        })
      }, 5000)

      setLoading(false)
    } else {
      setAlert({
        message: "Cupom criado com sucesso.",
        severity: "success"
      })

      setTimeout(() => {
        history.push(`/promocoes/cupons/${response?.data?.id}`);
      }, 1500)
    }
  }

  useEffect(() => {
    setValue('value', '')
  }, [watch('value_type')])

  return (
    <main id="withdraw">
      <style jsx>{`
        .nxui-select-container div[data-slot='input-wrapper'] {
          height: 36px !important;
          min-height: 36px !important;
        }
  
        .nxui-select-container div[data-slot='input-wrapper']:hover {
          border-color: #209869 !important;
        }
  
        .rs-toggle {
          display: flex !important;
          align-items: center !important;
        }
      `}
      </style>
  
      <div id="title">
          <span className="back" onClick={() => history.back()}>
            <ArrowBackIosIcon/><span>Voltar</span>
          </span>
        <h1>Novo cupom</h1>
      </div>
  
      <Collapse className="feedback" in={ alert.message }>
        <Alert severity={ alert.severity }>{ alert.message }</Alert>
      </Collapse>

      <form onSubmit={handleSubmit(onSubmit)} className="grid grid-flow-row grid-cols-4 mt-10 gap-4" style={{ maxWidth: '50%' }}>
        <div className={'col-span-4'}>
          <label htmlFor="method" className="text-sm font-medium mb-1">Método</label>
          <Controller
            name="method"
            control={control}
            render={({ field, fieldState }) => (<RadioGroup
              orientation={'horizontal'}
              className={'mb-1'}
              color="primary"
              errorMessage={fieldState?.error?.message}
              isInvalid={fieldState?.error?.message}
              value={field?.value}
              onValueChange={field?.onChange}
            >
              {/*<CustomRadio*/}
              {/*  value="product"*/}
              {/*  className="max-w-full flex-1"*/}
              {/*>*/}
              {/*  <span className="text-sm font-medium">Desconto por item</span>*/}
              {/*</CustomRadio>*/}
              <CustomRadio
                value="cart"
                className="max-w-full flex-1"
              >
                <span className="text-sm font-medium">Desconto no total do carrinho</span>
              </CustomRadio>
            </RadioGroup>)}
          />
          <span className="text-xs text-default-400">
              O método é utilizado para determinar qual o modelo de desconto que este cupom será aplicado.
              {/*O desconto por item é aplicado diretamente no produto,*/}
              {/*enquanto o desconto no total do carrinho é aplicado no valor total do pedido.*/}
            </span>
        </div>
  
        <Divider className={'col-span-4 h-px row-span-1'}/>
  
        <Controller
          name="code"
          control={control}
          render={({ field, formState, fieldState }) => {
            return (<div className={'col-span-4'}>
                <label htmlFor="code" className="text-sm font-medium">Código</label>
                <Input
                  {...field}
                  id="code"
                  variant="bordered"
                  color="primary"
                  errorMessage={fieldState?.error?.message}
                  isInvalid={fieldState?.error?.message}
                  placeholder="BOLAO10"
                />
              </div>)
          }}
        />
  
        <Divider className={'col-span-4 h-px row-span-1'}/>
  
        <Controller
          name="description"
          control={control}
          render={({ field, fieldState }) => (<div className={'col-span-3'}>
            <label htmlFor="description" className="text-sm font-medium">Descrição</label>
            <Input
              {...field}
              variant="bordered"
              color="primary"
              errorMessage={fieldState?.error?.message}
              isInvalid={fieldState?.error?.message}
              id="description"
              placeholder="Descrição"
            />
          </div>)}
        />
  
        <Controller
          name="value_type"
          control={control}
          render={({ field, fieldState }) => (<div className={'nxui-select-container flex flex-col'} style={{ gridColumn: 1 }}>
            <label htmlFor="value_type" className="text-sm font-medium">Tipo</label>
            <Autocomplete
              id="value_type"
              variant="bordered"
              color="primary"
              selectedKey={field.value}
              onSelectionChange={field.onChange}
              isClearable={false}
              errorMessage={fieldState?.error?.message}
              isInvalid={fieldState?.error?.message}
              placeholder={'Selecione o tipo'}
            >
              {[{ label: 'Porcentagem', value: 'percentage' }, { label: 'Valor', value: 'fixed' }].map((item) => (<AutocompleteItem key={item.value} value={item.value}>
                {item.label}
              </AutocompleteItem>))}
            </Autocomplete>
          </div>)}
        />
  
        <Controller
          name="value"
          control={control}
          render={({ field, fieldState }) => (<div className={'col-span-3'}>
              <label htmlFor="value" className="text-sm font-medium">Valor</label>
              <InputGroup>
                <InputGroup.Addon>
                  <span className={'w-4'}>{watch('value_type') === 'fixed' ? 'R$' : '%'}</span>
                </InputGroup.Addon>
                <RSInput
                  as={watch('value_type') === 'fixed' ? CurrencyInput : 'input'}
                  disabled={!watch('value_type')}
                  id="value"
                  name="value"
                  value={field.value}
                  {...(watch('value_type') === 'fixed' ? {
                    groupSeparator: '.', decimalSeparator: ',', decimalsLimit: 2, onValueChange: (value) => {
                      field.onChange(value)
                    }, placeholder: 'Valor do desconto',
                  } : {
                    ...field, type: 'number', onChange: (value) => {
                      field.onChange(value > 100 ? 100 : value)
                    }, placeholder: 'Valor do desconto',
                  })}
                />
              </InputGroup>
              <p className="mt-1 text-tiny text-danger">
                {fieldState?.error?.message}
              </p>
            </div>)}
        />
  
        <Divider className={'col-span-4 h-px row-span-1'}/>
  
        <Controller
          name="start_date"
          control={control}
          render={({ field, fieldState }) => (<div className={'flex col-span-2 flex-col'}>
            <label htmlFor="start_date" className="text-sm font-medium">Data inicial</label>
            <I18nProvider locale={'pt-BR'}>
              <DatePicker
                {...field}
                variant="bordered"
                color="primary"
                id="start_date"
                placeholder="Escolha uma data inicial"
                errorMessage={fieldState?.error?.message}
                isInvalid={fieldState?.error?.message}
              />
            </I18nProvider>
          </div>)}
        />
  
        <Controller
          name="end_date"
          control={control}
          render={({ field, fieldState }) => (<div className={'flex col-span-2 flex-col'}>
            <label htmlFor="end_date" className="text-sm font-medium">Data final</label>
            <DatePicker
              {...field}
              variant="bordered"
              color="primary"
              id="end_date"
              placeholder="Escolha uma data final"
              errorMessage={fieldState?.error?.message}
              isInvalid={fieldState?.error?.message}
            />
          </div>)}
        />
  
        <Divider className={'col-span-4 h-px row-span-1'}/>
  
        <Controller
          name="max_uses"
          control={control}
          render={({ field, fieldState }) => (<div className={'col-span-2'}>
            <label htmlFor="max_uses" className="text-sm font-medium">Limite de uso (geral)</label>
            <Input
              {...field}
              variant="bordered"
              color="primary"
              id="max_uses"
              type="number"
              placeholder="Quantidade máxima"
              errorMessage={fieldState?.error?.message}
              isInvalid={!!fieldState?.error?.message}
            />
          </div>)}
        />
  
        <Controller
          name="max_uses_per_user"
          control={control}
          render={({ field, fieldState }) => (<div className={'col-span-2'}>
            <label htmlFor="max_uses_per_user" className="text-sm font-medium">Limite de uso (por usuário)</label>
            <Input
              {...field}
              errorMessage={fieldState?.error?.message}
              isInvalid={fieldState?.error?.message}
              variant="bordered"
              color="primary"
              id="max_uses_per_user"
              type="number"
              placeholder="Quantidade máxima"
            />
          </div>)}
        />
  
        <Divider className={'col-span-4 h-px row-span-1'}/>
  
        <p className={'col-span-4 text-lg font-medium'}>Regras</p>
  
        <Controller
          name="first_order"
          control={control}
          render={({ field, fieldState }) => (<Switch
            classNames={{
              base: cn('col-span-4', 'inline-flex flex-row-reverse bg-neutral-7/50 hover:border-neutral-6 items-center', 'justify-between cursor-pointer rounded-lg gap-4 p-4 border border-transparent', 'data-[selected=true]:border-primary data-[selected=true]:hover:border-primary'), wrapper: 'p-0 h-4 overflow-visible', thumb: cn('w-6 h-6 border-2 shadow-lg', 'group-data-[hover=true]:border-neutral-5', //selected
                'group-data-[selected=true]:ml-6 group-data-[selected=true]:!border-primary', // pressed
                'group-data-[pressed=true]:w-7', 'group-data-[selected]:group-data-[pressed]:ml-4'),
            }}
            isSelected={field.value}
            onValueChange={field.onChange}
          >
            <div className="flex flex-col gap-1">
              <span className="text-sm">Exclusivo para primeira compra</span>
              <p className="text-tiny max-w-[75%] p-0 m-0 text-default-400">
                Ao ativar essa opção, quando o cupom for adicionado ao carrinho, ele só será válido na primeira compra.
              </p>
            </div>
          </Switch>)}
        />
  
        <div className="col-span-4">
          <QueryBuilderCustom
            control={control}
          />
        </div>
  
        <Divider className={'col-span-4 h-px row-span-1'}/>
  
        <Button type="submit" color="green" label="Cadastrar cupom" loading={loading} />
      </form>
  </main>
  )
}
