import { NavLink } from "react-router-dom";
import { Close, OpenInNew, Receipt, Search, Web, Block, Telegram, Language } from '@material-ui/icons';
import TrashIcon from '@material-ui/icons/DeleteOutline';
import Tooltip from '@material-ui/core/Tooltip';
import { useEffect, useState } from 'react';

import currencyFormat from '../../../../helpers/currencyFormat';
import jackpotsService from '../../../../services/jackpots';
import moment from 'moment';
import Loading from "../../../../components/Loading";
import TagItem from "../../../../components/TagItem";
import Table from "../../../../components/Table";
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'

function formatChannelType(target_channel) {
	switch (target_channel) {
		case 'all':
			return <span className="flex items-center gap-1"><Web style={{ color: "#209869" }}/> Todos</span>;
		case 'bot_telegram':
			return <span className="flex items-center gap-1"><Telegram style={{ color: "#209869" }}/> Telegram</span>;
		case 'web_site':
			return <span className="flex items-center gap-1"><Language style={{ color: "#209869" }}/> Site</span>;
		default:
			return <span className="flex items-center gap-1"><Block style={{ color: "#209869" }}/> Sem canal</span>;
	}
}

const jackpotStatus = [
	{
		"raw": "awaiting",
		"label": "Aguardando",
		color: "#D8C364",
	},
	{
		"raw": "draft",
		"label": "Rascunho",
		color: "#B1B5C4",
	},
	{
		"raw": "finished",
		"label": "Encerrado",
		color: "#209869",
	},
	{
		"raw": "blocked",
		"label": "Bloqueado",
		color: "#D86470",
	},
	{
		"raw": "cancelled",
		"label": "Cancelado",
		color: "#CB784E",
	},
	{
		"raw": "production",
		"label": "Em produção",
		color: "#209869",
	},
]

export default function Games({ activeTab, concourse }) {
	const [loading, setLoading] = useState(false);
	const [jackpots, setJackpots] = useState([]);
	const [pagination, setPagination] = useState({});
	const [cancelID, setCancelID] = useState('')

	const [filters, setFilters] = useState(null);
	const [searchFilter, setSearchFilter] = useState("");

	useEffect(() => {
		if (filters) {
			getJackpots();
		}
	}, [filters])

	useEffect(() => {
		if (concourse?.id && activeTab === "listGames") {
			getJackpots();
		}
	}, [concourse, activeTab]);

	async function getJackpots(page = 1) {
		setLoading(true);
		const _id = concourse.id;

		await jackpotsService.getJackpotsByConcourse(_id, {
			sort: 'date_desc',
			page,
			...(filters && { ...filters }),
		}).then(({data}) => {
			setJackpots(data?.result);
			setPagination(data?.pagination);
		}).finally(() => {
			setLoading(false);
		})
	}

	async function paginate(page) {
		await getJackpots(page)
	}

	function updateFilters(filterKey, value) {
		const newFilters = { ...filters, [filterKey]: value };

		if (!value) {
			delete newFilters[filterKey]
		}

		setFilters(newFilters);
	}

	async function onStatusChange(event) {
		await updateFilters('status', event.target.value);
	}

	async function handleSearch(event) {
		await setSearchFilter(event.target.value);

		if (!event.target.value) {
			await updateFilters('search', "");
		}
	}

	async function onSubmit(event) {
		event.preventDefault();
		await updateFilters('search', searchFilter);
	}

	async function clear() {
		setSearchFilter("")
		await updateFilters('search', "");
	}

	const [confirm, setConfirm] = useState(false);

	const handleConfirm = () => {
		setConfirm(!confirm);
	};

	async function cancelJackpot() {
		setLoading(true);

		await jackpotsService.cancelJackpot(concourse.id, cancelID).then(async () => {
			await getJackpots()
			setLoading(false)
		})
	}

	const handleJackpotCancel = async () => {
		setConfirm(!confirm);
		await cancelJackpot();
	};

	return (
		<>
			<div id="filter">
				{loading && <Loading />}
				<div className="inputs">
					<select name="status" id="status" onChange={onStatusChange} value={filters?.status} className="form-control">
						<option value="">Todos</option>
						{jackpotStatus.map((item, index) => (<option key={index} value={item.raw}>{item.label}</option>))}
					</select>
					<form onSubmit={onSubmit} className="search">
						<input type="text" className="form-control" placeholder="Buscar pela referência" value={searchFilter} onChange={handleSearch} />
						<button type="submit"><Search /></button>
						{filters?.search ? (<button type="submit" className="cancel" onClick={clear}><Close /></button>) : null}
					</form>
				</div>
			</div>

			<div id="infos">
				<h3>Bolões neste concurso</h3>
				<span>Total de <strong>{pagination?.total}</strong> bolões encontrados.</span>
			</div>

			<Table
				rows={jackpots}
				renderRow={(row) => [
					{ headerName: "Ref", field: "ref" },
					{ headerName: "Título", field: "name" },
					{ headerName: "Concurso", field: "sku" },
					{ headerName: "Canal", render: () => formatChannelType(row.target_channel) },
					{ headerName: "Valor", render: () => currencyFormat(row.shares_regular_price) },
					{
						headerName: "Status", render: () => {
							const statusItem = jackpotStatus.filter((item) => item.raw === row.status)[0]
							return <TagItem item={{ color: statusItem?.color, label: statusItem?.label }} />
						}
					},
					{ headerName: "Cadastrado em", render: () => moment(row.created_at, "DD-MM-YYYY").format("DD/MM/YYYY") },
					{ headerName: "Data de início", render: () => moment(row.start_date, "DD-MM-YYYY HH:mm").format("DD/MM/YYYY HH:mm") },
					{ headerName: "Arquivo", field: "source_file" },
					{
						headerName: "Ações", render: () => (
							<>
								{row.receipt.length > 0 && row.receipt[0]?.s3_url && (
									<Tooltip title="Ver PDF" aria-label="open">
										<Receipt className="link" onClick={event => window.open(row.receipt[0].s3_url)} />
									</Tooltip>
								)}
								<Tooltip title="Ver bolão" aria-label="show">
									<NavLink to={`/campanhas/concourses/${concourse.id}/boloes/${row.id}`}>
										<OpenInNew style={{ cursor: "pointer" }} />
									</NavLink>
								</Tooltip>
								<Tooltip title="Cancelar bolão" aria-label="show">
									<button type={'button'} className={"link"} onClick={() => {
										setCancelID(row.id)
										handleConfirm()
									}}>
										<TrashIcon style={{ cursor: "pointer" }} />
									</button>
								</Tooltip>
							</>
						)
					},
				]}
				pagination={pagination}
				paginate={paginate}
				loading={loading}
			/>

			<Dialog open={ confirm } onClose={ handleConfirm } className="confirmation">
				<DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">Confirmação</DialogTitle>
				<DialogContent>
					<DialogContentText>Você realmente deseja cancelar este bolão?</DialogContentText>
				</DialogContent>
				<DialogActions>
					<span className="link" onClick={ handleConfirm }>Não</span>
					<span className="link" onClick={ handleJackpotCancel }>Sim</span>
				</DialogActions>
			</Dialog>
		</>
	)
}
