import { WorkHistory } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import Loading from '../../../../../components/Loading'
import Title from '../../../../../components/Title'
import validationService from '../../../../../services/validations'
import account from '../../../../../services/account'
import users from '../../../../../services/users'
import { ActivityInfo, responseMessage } from './functions'
import { useParams } from 'react-router-dom'

export default function UserValidations (props) {
	const {userID} = useParams()
  const [loading, setLoading] = useState(true)
  const [manualRequest, setManualRequest] = useState(null)
  const [userData, setUserData] = useState({
    validations: [], activities: null, targetUser: null,
  })

  async function makeApiRequests(showLoading = true) {
    showLoading && setLoading(true)

    const getTargetUser = async () => {
      await users.getUsers({
        id: {
          raw: userID,
        }
      }, { page: 1 }).then(({ data }) => {
        setUserData((state) => ({
          ...state,
          targetUser: data[0],
        }))
      })
    }

    const getActivities = () => {
      let manualIds = [];
      let activities = [];

      validationService.getUserActivities(userID).then(async ({ data }) => {
        activities = await data?.filter((activity) => {
          return activity.subject
        }).map((activity) => {
          if (activity.values.manual_requester_id) {
            manualIds.push({
              id: activity.id,
              manual: {
                requester: activity.values.manual_requester_id,
                accepter: activity.values.manual_acceptier_id
              }
            })
          }

          return activity
        })

        if (manualIds.length > 0) {
          let usersManualIds = []
          manualIds.map((manual) => {
            manual.manual.requester && usersManualIds.push(manual.manual.requester)
            manual.manual.accepter && usersManualIds.push(manual.manual.accepter)
          })

          await users.getUsers(null, {
            ids: usersManualIds.join(','),
            page: 1,
          }).then(({ data }) => {
            if (!data) return null

            activities = activities.map((activity) => {
              const requesterUser = data.find((user) => user.id === activity.values.manual_requester_id)
              const accepterUser = data.find((user) => user.id === activity.values.manual_acceptier_id)

              if (activity.values.manual_requester_id && (requesterUser || accepterUser)) {
                return {
                  ...activity,
                  manual: {
                    requester: {
                      name: requesterUser.fullname,
                    },
                    ...(accepterUser && {
                      accepter: {
                        name: accepterUser.fullname,
                      },
                    }),
                  }
                }
              }

              return activity
            })
          })
        }

        setUserData((state) => ({
          ...state, activities,
        }))
      })
    }

    const getValidations = async () => {
      await account.getOnboardProfile().then(async ({ data: profile }) => {
        setUserData((state) => ({
          ...state,
          loggedUser: profile,
        }))

        await validationService.getUsersValidations(userID).then(async ({ data }) => {
          if (data?.length === 0) return null

          const validations = data?.filter((validation) => {
            return validation
          })

          await data.map((validation) => {
            let requestedBy = 'none'
            let label = 'Solicitar aprovação manual'
            let response;
            let show = true;

            if (validation.status === "finished") {
              requestedBy = 'finished'
              show = false;
              response = responseMessage({
                requestedBy,
                error: true,
              })
            }

            if (validation.has_manual_request) {
              if (validation.manual_requester_id === profile.id) {
                requestedBy = 'same'
                label = 'Aguardando outro administrador'
                response = responseMessage({
                  requestedBy,
                  error: true,
                })
              } else {
                requestedBy = 'different'
                label = 'Autenticar aprovação manual'
              }
            }

            setManualRequest((state) => ({
              ...state,
              [validation.type]: {
                requestedBy,
                disabled: requestedBy === "same",
                id: validation.id,
                type: validation.type,
                show,
                responseMessage: response,
                label,
              }
            }))
          })

          return setUserData((state) => ({
            ...state, validations,
          }))
        })
      })
    }

    await Promise.all([getTargetUser(), getValidations(), getActivities()])
    showLoading && setLoading(false)
  }

  useEffect(() => {
    makeApiRequests()
  }, [])

  async function onRequest(subjectType) {
    const { id, requestedBy } = manualRequest[subjectType]
    if (requestedBy === 'same' || requestedBy === "finished") return null

    setManualRequest((state) => ({
      ...state,
      [subjectType]: {
        ...(state[subjectType] && state[subjectType]),
        loading: true,
        responseMessage: "",
      },
    }))

    if (requestedBy === 'none') {
      await validationService.requestManualValidation(id).then(async (response) => {
        const error = response.error
        const status = error ? 'error' : 'success'
        const message = responseMessage({
          requestedBy,
          error,
        })

        if (!response.error) {
          setTimeout(() => {
            setManualRequest((state) => ({
              ...state,
              [subjectType]: {
                ...(state[subjectType] && state[subjectType]),
                error,
                responseMessage: message,
                responseStatus: status,
              },
            }));
          }, 5000)

          await makeApiRequests(false)
        }

        setManualRequest((state) => ({
          ...state,
          [subjectType]: {
            ...(state[subjectType] && state[subjectType]),
            error,
            responseMessage: message,
            responseStatus: status,
          },
        }));
      })
    }

    if (requestedBy === 'different') {
      await validationService.approveManualValidation(id).then(async (response) => {
        const error = response.error
        const status = error ? 'error' : 'success'
        const message = responseMessage({
          requestedBy,
          error,
        })

        if (!response.error) {
          setTimeout(() => {
            setManualRequest((state) => ({
              ...state,
              [subjectType]: {
                ...(state[subjectType] && state[subjectType]),
                error,
                responseMessage: message,
                responseStatus: status,
              },
            }));
          }, 5000)

          await makeApiRequests(false)
        }

        setManualRequest((state) => ({
          ...state,
          [subjectType]: {
            ...(state[subjectType] && state[subjectType]),
            error,
            responseMessage: message,
            responseStatus: status,
          },
        }));
      })
    }
  }

  return (
    <main className="flex flex-col w-full h-full flex-1 mb-10">
      {loading ? <Loading/> : (
        <>
          <Title label={`Validações de ${[userData.targetUser.first_name, userData.targetUser.last_name].join(' ')}`}/>
          <section className="mt-6 rounded-lg flex flex-1 overflow-hidden flex-col min-w-[50%] bg-neutral-7 w-full">
            <h3 className="m-0 p-3 border-b-2 border-neutral-5/50 text-lg font-medium bg-neutral-6/50">Listagem de atividades</h3>
            <div className="px-4 mr-4 my-6 flex-1 gap-3 flex flex-col overflow-y-auto scrollbar-default max-h-[700px]">
              {userData?.activities?.length > 0 ? userData?.activities.map((activity) => (
                <section key={activity.id}>
                  <ActivityInfo
                    key={activity.id}
                    activity={activity}
                    onRequest={onRequest}
                    manualRequest={manualRequest}
                    userData={userData}
                  />
                </section>
              )) : (
                <div className="flex flex-col flex-1 gap-2 w-full h-full items-center justify-center">
                  <WorkHistory className="text-primary !w-[48px] !h-[48px]"/>
                  <span className="text-base font-medium">Nenhuma atividade registrada</span>
                </div>
              )}
            </div>
          </section>
        </>
      )}
  </main>)
}
