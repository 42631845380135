import moment from "moment";
import { ProgressBar, Step } from "react-step-progress-bar";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import "../../../../assets/tailwind.css"

import { useEffect, useState } from "react";
import { Check } from "@material-ui/icons";
import { CircularProgress } from "@material-ui/core";
import jackpots from "../../../../services/jackpots";
import Loading from "../../../../components/Loading";

export default function ProcessingGames({ activeTab, concourse }) {
	const [listLoading, setListLoading] = useState(false);
	const [files, setFiles] = useState([]);
	const [pagination, setPaginationJackpotFiles] = useState([]);

	useEffect(() => {
		if (!files.find(file => file.status !== "finished" && file.status !== "error")) {
			return
		}

		const timer = setInterval(() => {
			getAllJackpotFiles(pagination.current_page, true)
		}, 1000)
		return () => clearInterval(timer)
	}, [files])

	useEffect(() => {
		if (activeTab === "processingGames") {
			getAllJackpotFiles()
		}

	}, [activeTab])

	async function paginate(nextPage) {
		getAllJackpotFiles(nextPage)
	}

	const getAllJackpotFiles = (page = 1, noLoading = false) => {
		!noLoading && setListLoading(true)
		jackpots.getJackpotsFile(page, concourse.id).then(async (response) => {
			setListLoading(false)
			if (response.meta.pagination.total_pages >= page) {
				setFiles(response.data);
				setPaginationJackpotFiles(response.meta.pagination);
				return
			} else {
				getAllJackpotFiles(page - 1)
			}
		});
	};

	const percent = (file) => {
		return parseInt((((file.row_count_processed / file.row_count_total) * 100)).toFixed(), 10) / 2
	}

	return (
		<div id="list" className="mt-10 mb-20">
			<div className="flex flex-col w-full gap-4 table-auto">
				<div className="flex items-center justify-between w-full px-3">
					<span className="text-base font-semibold text-neutral-4 whitespace-nowrap">Bolão</span>
					<span className="text-base w-[250px] font-semibold text-neutral-4 whitespace-nowrap">Status</span>
					<span className="text-base w-[130px] font-semibold text-neutral-4 whitespace-nowrap">Data de envio</span>
				</div>
				<div className="relative flex min-h-[400px] flex-col w-full gap-4">
					{listLoading && <Loading />}
					{!listLoading && files.length === 0 && (
						<div key="empty">
							<span className="text-center empty">Não há arquivos cadastrados</span>
						</div>
					)}
					{files.length > 0 && (
						files.map((file) => (
							<div key={file.id} style={{ height: 80 }} className="p-3 py-4 flex items-center justify-between !border border-[#E6E8EC] border-solid rounded-lg">
								<div className="reference lg:whitespace-nowrap">
									{file.original_name}
								</div>
								<div className="reference w-[250px] lg:whitespace-nowrap">
									<ProgressBar unfilledBackground="#E6E8EC" filledBackground="#209869" percent={file.status === "finished" ? 100 : percent(file) + 50}>
										<Step>
											{({ accomplished, index }) => (
												<div style={{ borderRadius: '100%' }} className={`flex flex-col items-center justify-center relative w-5 h-5 ${accomplished ? "bg-[#209869]" : "bg-[#E6E8EC]"}`}>
													{accomplished && <Check style={{ color: "white", fontSize: '14px' }} />}
													<span className="absolute mt-12 text-xs">Envio</span>
												</div>
											)}
										</Step>
										<Step>
											{({ accomplished, index }) => (
												<div style={{ borderRadius: '100%' }} className={`flex items-center justify-center relative w-5 h-5 ${accomplished ? "bg-[#209869]" : "bg-[#E6E8EC]"}`}>
													{accomplished && <Check style={{ color: "white", fontSize: '14px' }} />}
													<span className="absolute mt-12 text-xs">Processamento</span>
												</div>
											)}
										</Step>
										<Step>
											{({ accomplished, index }) => (
												<div style={{ borderRadius: '100%' }} className={`flex items-center justify-center relative w-5 h-5 ${accomplished ? "bg-[#209869]" : "bg-[#E6E8EC]"}`}>
													{accomplished && <Check style={{ color: "white", fontSize: '14px' }} />}
													<span className="absolute mt-12 text-xs">Concluído</span>
												</div>
											)}
										</Step>
									</ProgressBar>
								</div>
								<div className="lg:whitespace-nowrap">{moment(file.created_at, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm")}</div>
							</div>
						))
					)}
				</div>
				<div className="flex items-center justify-center gap-4">
					{pagination.links?.previous ? <ArrowBackIosIcon className="link" onClick={event => paginate(pagination.current_page - 1)} /> : null}
					{pagination.count ? <span className="labels">{pagination.current_page} de {pagination.total_pages} {pagination.total_pages > 1 ? 'Páginas' : 'Página'}</span> : null}
					{pagination.links?.next ? <ArrowForwardIosIcon className="link" onClick={event => paginate(pagination.current_page + 1)} /> : null}
				</div>
			</div>
		</div>
	);
}
