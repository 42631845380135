/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Loading from "../../../../components/Loading";
import jackpots from "../../../../services/jackpots";
import DropzoneComponent from "../../../../components/Dropzone";
import moment from "moment";
import { ContentCopy } from "@mui/icons-material";
import { toast } from "react-toastify";
import TagItem from "../../../../components/TagItem";
import Table from "../../../../components/Table";

export default function ReceiptGames({ activeTab, concourse }) {
  const [receiptLogs, setReceiptLogs] = useState([]);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
	const [watermark, setWatermark] = useState(1);

  useEffect(() => {
    if (concourse?.id && activeTab === "receiptGames") {
      getReceiptLogs();
    }
  }, [concourse, activeTab]);

  async function paginate(nextPage) {
    getReceiptLogs(nextPage)
  }

  const getReceiptLogs = async (page = 1, noLoading = false) => {
    !noLoading && setLoading(true);
    jackpots.getLogsByConcourseId(concourse.id, { page }).then((response) => {
      setPagination(response.meta.pagination);
      setReceiptLogs(response.data);
    }).finally(() => {
      !noLoading && setLoading(false);
    });
  }

  useEffect(() => {
    if (!receiptLogs.find(file => file.status === "uploaded" || file.status === "processing")) {
      return
    }
    const timer = setInterval(() => {
      getReceiptLogs(pagination.current_page, true)
    }, 1000)
    return () => clearInterval(timer)
  }, [receiptLogs])

  const logStatus = [
    {
      "raw": "uploaded",
      "label": "Na fila",
      color: "#D8C364",
    },
    {
      "raw": "processing",
      "label": "Processando",
      color: "#4E9DCB",
    },
    {
      "raw": "finished",
      "label": "Finalizado",
      color: "#209869",
    },
    {
      "raw": "error",
      "label": "Falha",
      color: "#D86470",
    },
  ]

  return (
    <div className="mt-4">
      {loading && <Loading />}

	    <div className="max-w-3xl mb-4 w-full gap-3 flex mx-auto justify-start" style={{alignItems: 'flex-end'}}>
		    <label htmlFor="banner-status">Máscara:</label>
		    <select name="banner-status" id="banner-status" className="w-fit form-control" value={ watermark } onChange={ event => setWatermark(event.target.value) }>
			    <option value={1}>Não aplicar máscara</option>
			    <option value={0}>Aplicar máscara</option>
		    </select>
	    </div>

      <DropzoneComponent
        allowedExtensions={["pdf"]}
        type="receipt"
        concourseID={concourse.id}
        showProgress
        refresh={getReceiptLogs}
        optionalParams={{
	        has_watermark: watermark,
        }}
      />


      <div id="infos">
        <h3>Comprovantes</h3>
        <span>Total de <strong>{pagination?.total}</strong> registros encontrados.</span>
      </div>

      <Table
        rows={receiptLogs}
        renderRow={(row) => [
          { headerName: "Arquivo", field: "file_name" },
          { headerName: "Concurso", render: () => row.parameters?.number },
          {
            headerName: "Status", render: () => {
              const status = logStatus?.filter(data => row.status === data.raw)[0]
              return <TagItem item={{ color: status?.color, label: status?.label }} />
            }
          },
          {
            headerName: "Resposta", render: () => (
              <>
                {
                  row.response && (row.status === "finished" || row.status === "error") &&
                  <div onClick={() => {
                    navigator.clipboard.writeText(row.response)
                    toast.success("Erro copiado com sucesso!")
                  }} className="flex items-center gap-1 cursor-pointer">
                    <ContentCopy className="flex-shrink-0" style={{ fontSize: 18, fill: "#209869" }} />
                    <span style={{ maxWidth: 200 }} className='truncate' title={row.response}>
                      {row.response}
                    </span>
                  </div>
                }
              </>
            )
          },
          { headerName: "Data de criação", render: () => moment(row.created_at).format("DD/MM/YYYY HH:mm") },
          { headerName: "Última atualização", render: () => moment(row.updated_at).format("DD/MM/YYYY HH:mm") },
          {
            headerName: "Ações", render: () => {
              return (
                <>
                  {row.s3_url && <a href={row.s3_url} target="_blank" rel="noreferrer">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                    </svg>
                  </a>}
                </>
              )
            }
          },
        ]}
        pagination={pagination}
        paginate={paginate}
        loading={loading}
      />

    </div >
  )
}
