import api from './axiosInstances';

const banners = {
    async getBanners(params) {
        try {
            let _url  = '/admin/campaigns/banners?';
                _url += (params.page) ? 'page='+ params.page : '' ;
            const { data } = await api.get(_url);
            return data;
        }
        catch(error) {
            return false;
        }
    },
    async getBanner(params) {
        try {
            const { data } = await api.get('/admin/campaigns/banners/'+ params.id);
            return data;
        }
        catch(error) {
            console.log(error);
        }
    },
    async addBanner(params) {
        try {
            const _headers = { headers: { "Content-Type": `multipart/form-data; boundary=${params._boundary}` }}
            await api.post('/admin/campaigns/banners/create', params, _headers);
            return true ;
        }
        catch(error) {
            console.log(error);
        }
    },
    async updateBanner(id, params) {
        try {
            await api.post('/admin/campaigns/banners/'+ id, params);
            return true;
        }
        catch(error) {
            console.log(error);
        }
    },
    async removeBanner(params) {
        try {
            await api.delete('/admin/campaigns/banners/'+ params.id);
            return true;
        }
        catch(error) {
            console.log(error);
        }
    },
}
export default banners;
