import { Navigate, Route, Routes, Outlet, useLocation } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as appActions from './store/app/actions'

import PrivateRoute from './components/PrivateRoute'
import Menu from './components/Menu'
import Footer from './components/Footer'
import Login from './pages/Login'
import NotFound from './pages/NotFound'
import Mus from './pages/Mus'

import Concourses from './pages/Campaigns/Concourses'
import ConcoursesNew from './pages/Campaigns/Concourses/New'
import ConcoursesEdit from './pages/Campaigns/Concourses/Edit'
import ConcoursesSingle from './pages/Campaigns/Concourses/View'

import Jackpots from './pages/Campaigns/Jackpots'
import JackpotsNew from './pages/Campaigns/Jackpots/New'
import JackpotsSingle from './pages/Campaigns/Jackpots/View'

import Banners from './pages/Campaigns/Banners'
import BannersNew from './pages/Campaigns/Banners/New'
import BannersEdit from './pages/Campaigns/Banners/Edit'

import Users from './pages/Configurations/Users'
import User from './pages/Configurations/Users/Single'
import UserValidations from './pages/Configurations/Users/Single/Validations'

import Depositions from './pages/Configurations/Depositions'
import Terms from './pages/Configurations/Terms'
import TermsNew from './pages/Configurations/TermsNew'
import TermsEdit from './pages/Configurations/TermsEdit'

import Rules from './pages/Rules'
import RulesNew from './pages/Rules/new'
import RulesConfig from './pages/Rules/config'

import Withdraw from './pages/Financial/Withdraw'
import UserOrders from './pages/Financial/Orders'
import UserSingleOrder from './pages/Financial/Orders/single'
import Transactions from './pages/Financial/Transactions'
import Wallets from './pages/Financial/Wallets'

import Coupons from './pages/Promotions/Coupons'
import CouponsNew from './pages/Promotions/Coupons/new'
import CouponSingle from './pages/Promotions/Coupons/single'

import AffiliatesDetails from './pages/Afiliados/details'
import AffiliatesUsers from './pages/Afiliados/affiliates'
import AffiliatesSales from './pages/Afiliados/sales'

const AppRoutes = (props) => {
	const location = useLocation()
	const hiddenMenuRoutes = ['/login', '/', '/404']
	const show = hiddenMenuRoutes.indexOf(location.pathname) < 0
	const storage = JSON.parse(localStorage.getItem('unindo_sonhos_admin_data'));

	return (
		<Routes>
			<Route
				element={(<div>
					{show ? <Menu/> : null}
					<TransitionGroup className={`${show ? 'content' : ''} ${props.appData.menu && show ? 'menu-opened' : ''}`}>
						<CSSTransition timeout={{ enter: 300, exit: 300 }} classNames={'fade'}>
							<Outlet/>
						</CSSTransition>
					</TransitionGroup>
					{show ? <Footer/> : null}
				</div>)}
			>
				<Route
					path="/"
					element={!storage?.token ? <Login/> : <Navigate to="/dashboard" replace />}
				/>

				<Route exact element={<PrivateRoute />}>
					<Route exact element={<PrivateRoute whiteListGroup="usersPage" />}>
						<Route path="/usuarios" element={<Users/>} exact/>
						<Route path="/usuarios/:userID" element={<User/>} exact/>
						<Route path="/usuarios/:userID/validacoes" element={< UserValidations/>} exact/>
					</Route>

					<Route exact path="/dashboard" element={<Concourses/>}/>

					<Route whiteListGroup="depositionsPage" path="/configuracoes/depoimentos" element={< Depositions/>} exact/>

					<Route whiteListGroup="termsPage" path="/configuracoes/termos" element={< Terms/>} exact/>
					<Route whiteListGroup="termsPage" path="/configuracoes/termos/novo" element={< TermsNew/>} exact/>
					<Route whiteListGroup="termsPage" path="/configuracoes/termos/:id" element={< TermsEdit/>} exact/>

					<Route path="/campanhas/concourses" element={<Concourses/>} exact/>
					<Route path="/campanhas/concourses/editar/:id" element={<ConcoursesEdit/>}/>
					<Route path="/campanhas/concourses/novo" element={<ConcoursesNew/>}/>
					<Route path="/campanhas/concourses/:id" element={<ConcoursesSingle/>} exact/>

					<Route path="/mus" element={<Mus/>} exact/>

					<Route path="/rules" element={<Rules/>} exact/>
					<Route path="/rules/new" element={<RulesNew/>} exact/>
					<Route path="/rules/config" element={<RulesConfig/>} exact/>

					<Route path="/campanhas/concourses/boloes" element={<Jackpots/>} exact/>
					<Route path="/campanhas/concourses/:concourse/boloes/novo" element={<JackpotsNew/>}/>
					<Route path="/campanhas/concourses/:concourse/boloes/:id" element={<JackpotsSingle/>}/>

					<Route path="/campanhas/banners" element={<Banners/>} exact/>
					<Route path="/campanhas/banners/novo" element={<BannersNew/>}/>
					<Route path="/campanhas/banners/editar/:id" element={<BannersEdit/>}/>

					{/*<Route path="/financeiro/boletos" element={< Barcode /> }        />*/}
					<Route path="/financeiro/compras" element={< UserOrders />} exact/>
					<Route path="/financeiro/compras/:id" element={<UserSingleOrder />}/>
					<Route path="/financeiro/transacoes" element={<Transactions />} exact/>
					<Route path="/financeiro/carteiras" element={<Wallets />} exact/>
					{/*<Route path="/financeiro/pix" element={< PIX /> }            />*/}
					{/*<Route path="/financeiro/premios" element={< Prizes /> }         />*/}
					<Route path="/financeiro/saques" element={<Withdraw/>}/>

					<Route path="/promocoes/cupons" element={<Coupons/>} exact/>
					<Route path="/promocoes/cupons/novo" element={<CouponsNew/>} exact/>
					<Route path="/promocoes/cupons/:id" element={<CouponSingle/>}/>

          <Route path="/afiliados/relatorio" element={<AffiliatesDetails />} exact />
          <Route path="/afiliados/comissoes" element={<AffiliatesSales />} exact />
          <Route path="/afiliados/usuarios" element={<AffiliatesUsers />} exact />

					{/*<Route path="/promocoes/campanhas" element={<CouponsCampaigns/>} exact/>*/}
					{/*<Route path="/promocoes/campanhas/novo" element={<CouponsCampaignsSingle/>} exact/>*/}
					{/*<Route path="/promocoes/campanhas/:id" element={<CouponsCampaignsSingle/>}/>*/}
				</Route>

				<Route path="*" element={<NotFound />} />
			</Route>
	</Routes>
	)
}

const mapStateToProps = state => ({ appData: state.appReducer })
const mapDispatchToProps = dispatch => ({ appActions: bindActionCreators(appActions, dispatch) })
export default connect(mapStateToProps, mapDispatchToProps)(AppRoutes)
