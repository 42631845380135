import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { NavLink, useLocation } from 'react-router-dom'
import { history } from '../../helpers/history';

import MenuIcon  from '@material-ui/icons/Menu';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import GolfCourseIcon from '@material-ui/icons/GolfCourse';

import './style.scss';

import * as accountActions from '../../store/account/actions';
import * as appActions     from '../../store/app/actions';

import logo from '../../assets/images/logotipo-unindo-sonhos.png';
import { canWhiteList } from '../PrivateRoute'
import { Link, Listbox, ListboxItem, ListboxSection, ScrollShadow } from '@nextui-org/react'
import LinkIcon from '@mui/icons-material/Link';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import VerifiedUserRoundedIcon from '@mui/icons-material/VerifiedUserRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded';
import CompareArrowsRoundedIcon from '@mui/icons-material/CompareArrowsRounded';
import LocalOfferRoundedIcon from '@mui/icons-material/LocalOfferRounded';
import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded';
import TextSnippetRoundedIcon from '@mui/icons-material/TextSnippetRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import ViewCarouselRoundedIcon from '@mui/icons-material/ViewCarouselRounded';
import { BurstMode, Collections, Receipt } from '@material-ui/icons'
import { Groups, Source } from '@mui/icons-material'

const Menu = (props) => {
    const logout = () => {
        localStorage.removeItem('unindo_sonhos_admin_data');
        history.push('/');
    }

		const navMenus = [
			{
				title: 'Campanhas',
				submenus: [
          {
            title: 'Concursos',
            link: '/dashboard',
            icon: ViewCarouselRoundedIcon,
          },
					{
						title: 'Banners',
						link: '/campanhas/banners',
						icon: Collections,
						// whiteList: 'bannersPage',
					},
					{
						title: 'MUS',
						link: '/rules',
						icon: AttachMoneyOutlinedIcon
					}
				]
			},
			{
				title: 'Financeiro',
				submenus: [
					{
						title: 'Saques',
						link: '/financeiro/saques',
						icon: CallReceivedIcon
					},
					{
						title: 'Liberações',
						link: '/mus',
						icon: VerifiedUserRoundedIcon
					}
				]
			},
			{
				title: 'Clientes',
				submenus: [
					{
						title: 'Usuários',
						link: '/usuarios',
						// whiteList: 'usersPage',
						icon: PeopleRoundedIcon
					},
					{
						title: 'Ordens de compras',
						link: '/financeiro/compras',
						icon: ShoppingCartRoundedIcon
					},
					{
						title: 'Transações',
						link: '/financeiro/transacoes',
						icon: CompareArrowsRoundedIcon
					}
				]
			},
      {
        title: 'Afiliados',
        submenus: [
          {
            title: 'Relatório',
            link: '/afiliados/relatorio',
            icon: Source,
          },
          {
            title: 'Comissões',
            link: '/afiliados/comissoes',
            icon: Receipt,
          },
          {
            title: 'Afiliados',
            link: '/afiliados/usuarios',
            icon: Groups,
          },
        ]
      },
			{
				title: 'Promoções',
				submenus: [
					{
						title: 'Cupons',
						link: '/promocoes/cupons',
						icon: LocalOfferRoundedIcon
					},
					// {
					// 	title: 'Campanhas',
					// 	link: '/promocoes/campanhas',
					// 	icon: CampaignRoundedIcon
					// }
				]
			},
			{
				title: 'Configurações Gerais',
				submenus: [
					{
						title: 'Termos',
						link: '/configuracoes/termos',
						// whiteList: "termsPage",
						icon: TextSnippetRoundedIcon
					},
					{
						title: 'Configurações MUS',
						link: '/rules/config',
						icon: SettingsRoundedIcon
					}
				]
			}
		]

    return (
        <section id="menu" className={ props.appData.menu ? 'active' : '' }>

            {
                props.appData.menu
                    ? <span className="toogle-button"><ArrowBackIosIcon className="toggle-menu close" onClick={ props.appActions.toggleMenu } /></span>
                    : <span className="toogle-button"><MenuIcon         className="toggle-menu"       onClick={ props.appActions.toggleMenu } /></span>
            }

            <div className="box">
                <Link href="/dashboard" id="logo" className="flex items-center justify-center">
                  <img src={ logo } alt="Unindo Sonhos"/>
                </Link>

                <hr/>

		            <ScrollShadow className={'h-[87%]'}>
			            <Listbox
				            classNames={{
											base: "p-4",
			              }}
				            variant="flat"
				            color="primary"
			            >
				            {navMenus.map((menu) => {
											if (menu?.whiteList && !canWhiteList(menu.whiteList)) return null

											return (
												<ListboxSection
													title={menu?.title}
													classNames={{
														heading: 'text-base',
														group: "flex flex-col gap-y-2"
													}}
												>
													{menu?.submenus?.map((submenu) => {
														if (submenu?.whiteList && !canWhiteList(submenu.whiteList)) return null

														const SubmenuIcon = submenu?.icon

														return (
															<ListboxItem
                                as={NavLink}
                                to={submenu?.link}
                                exact
                                activeClassName="menu-active"
																key={submenu?.title}
																startContent={<SubmenuIcon className="w-5 h-5" />}
																classNames={{
																	title: 'text-md mt-px',
																	base: 'ml-2 text-neutral-3 items-center p-3 max-w-[250px]',
																}}
															>
                                {submenu?.title}
															</ListboxItem>
														)
													})}
												</ListboxSection>
											)
				            })}
				            <ListboxItem
					            key={"Sair"}
					            onClick={logout}
					            startContent={<ExitToAppIcon className="w-5 h-5" />}
					            color={"danger"}
					            classNames={{
						            title: 'text-md',
						            base: 'text-neutral-3 p-3 max-w-[250px]',
					            }}
				            >
					            Sair
				            </ListboxItem>
			            </Listbox>
		            </ScrollShadow>
            </div>
        </section>)
}

const mapStateToProps = state => {
	return {
		userData: state.accountReducer.userData, appData: state.appReducer,
	}
}
const mapDispatchToProps = dispatch => {
	return {
		accountActions: bindActionCreators(accountActions, dispatch), appActions: bindActionCreators(appActions, dispatch),
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(Menu);
