import React from 'react';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';

import * as appActions from '../../store/app/actions';
import './style.scss';

const Footer = (props) => {
    return (
        <footer>
            Unindo Sonhos - 2021 - Todos os direitos reservados.
        </footer>
    )
}

const mapStateToProps    = state    => ({ appData:  state.appReducer });
const mapDispatchToProps = dispatch => ({ appActions: bindActionCreators(appActions, dispatch) });
export default connect(mapStateToProps, mapDispatchToProps)(Footer);