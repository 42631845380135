import React from 'react';
import './style.scss';

import CircularProgress from '@material-ui/core/CircularProgress';

class Button extends React.Component {

    render() {
        return (
            <button
                style={ { width: this.props.width } }
                type={ this.props.type ? this.props.type : 'button' }
                className={ `${ this.props.loading  ? 'with-progress' : '' } ${this.props.className} ${this.props.color} ${this.props.small ? "small-button" : ""}` }
                disabled={ this.props.disabled ? this.props.disabled : false }
                onClick={ this.props.action   ? this.props.action   : () => (false) } >
                    { this.props.loading ? <CircularProgress className="progress" /> : this.props.label }
            </button>
        );
    }
}

export default Button;
