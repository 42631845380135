import moment from 'moment'

export default function formatDate(date, withHour = false, onlyHour = false, shortDate = false) {
	if (withHour) {
		return moment(date).format('DD/MM/YYYY HH:mm')
	}

	if (shortDate) {
		return moment(date).format('DD/MM/YY')
	}

	if (onlyHour) {
		return moment(date).format('HH:mm[h]')
	}

	return moment(date).format('DD/MM/YYYY')
}
