import React, { useState } from 'react';

import { history } from '../../../../helpers/history';
import Button from '../../../../components/Button';
import jackpotsSevices from '../../../../services/jackpots';
import SendNewGames from '../../../../components/SendNewGames';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import './style.scss';
import { useParams } from 'react-router-dom'

const JackpotNew = (props) => {
		const {concourse} = useParams()

    const [alert, setAlert] = useState({ show: false, severity: 'info', message: '' });
    const [loading, setLoading] = useState(false);
    const [xlsx, setxlsx] = useState('');

    const attachFile = (event) => {
        setxlsx(event.target.files[0]);
    };

    const submit = async (event) => {
        event.preventDefault();

        const _concourse = concourse;

        if (!xlsx) {
            setAlert({ show: true, severity: 'warning', message: 'O campo arquivo é obrigatório.' });
            setTimeout(() => setAlert({ show: false, severity: 'warning', message: '' }), 5000)
            return false;
        }

        //check file!
        // if(!['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(xlsx.type)) {
        //     setAlert({ show: true, severity: 'warning', message: 'São aceitos somente arquivos XLSX ou ZIP' });
        //     setTimeout(() => setAlert({ show: false, severity: 'warning', message: '' }), 5000)
        //     return false;
        // }

        setLoading(true);
        const formData = new FormData()
        formData.append('jackpot_file', xlsx);
        formData.append('concourse_id', _concourse);
        formData.append('user_id', 1);

        const data = await jackpotsSevices.uploadGames(formData);

        if (data) {
            setAlert({ show: true, severity: 'success', message: 'Upload realizado com sucesso!' });
            setLoading(false);

            setTimeout(() => {
                setAlert({ show: false, severity: 'warning', message: '' });
                history.push(`/campanhas/concourses/${_concourse}`);
            }, 3000);
        }
        else {
            setAlert({ show: true, severity: 'warning', message: 'Falha ao enviar o arquivo, verifique o arquivo e tente novamente.' });
            setLoading(false);

            setTimeout(() => { setAlert({ show: false, severity: 'warning', message: '' }); }, 5000);
        }

    }

    return (
        <main id="new-game">
            <div id="title">
                <span className="back" onClick={event => history.back()}><ArrowBackIosIcon /> <span>Voltar</span></span>
                <h1>Cadastrar novo jogo</h1>
            </div>
            <form onSubmit={submit} noValidate>
                <Collapse className="feedback" in={alert.show}>
                    <Alert severity={alert.severity}>{alert.message}</Alert>
                </Collapse>
            </form>
        </main>
    );
}

export default JackpotNew;
