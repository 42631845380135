/* eslint-disable no-unused-expressions */
import Button from "../Button";
import "./style.scss";
import "../../assets/tailwind.css"

import { useEffect, useState } from "react";
import { GameRow } from "./game-row";
import DropzoneComponent from "../Dropzone";
import jackpots from "../../services/jackpots";
import Alert from "@material-ui/lab/Alert";
import { toast } from "react-toastify";
import { Modal } from "../Modal";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { DatePicker, Message } from 'rsuite';
import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import { isAfter, isBefore } from "date-fns";
import { textByLength } from "../../helpers/textByLength";

export default function SendNewGames({ activeTab, setActiveTab, concourse }) {
  const [processingFiles, setProcessingFiles] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [uploadItems, setUploadItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [responseSubmit, setResponseSubmit] = useState({
    show: false,
    type: '',
    message: '',
  })

  const [modalFilterOpen, setModalFilterOpen] = useState(false);
  const [openedModalErrors, setOpenedModalErrors] = useState(false);
  const [modalExclude, setModalExclude] = useState(false);
  const [modalPublish, setModalPublish] = useState(false);
  const [allCheck, setAllCheck] = useState(false);
  const [pagination, setPagination] = useState({});

  const [descriptionGlobalFilter, setDescriptionGlobalFilter] = useState("");
  const [startDateGlobalFilter, setStartDateGlobalFilter] = useState("");

  const uploadErrorList = uploadItems.filter((item) => item.error?.message)

  const ranges = [
    {
      label: 'Hoje',
      value: moment().toDate(),
    },
    {
      label: 'Amanhã',
      value: moment().add(1, 'd').toDate(),
    },
    {
      label: 'Início do Concurso',
      value: moment(concourse.start_date, 'DD-MM-YYYY').toDate(),
    },
  ];

  useEffect(() => {
    if (selectedRows.length === processingFiles.length) {
      setAllCheck(true)
      return
    }

    setAllCheck(false)
  }, [selectedRows])

  const getAllUploads = (page = 1, needLoading = true) => {
    needLoading && setLoading(true);
    jackpots.getProcessingJackpots(page, concourse.id).then(async (response) => {
      setLoading(false);
      if (response.meta.pagination.total_pages >= page) {
        setProcessingFiles(response.data);
        setPagination(response.meta.pagination);
        return
      } else {
        getAllUploads(page - 1)
      }
    });
  };

  useEffect(() => {
    if (concourse?.id && activeTab === "uploadGames") {
      getAllUploads();
    }
  }, [concourse, activeTab]);

  function paginate(page) {
    setSelectedRows([])
    setAllCheck(false)
    getAllUploads(page);
  }

  async function handleSelectAll(e) {
    if (e.target.checked) {
      const filteredGames = processingFiles.map((file) => (
        {
          id: file.id,
          status: file.status,
          fileName: file.original_name,
        }
      ))
      setSelectedRows(filteredGames);
      return;
    }
    setSelectedRows([]);
  }

  async function processUploads() {
    await jackpots.createJackpot(processingFiles.filter(row => row.status === "pending").map(item => item.id)).then(async (response) => {
      if (!response.errors) {
        await getAllUploads(pagination.current_page);
        await setSelectedRows([]);
        toast.success("Jogos enviados para fila de processamento.");
        setModalPublish(false)
        setActiveTab("processingGames")
      }
    })
  }

  async function excludeUploads() {
    await jackpots.deleteUploads(selectedRows.map(item => item.id)).then(async (response) => {
      if (!response.errors) {
        await getAllUploads(pagination.current_page);
        await setSelectedRows([]);
        toast.success("Jogos excluídos com sucesso!");
        setModalExclude(false)
      }
    })
  }

  function handleOpenFilterModal() {
    setModalFilterOpen(true);
  }

  async function handleFilterSubmit() {
    if (!descriptionGlobalFilter && !startDateGlobalFilter.date) {
      setResponseSubmit({
        show: true,
        type: 'error',
        message: "Você deve preencher pelo menos um campo."
      })
      return
    }

    setResponseSubmit({
      show: false,
      type: "",
      message: ""
    })
    setLoading(true);

    await jackpots.updateUpload({
      ...(descriptionGlobalFilter && { description: descriptionGlobalFilter }),
      ...(startDateGlobalFilter && {
        start_at:
          moment(startDateGlobalFilter.date)
            .hours(startDateGlobalFilter.hours || 0)
            .minutes(startDateGlobalFilter.minutes || 0)
            .format("yyyy-MM-DD HH:mm")
      }),
      ids: selectedRows.map(item => item.id)
    }).then(async (response) => {
      if (response) {
        await setDescriptionGlobalFilter("")
        await setStartDateGlobalFilter("")
        await setModalFilterOpen(false)
        await getAllUploads(pagination.current_page)
      }
    }).finally(() => setLoading(false))
  }

  return (
    <>
      <Modal
        title="Erros de upload"
        noSubmit
        open={openedModalErrors}
        setOpen={setOpenedModalErrors}
      >
        <>
          <Alert className="cursor-pointer w-fit" severity="error">
            <div className="flex flex-col">
              <span className="font-semibold">Alguns arquivos não foram enviados.</span>
              <span>{uploadErrorList.length} arquivos falharam para importar para o sistema, verifique os erros abaixo e tente novamente.</span>
            </div>
          </Alert>
          <span className="mt-4 mb-3 font-bold">
            Arquivos com falha ({uploadErrorList.length})
          </span>
          <div className="table-main">
            <table>
              <thead>
                <tr>
                  <th>
                    Arquivo
                  </th>
                  <th>
                    Descrição
                  </th>
                </tr>
              </thead>
              <tbody>
                {uploadErrorList.map((item) => (
                  <>
                    <tr key={item.error?.fileName}>
                      <td>
                        {item.error?.fileName}
                      </td>
                      <td>
                        {item.error?.message}
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </>
      </Modal>
      <Modal
        title="Confirmar exclusão"
        open={modalExclude}
        setOpen={setModalExclude}
        handleSubmit={excludeUploads}
        okText="Excluir"
      >
        <>
          <span className="mt-4 mb-3 font-bold">
            Arquivos selecionados ({selectedRows.length})
          </span>
          <table className="w-full overflow-hidden text-sm text-left text-gray-500 rounded-lg table-fixed">
            <thead className="text-xs text-gray-700 uppercase bg-gray-200">
              <tr className="">
                <th className="p-3">
                  Arquivo
                </th>
              </tr>
            </thead>
            <tbody className="flex flex-col w-full overflow-y-auto max-h-[300px]">
              {selectedRows.map((item) => {
                return (
                  <>
                    <tr key={item.fileName} className="w-full bg-white border-b">
                      <td className="p-3 font-medium text-gray-900 whitespace-nowrap">
                        {item.fileName}
                      </td>
                    </tr>
                  </>
                )
              }
              )}
            </tbody>
          </table>
        </>
      </Modal>
      <Modal
        title={`Aplicar parâmetros (${selectedRows.length})`}
        open={modalFilterOpen}
        setOpen={setModalFilterOpen}
        handleSubmit={handleFilterSubmit}
        okText="Aplicar"
      >
        <form className="flex flex-col gap-4" id="form">
          <div className="flex flex-col border-b border-solid border-neutral-6">
            <h5 className="text-base font-medium">Lançamento</h5>
            <div className="flex items-center w-full gap-2 pb-4">
              <div className="flex flex-col">
                <label htmlFor="start-date-filter">Data</label>
                <DatePicker
                  format="dd/MM/yyyy"
                  value={startDateGlobalFilter?.date}
                  className="w-full"
                  placeholder="Selecione a data"
                  disabledDate={date => {
                    return (
                      isBefore(date, moment(concourse.start_date, "DD-MM-YYYY").toDate()) ||
                      isAfter(date, moment(concourse.end_date, "DD-MM-YYYY").add(1, 'd').toDate())
                    )
                  }}
                  oneTap
                  onChange={(selectedDate) => {
                    setStartDateGlobalFilter({ ...startDateGlobalFilter, date: selectedDate, minutes: 0, hours: 0 })
                  }}
                  cleanable
                  ranges={ranges}
                  disabled={loading}
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="hour-date-filter">Hórario</label>
                <DatePicker
                  format="HH:mm"
                  placeholder="Selecione o horário"
                  disabledHours={hours => {
                    if (moment(concourse.end_date, "DD-MM-YYYY").format("DD-MM-YYYY") === moment(startDateGlobalFilter?.date).format("DD-MM-YYYY")) {
                      return hours > 20
                    }
                  }}
                  disabledMinutes={minutes => {
                    if (moment(concourse.end_date, "DD-MM-YYYY").format("DD-MM-YYYY") === moment(startDateGlobalFilter?.date).format("DD-MM-YYYY")) {
                      return minutes > 0
                    }
                  }}
                  value={moment().hours(startDateGlobalFilter?.hours || 0).minutes(startDateGlobalFilter?.minutes || 0).toDate()}
                  onChange={(selectedHour) => {
                    setStartDateGlobalFilter({ ...startDateGlobalFilter, hours: moment(selectedHour).hours() || 0, minutes: moment(selectedHour).minutes() || 0 })
                  }}
                  disabled={loading}
                />
              </div>
            </div>
          </div>
          <div>
            <label className="text-base font-medium" htmlFor="description-filter">
              Detalhes da aposta
            </label>
            <textarea
              className="border border-solid border-neutral-6 rounded-lg w-full p-3 scrollbar-track-rounded-full scrollbar-thumb-rounded-full min-h-[100px] scrollbar-thin scrollbar-thumb-neutral-5 scrollbar-track-neutral-6 resize-none placeholder:italic text-2xs text-neutral-1"
              onChange={(e) => {
                e.stopPropagation()
                setDescriptionGlobalFilter(e.target.value)
              }}
              value={descriptionGlobalFilter}
              placeholder="Descrição do jogo"
              disabled={loading}
            />
          </div>
          {responseSubmit.show && (
            <Message showIcon type={responseSubmit.type}>
              {responseSubmit.message}
            </Message>
          )}
        </form>
      </Modal>
      <Modal
        title="Confirmar processamento"
        open={modalPublish}
        setOpen={setModalPublish}
        handleSubmit={processUploads}
        okText="Processar"
      >
        <>
          <span className="mt-4 mb-3 font-bold">
            Arquivos selecionados ({processingFiles.filter(file => file.status === "pending").length})
          </span>
          <table className="w-full overflow-hidden text-sm text-left text-gray-500 rounded-lg table-fixed">
            <thead className="text-xs text-gray-700 uppercase bg-gray-200">
              <tr className="">
                <th className="p-3">
                  Arquivo
                </th>
              </tr>
            </thead>
            <tbody className="flex flex-col w-full overflow-y-auto max-h-[300px]">
              {processingFiles.filter(file => file.status === "pending").map((item) => {
                return (
                  <>
                    <tr key={item.original_name} className="w-full bg-white border-b">
                      <td className="p-3 font-medium text-gray-900 whitespace-nowrap">
                        {item.original_name}
                      </td>
                    </tr>
                  </>
                )
              }
              )}
            </tbody>
          </table>
        </>
      </Modal>
      <div id="list" className="mt-4">
        <div className="mt-6 mb-8">
          <DropzoneComponent
            type="jackpot"
            setUploadItems={setUploadItems}
            concourseID={concourse.id}
            refresh={getAllUploads}
          />
        </div>
        <div className="row-full">
          {uploadErrorList.length > 0 && (
            <div className="flex items-center justify-center my-4 mb-6">
              <Alert
                onClick={() => setOpenedModalErrors(true)}
                className="cursor-pointer w-fit"
                severity="error"
              >
                {`De ${uploadItems.length === 1 ? uploadItems.length + ' arquivo' : uploadItems.length + " arquivos"}, ${uploadErrorList.length} estão com erro. `}
                <span className="font-medium underline">
                  Clique aqui para ver os erros.
                </span>
              </Alert>
            </div>
          )}
          {processingFiles.length > 0 && <div className="flex items-center justify-between w-full mb-8 gap-x-4">
            <Button
              small
              color="green"
              action={handleOpenFilterModal}
              label={
                textByLength(
                  selectedRows.length > 1,
                  `Aplicar parâmetros em ${selectedRows.length} bolões`,
                  `Aplicar parâmetros em ${selectedRows.length} bolão`
                )
              }
              disabled={loading || selectedRows.length === 0}
            />
            <div className="flex items-center gap-x-4">
              <Button
                small
                className="self-end text-red-500 bg-white border-red-500 disabled:cursor-not-allowed disabled:bg-white disabled:opacity-70 disabled:hover:bg-white disabled:hover:text-red-500 hover:bg-red-500 hover:text-white"
                width="202px"
                color="red"
                action={() => setModalExclude(true)}
                label={
                  textByLength(
                    selectedRows.length > 1,
                    `Excluir ${selectedRows.length} bolões`,
                    `Excluir ${selectedRows.length} bolão`
                  )
                }
                disabled={loading || selectedRows.length === 0}
              />
              <Button
                small
                className="self-end"
                width="202px"
                color="green"
                action={() => setModalPublish(true)}
                label={
                  textByLength(
                    processingFiles.filter(file => file.status === "pending").length > 1,
                    `Processar ${processingFiles.filter(file => file.status === "pending").length} bolões`,
                    `Processar ${processingFiles.filter(file => file.status === "pending").length} bolão`
                  )
                }
                disabled={loading || processingFiles.filter(file => file.status === "pending").length === 0}
              />
            </div>
          </div>}
          {processingFiles.length > 0 && <div className="checkbox">
            <input
              type="checkbox"
              id="checkbox-all"
              value={allCheck}
              checked={allCheck}
              onChange={(e) => {
                handleSelectAll(e);
                setAllCheck(e.target.checked);
              }}
            />
            <label htmlFor="checkbox-all" className="pl-2 text-base font-semibold cursor-pointer text-neutral-2">
              Marcar todos
            </label>
          </div>}
          <div className="relative game-list">
            {loading && (
              <div className="absolute z-10 flex items-center justify-center w-full h-full pointer-events-none bg-white/70">
                <CircularProgress size={40} className="text-primary icon" />
              </div>
            )}
            {!loading &&
              Object.values(processingFiles).length === 0 && (
                <div className="w-full max-w-2xl p-6 mx-auto bg-gray-200 rounded-md">
                  <div className="text-xl font-medium text-center text-black">
                    <span>Nenhum arquivo encontrado.</span>
                  </div>
                </div>
              )}
            <div className="grid grid-cols-3 gap-4 2xl:grid-cols-4">
              {uploadItems.map((file) => {
                return (
                  <GameRow
                    allDisabled={false}
                    show={file.show}
                    refresh={() => getAllUploads(pagination.current_page)}
                    loading={loading}
                    concourse={concourse}
                    key={file.id + Math.random()}
                    file={file}
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                  />
                );
              })}
              {processingFiles?.map((file) => {
                return (
                  <GameRow
                    allDisabled={false}
                    refresh={() => getAllUploads(pagination.current_page, false)}
                    loading={loading}
                    key={file.id + Math.random()}
                    file={file}
                    concourse={concourse}
                    description={file.description}
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                  />
                );
              })}
            </div>
            <div className="flex items-center justify-center gap-4">
              {pagination.links?.previous ? <ArrowBackIosIcon className="link" onClick={event => paginate(pagination.current_page - 1)} /> : null}
              {pagination.count ? <span className="labels">{pagination.current_page} de {pagination.total_pages} {pagination.total_pages > 1 ? 'Páginas' : 'Página'}</span> : null}
              {pagination.links?.next ? <ArrowForwardIosIcon className="link" onClick={event => paginate(pagination.current_page + 1)} /> : null}
            </div>
          </div>
          {processingFiles.length > 0 && <div className="flex items-center justify-end w-full mt-4 mb-20 gap-x-4">
            <Button
              small
              className="self-end text-red-500 bg-white border-red-500 disabled:cursor-not-allowed disabled:bg-white disabled:opacity-70 disabled:hover:bg-white disabled:hover:text-red-500 hover:bg-red-500 hover:text-white"
              width="202px"
              color="red"
              action={() => setModalExclude(true)}
              label={
                textByLength(
                  selectedRows.length > 1,
                  `Excluir ${selectedRows.length} bolões`,
                  `Excluir ${selectedRows.length} bolão`
                )
              }
              disabled={loading || selectedRows.length === 0}
            />
            <Button
              small
              className="self-end"
              width="202px"
              color="green"
              action={() => setModalPublish(true)}
              label={
                textByLength(
                  processingFiles.filter(file => file.status === "pending").length > 1,
                  `Processar ${processingFiles.filter(file => file.status === "pending").length} bolões`,
                  `Processar ${processingFiles.filter(file => file.status === "pending").length} bolão`
                )
              }
              disabled={loading || processingFiles.filter(file => file.status === "pending").length === 0}
            />
          </div>}
        </div>
      </div>
    </>
  );
}
