import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { OpenInNew } from '@material-ui/icons'
import { CheckPicker, DateRangePicker, Input, MaskedInput } from 'rsuite';
import { addDays, addMonths, endOfMonth, endOfWeek, startOfMonth, startOfWeek, subDays } from 'date-fns';
import {
  FilterAltOff, Fingerprint, PermIdentity,
} from '@mui/icons-material'

import Title from '../../../components/Title';
import Loading from '../../../components/Loading';
import Button from '../../../components/Button';
import { history } from '../../../helpers/history';
import Table from '../../../components/Table';
import DrawerFilters from '../../../components/DrawerFilters'
import users from '../../../services/users'
import { formatStringByMask } from '../../../helpers/formatStringByMask'
import TrashIcon from '@material-ui/icons/DeleteOutline'
import Tooltip from '@material-ui/core/Tooltip'
import affiliateService from '../../../services/affiliate'
import { AwardIcon } from 'lucide-react'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'

const cpfMask = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]
const phoneMask = ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]

const predefinedPastRanges = [
  {
    label: 'Hoje',
    value: [new Date(), new Date()],
    placement: 'left'
  },
  {
    label: 'Ontem',
    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
    placement: 'left'
  },
  {
    label: 'Essa semana',
    value: [startOfWeek(new Date()), endOfWeek(new Date())],
    placement: 'left'
  },
  {
    label: 'Últimos 7 dias',
    value: [subDays(new Date(), 6), new Date()],
    placement: 'left'
  },
  {
    label: 'Últimos 30 dias',
    value: [subDays(new Date(), 29), new Date()],
    placement: 'left'
  },
  {
    label: 'Esse mês',
    value: [startOfMonth(new Date()), new Date()],
    placement: 'left'
  },
  {
    label: 'Mês passado',
    value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
    placement: 'left'
  },
];

export default function UsersList() {
  const [openDrawerFilters, setOpenDrawerFilters] = useState(false);
  const [filters, setFilters] = useState({})
  const [tableData, setTableData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState({})

  const filterCount = Object.entries(getFilters()).length;

  useEffect(() => {
    getTableData()
  }, [])

  async function getTableData(reset = false, page = 1){
    setLoading(true);

    users.getUsers(!reset && {
      ...getFilters(),
    },
    {
      page,
      ...(filters?.created_at?.formatted && {
        searchFields: "created_at:between"
      }),
    }
    )
    .then((res) => {
      setTableData(res.data)
      setPagination(res.meta.pagination)
    })
    .finally(() => {
      setLoading(false)
    });
  }

  function getFilters() {
    const activeFilters = {}

    Object.entries(filters)
      .filter(([key, value]) => {
        const currentValue = value?.formatted || value?.raw
        if (!currentValue || currentValue === "" || currentValue.length === 0) return false
        return true
      })
      .map(([key, value]) => {
        activeFilters[key] = value
      })

    return activeFilters
  }

  const paginate = (page) => {
    getTableData(false, page);
  }

  async function onSubmitFilters(reset = false) {
    if (reset) {
      await setFilters({})
    }

    setOpenDrawerFilters(false);
    getTableData(reset);
  }
  
  async function handleNewAffiliate(row) {
    await affiliateService.transformUser(row)
  }
  
  const isAffiliate = (row) => {
    return row?.roles?.data?.some(role => role.name === 'Afiliado')
  }

  const handleClose = () => setDialogOpen(false)
  
  const handleConfirm = () => {
    handleNewAffiliate(dialogData)
    setDialogOpen(false)
    getTableData()
  }

  return (
    <main>
      <Title label="Clientes" />

      <DrawerFilters
        open={openDrawerFilters}
        setOpen={setOpenDrawerFilters}
        onSubmit={() => onSubmitFilters()}
        filters={[
          {
            label: "Nome",
            name: "first_name",
            field: () => (
              <Input
                id="input-name"
                value={filters?.first_name?.raw}
                onChange={(value) =>
                  setFilters((state) => {
                    return {
                      ...state,
                      first_name: {
                        raw: value,
                        formatted: value,
                      }
                    }
                  })
                }
                placeholder="Nome do cliente"
                className="w-full"
              />
            ),
          },
          {
            label: "Sobrenome",
            name: "last_name",
            field: () => (
              <Input
                id="input-name"
                value={filters?.last_name?.raw}
                onChange={(value) =>
                  setFilters((state) => {
                    return {
                      ...state,
                      last_name: {
                        raw: value,
                        formatted: value,
                      }
                    }
                  })
                }
                placeholder="Nome do cliente"
                className="w-full"
              />
            ),
          },
          {
            label: "Email",
            name: "email",
            field: () => (
              <Input
                id="input-email"
                value={filters?.email?.raw}
                onChange={(value) =>
                  setFilters((state) => ({
                      ...state,
                      email: {
                        raw: value,
                        formatted: value,
                      },
                    })
                  )}
                placeholder="Email do cliente"
                type='email' className="w-full"
              />
            ),
          },
          {
            label: "CPF",
            name: "cpf",
            field: () => (
              <MaskedInput
                id="input-cpf"
                value={filters?.cpf?.raw}
                onChange={(value) =>
                  setFilters((state) => ({
                      ...state,
                      cpf: {
                        raw: value,
                        formatted: value?.replace(/\D/g, ""),
                      },
                    })
                  )}
                placeholder="CPF do cliente"
                mask={cpfMask} className="w-full"
              />
            ),
          },
          {
            label: "Data de criação",
            name: "created_at",
            field: () => (
              <DateRangePicker
	              placement="auto"
                id="input-period"
                value={filters?.created_at?.raw}
                format="dd/MM/yyyy"
                onChange={(value) => {
                  setFilters((state) => {
                    return ({
                      ...state,
                      created_at: {
                        raw: value,
	                      formatted: !value ? null : encodeURI([moment(value && value[0]).format('YYYY-MM-DD'), moment(value && value[1]).format('YYYY-MM-DD')]),
                      },
                    })
                  })
                }}
                showOneCalendar
                ranges={predefinedPastRanges}
                placeholder="Escolha um período"
                className='w-full'
              />
            ),
          },
	        {
		        label: "Telefone",
		        name: "phone",
		        field: () => (
			        <MaskedInput
				        id="input-phone"
				        value={filters?.phone?.raw}
				        onChange={(value) =>
					        setFilters((state) => ({
							        ...state,
							        phone: {
								        raw: value,
								        formatted: value?.replace(/\D/g, ""),
							        },
						        })
					        )}
				        placeholder="Telefone do cliente"
				        mask={phoneMask} className="w-full"
			        />
		        ),
	        },
        ]}
      />

      {pagination?.total > 0 && (
        <div id="infos" className="flex justify-end w-full mb-4">
          <span>Total de <strong>{pagination?.total || 0}</strong> registros encontradas.</span>
        </div>
      )}

      <div className="flex items-center justify-end w-full gap-2 mb-4">
        {filterCount > 0 && (
          <FilterAltOff
            onClick={() => onSubmitFilters(true)}
            style={{ cursor: 'pointer', fill: '#209869' }}
          />
        )}
        <Button
          small
          width="202px"
          color="green"
          action={setOpenDrawerFilters}
          label={`Aplicar filtros ${filterCount > 0 ? `(${filterCount})` : ''}`}
          disabled={loading}
        />
      </div>

      <Table
        rows={tableData}
        renderRow={(row) => [
          { headerName: "Nome", render: () => row.first_name + " " + row.last_name },
          { headerName: "CPF", render: () => formatStringByMask("XXX.XXX.XXX-XX", row.cpf) },
          { headerName: "Email", render: () => row.email },
          { headerName: "Telefone", render: () => formatStringByMask("(XX) XXXXX-XXXX", row.phone) },
          { headerName: "Criado em", render: () => moment(row.created_at, "YYYY-MM-DD HH:mm").format("DD/MM/YYYY HH:mm") },
          {
            headerName: "Ações", render: () =>
              <div className="flex items-center gap-2">
                <OpenInNew onClick={() => history.push(`/usuarios/${row.id}`)} style={{ cursor: "pointer", fill: "#209869" }} />
                {!isAffiliate(row) && (
                  <Tooltip title="Adicionar como afiliado" placement="top" aria-label="show">
                    <button type={'button'} className={'link'} onClick={() => {
                      setDialogData(row)
                      setDialogOpen(true)
                    }}>
                      <AwardIcon style={{ cursor: 'pointer' }}/>
                    </button>
                  </Tooltip>
                )}
              </div>
          },
        ]}
        pagination={pagination}
        paginate={paginate}
        loading={loading}
      />

      <Dialog open={dialogOpen} onClose={handleClose} className="confirmation">
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">Você tem certeza?</DialogTitle>
        <DialogContent>
          <DialogContentText>Deseja adicionar este usuário como afiliado?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <span className="link" onClick={handleClose}>Cancelar</span>
          <span className="link" onClick={handleConfirm}>Adicionar</span>
        </DialogActions>
      </Dialog>
    </main>
  )
}
