import type { Condition, RefineErrors } from "@hammerstone/refine-react";
import { QueryBuilder } from "@hammerstone/refine-react";

import {
  userEmailCondition,
  itemsQuantityCondition,
  jackpotValueCondition,
  jackpotRefCondition,
  concourseSkuCondition,
  concourseTypeSelect, minCartAmount, maxCartAmount,
} from "./conditions/conditions";
import {Control, Controller, FieldValues} from "react-hook-form";

const conditions: Condition[] = [
  // concourseCondition,
  // jackpotCondition,
  minCartAmount,
  maxCartAmount,
  // userEmailCondition,
  itemsQuantityCondition,
  jackpotValueCondition,
  jackpotRefCondition,
  concourseSkuCondition,
  concourseTypeSelect,
];

const flavors = [
  {
    name: "base",
    flavor: {
      select: {
        className: 'refine-query-builder-select',
      },
      group: {
        className: 'refine-query-builder-group',
        wrapper: {},

        addCriterionButton: {
          className: 'refine-query-builder-group-add-criterion-button',
          text: {},
        },
      },

      addGroupButton: {
        className: 'refine-query-builder-add-group-button',
      },

      criterion: {
        className: 'refine-query-builder-criterion',

        wrapper: {
          className: 'refine-query-builder-criterion-wrapper',
        },

        removeCriterionButton: {
          className: 'refine-query-builder-criterion-remove-criterion-button',
        },

        errors: {
          className: 'refine-query-builder-criterion-errors',

          error: {
            className: 'refine-query-builder-criterion-errors-error text-tiny text-danger',
          },
        },
      },

      condition: {
        className: 'refine-query-builder-condition flex-1',
      },

      multi: {
        button: {
          placeholder: {},

          selected: {},

          deselect: {
            icon: {
              wrapper: {},
            },
          },

          icon: {
            wrapper: {},
          },
        },
      },

      inputs: {
        date: {
          className: 'refine-query-builder-inputs-date',

          double: {
            className: 'refine-query-builder-inputs-date-double',

            wrapper: {
              className: 'refine-query-builder-inputs-date-double-wrapper',
            },

            joiner: {},
          },

          relative: {
            className: 'refine-query-builder-inputs-date-relative',

            wrapper: {
              className: 'refine-query-builder-inputs-date-relative-wrapper',
            },
          },

          calendar: {
            icon: {},
          },

          error: {
            icon: {},
          },
        },

        number: {
          className: 'refine-query-builder-inputs-number',

          double: {
            className: 'refine-query-builder-inputs-number-double',

            wrapper: {
              className: 'refine-query-builder-inputs-number-double-wrapper',
            },

            joiner: {},
          },
        },

        text: {
          className: 'refine-query-builder-inputs-text',
        },
      },
    }
  },
];

export const blueprints = [
  {
    name: "initial",
    blueprint: [
      {
        "id": "minimun_value",
        "condition_id": "minimun_value",
        "depth": 1,
        "type": "criterion",
        "input": {
          "clause": "gte",
          "type": "number",
          "value": ""
        },
        "uid": "14890-1729776773"
      },
      {
        "depth": 1,
        "type": "conjunction",
        "word": "and",
        "uid": "15516-1729776776"
      },
      {
        "id": "maximum_value",
        "condition_id": "maximum_value",
        "depth": 1,
        "type": "criterion",
        "input": {
          "clause": "lte",
          "type": "number",
          "value": ""
        },
        "uid": "19317-1729776780"
      }
    ]
  },
];

export const QueryBuilderCustom = ({control}: { control: any }) => {
  const chosenFlavor = flavors[0];
  const chosenBlueprint = blueprints[0];

  return (
    <div className="mt-2">
      <Controller
        name="rules"
        control={control}
        render={({ field, fieldState }) => {
          const objErrors = {}
          
          const fieldValue = field?.value || chosenBlueprint.blueprint

          fieldValue?.filter(row => row?.id).map(rule => {
            const ruleRowId = rule?.uid

            if (rule?.input?.type === 'select' && rule?.input?.value?.length === 0) {
              objErrors[ruleRowId] = [{ id: rule?.id, message: "" }]
              
              return rule
            }
            
            if (rule?.input?.type === 'text' || rule?.input?.type === 'number') {
              if (
                !rule?.input?.value
                || (typeof rule?.input?.value === 'object' && (!rule?.input?.value?.[0] || !rule?.input?.value?.[1]))
                || (rule?.input?.value && typeof rule?.input?.value === 'string' && rule?.input?.value?.endsWith(','))
              ) {
                objErrors[ruleRowId] = [{ id: rule?.id, message: "" }]
                
                return rule
              }
            }
            
            
            return rule
          })


          return (
            <div className={'col-span-2'}>
              <label htmlFor="rules" className="text-sm font-medium">Condicionais e critérios</label>
              <QueryBuilder
                key={`${chosenFlavor.name}-${chosenBlueprint.name}`}
                blueprint={field.value || chosenBlueprint.blueprint}
                conditions={conditions}
                errors={objErrors}
                flavor={chosenFlavor.flavor}
                onChange={field.onChange}
              />
            </div>
          )
        }}
      />
    </div>
  );
};
