import React, { useEffect, useState } from 'react'

import { history } from '../../../helpers/history'
import Button from '../../../components/Button'
import termsService from '../../../services/terms'
import Title from '../../../components/Title'
import RichEditor from '../../../components/RichEditor'
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined'

import Alert from '@material-ui/lab/Alert'
import Collapse from '@material-ui/core/Collapse'

import './style.scss'
import 'react-datepicker/dist/react-datepicker.css'
import formatDate from '../../../helpers/formatDate'
import { useParams } from 'react-router-dom'

const TermsEdit = (props) => {
	const {id} = useParams()
	const [term, setTerm] = useState([])
	const [loading, setLoading] = useState(false)


	const getAllTerms = async () => {
		setLoading(true)
		const data = await termsService.getTermByID({ id })
		if (data) {
			setTerm(data.data)
		}
		setLoading(false)
	}

	useEffect(() => {
		getAllTerms()
	}, [])

	return (<main id="new-game">
			<Title label={`Termo: ${term?.name ?? ''}`}/>
			<div>
				<p className="text-2xs font-medium mb-8">Última atualização: {formatDate(term?.updated_at, true)}</p>
				<div dangerouslySetInnerHTML={{ __html: term?.content_text }}/>
			</div>
		</main>)
}

export default TermsEdit
