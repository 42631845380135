import api from './axiosInstances';

const concourses = {
    async getConcourses(params) {
        try {

            let _url = '/admin/concourses?';
            _url += params && params.page       ? "&page="          + params.page       : "";
            _url += params && params.status     ? "&status="        + params.status     : "";
            _url += params && params.has_paid   ? "&has_paid="      + params.has_paid   : "";
            _url += params && params.search     ? "&q="             + params.search     : "";
            const { data } = await api.get(_url,params);
            return data;
        }
        catch(error) {
            console.log(error);
            return false;
        }
    },
    async getMusSchedule(params) {
        try {
            const { data } = await api.get('/admin/mus-rewards-schedule?', {
	            params: {
		            order_by: 'created_at',
		            sort_by: 'desc',
		            per_page: 20,
		            ...params,
		            ...(params?.status && ({ status: params.status.join(',')})),
	            },
            });
            return data;
        }
        catch(error) {
            console.log(error);
            return false;
        }
    },
    async addConcourse(params){
        try {
            let _url = '/admin/concourses/create';
            const { data } = await api.post(_url,params);
            return data;
        }
        catch(error) {
            console.log(error);
            return false;
        }
    },
    async updateConcourse(id,params) {
        try {
            let _url = `/admin/concourses/${id}`;
            const { data } = await api.post(_url,params);
            return data;
        }
        catch(error) {
            console.log(error);
            return false;
        }
    },
    async getConcourse(id){
        try {

            let _url = `/admin/concourses/${id}`;
            const { data } = await api.get(_url);
            return data;
        }
        catch(error) {
            console.log(error);
            return false;
        }
    },
    async doPayment(concourseId) {
        try {
            await api.post('/admin/concourses/pay/'+ concourseId)
            return true;
        }
        catch(error) {
            console.log(error);
            return false;
        }
    }
}
export default concourses;
