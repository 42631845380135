import React, { useState, useEffect, useRef } from 'react';
import { parse, format } from 'date-fns';
import CurrencyInput from 'react-currency-input-field';

import { history } from '../../../../helpers/history';
import Button from '../../../../components/Button';
import concourseServices from '../../../../services/concourse';
import Title from '../../../../components/Title';
import DatePicker from '../../../../components/DateTimePicker';


import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';

import './style.scss';
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from 'react-router-dom'

const BannerEdit = (props) => {
		const {id: paramID} = useParams()
    const [id, setId] = useState(null);
    const [number, setNumber] = useState('');
    const [drawDdate, setdrawDdate] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [premiumEstimate, setPremiumEstimate] = useState('');
    const [status, setStatus] = useState('');
    const [name, setName] = useState('');
    const [typeGame, setTypeGame] = useState('');
    const [channelType, setChannelType] = useState('');

    const [alert, setAlert] = useState({ show: false, severity: 'info', message: '' });
    const [loading, setLoading] = useState(false);

    useEffect(() => { getConcourse() }, [paramID]);

    const getConcourse = async () => {
        const _id = paramID;
        const { data } = await concourseServices.getConcourse(_id);
        setId(data.id);
        setNumber(data.number);
        setName(data.name);
        setPremiumEstimate(data.premium_estimate);
        setStatus(data.status);
        setTypeGame(data.type_game.slug);
        setChannelType(data.target_channel);

        data.date_draw && setdrawDdate(parse(data.date_draw, "dd-MM-yyyy HH:mm:ss", new Date()));
        data.start_date && setStartDate(parse(data.start_date, "dd-MM-yyyy HH:mm:ss", new Date()));
        data.end_date && setEndDate(parse(data.end_date, "dd-MM-yyyy HH:mm:ss", new Date()));
    }

    const submit = async (event) => {
        event.preventDefault();

        if (!drawDdate || drawDdate < new Date()) {
            setAlert({ show: true, severity: 'warning', message: 'O campo Data do sorteio é obrigatório.' });
            setTimeout(() => setAlert({ show: false, severity: 'warning', message: '' }), 5000)
            return false;
        }

        if (!startDate) {
            setAlert({ show: true, severity: 'warning', message: 'O campo Data de inicio é obrigatório.' });
            setTimeout(() => setAlert({ show: false, severity: 'warning', message: '' }), 5000)
            return false;
        }

        if (!endDate) {
            setAlert({ show: true, severity: 'warning', message: 'O campo Data de término é obrigatório.' });
            setTimeout(() => setAlert({ show: false, severity: 'warning', message: '' }), 5000)
            return false;
        }

        if (!premiumEstimate) {
            setAlert({ show: true, severity: 'warning', message: 'O campo Prêmio estimado é obrigatório.' });
            setTimeout(() => setAlert({ show: false, severity: 'warning', message: '' }), 5000)
            return false;
        }

        if (!number) {
            setAlert({ show: true, severity: 'warning', message: 'O campo Número é obrigatório.' });
            setTimeout(() => setAlert({ show: false, severity: 'warning', message: '' }), 5000)
            return false;
        }

        if (!name) {
            setAlert({ show: true, severity: 'warning', message: 'O campo Nome é obrigatório.' });
            setTimeout(() => setAlert({ show: false, severity: 'warning', message: '' }), 5000)
            return false;
        }

        if (!status) {
            setAlert({ show: true, severity: 'warning', message: 'O campo Status é obrigatório.' });
            setTimeout(() => setAlert({ show: false, severity: 'warning', message: '' }), 5000)
            return false;
        }

        if (!typeGame) {
            setAlert({ show: true, severity: 'warning', message: 'O campo Tipo de Jogo é obrigatório.' });
            setTimeout(() => setAlert({ show: false, severity: 'warning', message: '' }), 5000)
            return false;
        }

        if (!channelType) {
            setAlert({ show: true, severity: 'warning', message: 'O campo Tipo do canal é obrigatório.' });
            setTimeout(() => setAlert({ show: false, severity: 'warning', message: '' }), 5000)
            return false;
        }

        setLoading(true);
        const formData = new FormData();
        formData.append('premium_estimate', premiumEstimate);
        formData.append('type_game', typeGame);
        formData.append('target_channel', channelType);
        formData.append('number', number);
        formData.append('name', name);
        formData.append('status', status ? status : 'draft');
        formData.append('draw_date', drawDdate ? format(drawDdate, "yyyy-MM-dd HH:mm:ss") : format(new Date(), "yyyy-MM-dd HH:mm:ss"));
        formData.append('start_date', startDate ? format(startDate, "yyyy-MM-dd HH:mm:ss") : format(new Date(), "yyyy-MM-dd HH:mm:ss"));
        formData.append('end_date', endDate ? format(endDate, "yyyy-MM-dd HH:mm:ss") : format(new Date(), "yyyy-MM-dd HH:mm:ss"));

        const response = await concourseServices.updateConcourse(id, formData);
        if (response) {
            setAlert({ show: true, severity: 'success', message: 'Atualizado com sucesso!' });
            setLoading(false);

            setTimeout(() => {
                setAlert({ show: false, severity: 'warning', message: '' });
                history.push('/campanhas/concourses');
            }, 2000);
        }
        else {
            setAlert({ show: true, severity: 'warning', message: 'Não conseguimos salvar esse concurso' });
            setLoading(false);
            setTimeout(() => { setAlert({ show: false, severity: 'warning', message: '' }); }, 5000);
        }

    }

    const sortedStatus = [
        { label: "Rascunho", value: "draft" },
        { label: "Cancelado", value: "cancelled" },
        { label: "Reembolsado", value: "refunded" },
        { label: "Aguardando", value: "awaiting" },
        { label: "Em produção", value: "production" },
        { label: "Encerrado", value: "finished" },
    ].sort((a, b) => a.label.localeCompare(b.label));

    const sortedTypes = [
        { value: "mega-sena", label: "Mega sena" },
        { value: "dupla-sena", label: "Dupla sena" },
        { value: "lotomania", label: "Lotomania" },
        { value: "quina", label: "Quina" },
        { value: "lotofacil", label: "Lotofácil" },
        { value: "dia-de-sorte", label: "Dia de sorte" },
        { value: "super-sete", label: "Super sete" },
        { value: "timemania", label: "Timemania" },
        { value: "mais-milionaria", label: "+Milionária" },
    ].sort((a, b) => a.label.localeCompare(b.label));

    const sortedChannels = [
        { value: "web_site", label: "Site" },
        { value: "bot_telegram", label: "Telegram" },
    ].sort((a, b) => a.label.localeCompare(b.label));

    return (
        <main id="edit-concourse">

            <Title label="Editar Concurso" />

            <form onSubmit={submit} noValidate>

                <Collapse className="feedback" in={alert.show}>
                    <Alert severity={alert.severity}>{alert.message}</Alert>
                </Collapse>

                <div className="row">
                    <div className="col-6 form-group">
                        <label htmlFor="number">Número:</label>
                        <input type="number" className="form-control" id="number" required disabled={loading} value={number} onChange={event => setNumber(event.target.value)} />
                    </div>
                    <div className="col-6 form-group">
                        <label htmlFor="name">Nome:</label>
                        <input type="text" className="form-control" id="name" required disabled={loading} value={name} onChange={event => setName(event.target.value)} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 form-group">
                        <label htmlFor="draw-date" style={{ width: "100%" }}>Data do sorteio:</label>
                        <DatePicker
                            id="draw-date"
                            selectedDate={drawDdate}
                            onChange={date => setdrawDdate(date)}
                        />
                    </div>
                    <div className="col-6 form-group">
                        <label htmlFor="premium-estimate">Prêmio estimado:</label>
                        <CurrencyInput
                            id="premium-estimate"
                            name="premium-estimate"
                            className="form-control"
                            placeholder="Please enter a number"
                            prefix="R$"
                            groupSeparator="."
                            decimalSeparator=","
                            value={premiumEstimate}
                            defaultValue={0}
                            decimalsLimit={2}
                            onValueChange={(value, name) => setPremiumEstimate(value)}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-6 form-group">
                        <label htmlFor="start-date" style={{ width: "100%" }}>Data de início:</label>
                        <DatePicker
                            id="start-date"
                            selectedDate={startDate}
                            onChange={date => setStartDate(date)}
                        />
                    </div>
                    <div className="col-6 form-group">
                        <label htmlFor="end-date" style={{ width: "100%" }}>Data de término:</label>
                        <DatePicker
                            id="end-date"
                            selectedDate={endDate}
                            onChange={date => setEndDate(date)}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-6 form-group">
                        <label htmlFor="concourse-status">Status:</label>
                        <select name="concourse-status" id="concourse-status" className="form-control" value={status} onChange={event => setStatus(event.target.value.toString())}>
                            <option value="">Selecione um status</option>
                            {sortedStatus.map(item => (
                                <option value={item.value}>{item.label}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-6 form-group">
                        <label htmlFor="game-type">Tipo de jogo:</label>
                        <select name="game-type" id="game-type" className="form-control" value={typeGame} onChange={event => setTypeGame(event.target.value)}>
                            <option value="">Selecione um tipo de jogo</option>
                            {sortedTypes.map(item => (
                                <option value={item.value}>{item.label}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 form-group">
                        <label htmlFor="channel-type">Tipo do canal:</label>
                        <select name="channel-type" id="channel-type" className="form-control" value={channelType}
                                onChange={event => setChannelType(event.target.value)}>
                            <option value="">Selecione um tipo de canal</option>
                            <option value="all">Todos</option>
                            {sortedChannels.map(item => (
                                <option value={item.value}>{item.label}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="form-group">
                    <Button type="submit" width="40%" color="green" label="Enviar" loading={loading}
                            disabled={loading}/>
                </div>

            </form>
        </main>
    );
}

export default BannerEdit;
