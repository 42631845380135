import type { Condition } from 'refine-core/types';

export const booleanCondition: Condition = {
  id: 'bool',
  component: 'BooleanCondition',
  display: 'Bool',
  meta: {
    clauses: [
      {
        id: 'true',
        display: 'Verdadeiro',
        meta: {},
      },
      {
        id: 'false',
        display: 'Falso',
        meta: {},
      },
    ],
  },
  refinements: [],
};

export const dateCondition: Condition = {
  id: 'date',
  component: 'DateCondition',
  display: 'Date',
  meta: {
    clauses: [
      {
        id: 'eq',
        display: 'Is Equal To',
        component: 'RefineDateInput',
        meta: {},
      },
      {
        id: 'dne',
        display: 'Is Not Equal To',
        component: 'RefineDateInput',
        meta: {},
      },
      {
        id: 'lte',
        display: 'Is On or Before',
        component: 'RefineDateInput',
        meta: {},
      },
      {
        id: 'gte',
        display: 'Is On or After',
        component: 'RefineDateInput',
        meta: {},
      },
      {
        id: 'btwn',
        display: 'Is Between',
        component: 'RefineDoubleDateInput',
        meta: {},
      },
      {
        id: 'gt',
        display: 'Is More Than',
        component: 'RefineRelativeDateInput',
        meta: {
          units: [
            {
              id: 'day',
              display: 'Days',
            },
            {
              id: 'week',
              display: 'Weeks',
            },
            {
              id: 'month',
              display: 'Months',
            },
            {
              id: 'year',
              display: 'Years',
            },
          ],
          modifiers: [
            {
              id: 'ago',
              display: 'Ago',
            },
            {
              id: 'from_now',
              display: 'From Now',
            },
          ],
        },
      },
      {
        id: 'exct',
        display: 'Is Exactly',
        component: 'RefineRelativeDateInput',
        meta: {
          units: [
            {
              id: 'day',
              display: 'Days',
            },
            {
              id: 'week',
              display: 'Weeks',
            },
            {
              id: 'month',
              display: 'Months',
            },
            {
              id: 'year',
              display: 'Years',
            },
          ],
          modifiers: [
            {
              id: 'ago',
              display: 'Ago',
            },
            {
              id: 'from_now',
              display: 'From Now',
            },
          ],
        },
      },
      {
        id: 'lt',
        display: 'Is Less Than',
        component: 'RefineRelativeDateInput',
        meta: {
          units: [
            {
              id: 'day',
              display: 'Days',
            },
            {
              id: 'week',
              display: 'Weeks',
            },
            {
              id: 'month',
              display: 'Months',
            },
            {
              id: 'year',
              display: 'Years',
            },
          ],
          modifiers: [
            {
              id: 'ago',
              display: 'Ago',
            },
            {
              id: 'from_now',
              display: 'From Now',
            },
          ],
        },
      },
      {
        id: 'st',
        display: 'Is Set',
        meta: {},
      },
      {
        id: 'nst',
        display: 'Is Not Set',
        meta: {},
      },
    ],
  },
  refinements: [],
};

export const dateWithTimeCondition: Condition = {
  id: 'date_with_time',
  component: 'DateCondition',
  display: 'Date With Time',
  meta: {
    clauses: [
      {
        id: 'eq',
        display: 'Is Equal To',
        component: 'RefineDateInput',
        meta: {},
      },
      {
        id: 'dne',
        display: 'Is Not Equal To',
        component: 'RefineDateInput',
        meta: {},
      },
      {
        id: 'lte',
        display: 'Is On or Before',
        component: 'RefineDateInput',
        meta: {},
      },
      {
        id: 'gte',
        display: 'Is On or After',
        component: 'RefineDateInput',
        meta: {},
      },
      {
        id: 'btwn',
        display: 'Is Between',
        component: 'RefineDoubleDateInput',
        meta: {},
      },
      {
        id: 'gt',
        display: 'Is More Than',
        component: 'RefineRelativeDateInput',
        meta: {},
      },
      {
        id: 'exct',
        display: 'Is Exactly',
        component: 'RefineRelativeDateInput',
        meta: {},
      },
      {
        id: 'lt',
        display: 'Is Less Than',
        component: 'RefineRelativeDateInput',
        meta: {},
      },
      {
        id: 'st',
        display: 'Is Set',
        meta: {},
      },
      {
        id: 'nst',
        display: 'Is Not Set',
        meta: {},
      },
    ],
  },
  refinements: [],
};

export const concourseTypeSelect: Condition = {
  id: 'concourse_type',
  component: 'TextCondition',
  display: 'Tipo do concurso',
  meta: {
    type: 'select',
    clauses: [
      {
        id: 'eq',
        component: 'RefineOptionInput',
        display: 'É igual a',
        meta: {},
      },
      {
        id: 'dne',
        component: 'RefineOptionInput',
        display: 'Não é',
        meta: {},
      },
    ],
    options: [
      { id: "mega-sena", display: "Mega sena" },
      { id: "dupla-sena", display: "Dupla sena" },
      { id: "lotomania", display: "Lotomania" },
      { id: "quina", display: "Quina" },
      { id: "lotofacil", display: "Lotofácil" },
      { id: "dia-de-sorte", display: "Dia de sorte" },
      { id: "super-sete", display: "Super sete" },
      { id: "timemania", display: "Timemania" },
      { id: "mais-milionaria", display: "+Milionária" },
    ],
  },
  refinements: [],
};

export const concourseCondition: Condition = {
  id: 'concourses',
  component: 'TextCondition',
  display: 'ID do concurso',
  meta: {
    type: 'text',
    clauses: [
      {
        id: 'in',
        display: 'Está entre',
        component: 'RefineTextInput',
        meta: {},
      },
    ],
  },
  refinements: [],
};

export const concourseSkuCondition: Condition = {
  id: 'concourses_sku',
  component: 'TextCondition',
  display: 'Núm. do concurso',
  meta: {
    type: 'text',
    clauses: [
      {
        id: 'in',
        display: 'Está entre',
        component: 'RefineTextInput',
        meta: {},
      },
    ],
  },
  refinements: [],
};

export const jackpotRefCondition: Condition = {
  id: 'jackpots_sku',
  component: 'TextCondition',
  display: 'Ref. do bolão',
  meta: {
    type: 'text',
    clauses: [
      {
        id: 'in',
        display: 'Está entre',
        component: 'RefineTextInput',
        meta: {},
      },
    ],
  },
  refinements: [],
};

export const jackpotCondition: Condition = {
  id: 'jackpots',
  component: 'TextCondition',
  display: 'ID do bolão',
  meta: {
    type: 'text',
    clauses: [
      {
        id: 'in',
        display: 'Está entre',
        component: 'RefineTextInput',
        meta: {},
      },
    ],
  },
  refinements: [],
};

export const userEmailCondition: Condition = {
  id: 'email',
  component: 'TextCondition',
  display: 'Email do usuário',
  meta: {
    type: 'text',
    clauses: [
      {
        id: 'in',
        display: 'Está entre',
        component: 'RefineTextInput',
        meta: {},
      },
    ],
  },
  refinements: [],
};

export const itemsQuantityCondition: Condition = {
  id: 'items_qty',
  component: 'NumericCondition',
  display: 'Quantidade de itens',
  meta: {
    type: 'number',
    clauses: [
      {
        id: 'eq',
        display: 'É igual a',
        component: 'RefineNumberInput',
        meta: {},
      },
      // {
      //   id: 'gt',
      //   display: 'É maior que',
      //   component: 'RefineNumberInput',
      //   meta: {},
      // },
      {
        id: 'gte',
        display: 'É maior ou igual a',
        component: 'RefineNumberInput',
        meta: {},
      },
      // {
      //   id: 'lt',
      //   display: 'É menor que',
      //   component: 'RefineNumberInput',
      //   meta: {},
      // },
      {
        id: 'lte',
        display: 'É menor ou igual a',
        component: 'RefineNumberInput',
        meta: {},
      },
      // {
      //   id: 'btwn',
      //   display: 'Está entre',
      //   component: 'RefineDoubleNumberInput',
      //   meta: {},
      // },
      // {
      //   id: 'nbtwn',
      //   display: 'Não está entre',
      //   component: 'RefineDoubleNumberInput',
      //   meta: {},
      // },
    ],
  },
  refinements: [],
};

export const minCartAmount: Condition = {
  id: 'minimun_value',
  component: 'NumericCondition',
  display: 'Gasto mínimo',
  meta: {
    type: 'number',
    clauses: [
      {
        id: 'gte',
        display: 'É maior ou igual a',
        component: 'RefineNumberInput',
        meta: {},
      },
    ],
  },
  refinements: [],
};

export const maxCartAmount: Condition = {
  id: 'maximum_value',
  component: 'NumericCondition',
  display: 'Gasto máximo',
  meta: {
    type: 'number',
    clauses: [
      {
        id: 'lte',
        display: 'É menor ou igual a',
        component: 'RefineNumberInput',
        meta: {},
      },
    ],
  },
  refinements: [],
};

export const jackpotValueCondition: Condition = {
  id: 'jackpot_value',
  component: 'NumericCondition',
  display: 'Valor da cota',
  meta: {
    type: 'number',
    clauses: [
      {
        id: 'eq',
        display: 'É igual a',
        component: 'RefineNumberInput',
        meta: {},
      },
      // {
      //   id: 'gt',
      //   display: 'É maior que',
      //   component: 'RefineNumberInput',
      //   meta: {},
      // },
      {
        id: 'gte',
        display: 'É maior ou igual a',
        component: 'RefineNumberInput',
        meta: {},
      },
      // {
      //   id: 'lt',
      //   display: 'É menor que',
      //   component: 'RefineNumberInput',
      //   meta: {},
      // },
      {
        id: 'lte',
        display: 'É menor ou igual a',
        component: 'RefineNumberInput',
        meta: {},
      },
      {
        id: 'btwn',
        display: 'Está entre',
        component: 'RefineDoubleNumberInput',
        meta: {},
      },
      // {
      //   id: 'nbtwn',
      //   display: 'Não está entre',
      //   component: 'RefineDoubleNumberInput',
      //   meta: {},
      // },
    ],
  },
  refinements: [],
};
