import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { history }    from '../../../helpers/history';
import termsService from '../../../services/terms';
import Loading        from '../../../components/Loading';
import Button         from '../../../components/Button';
import Title          from '../../../components/Title';

import ArrowBackIosIcon          from '@material-ui/icons/ArrowBackIos';
import VisibilityIcon from "@material-ui/icons/Visibility";
import ArrowForwardIosIcon       from '@material-ui/icons/ArrowForwardIos';
import Alert                     from '@material-ui/lab/Alert';
import Collapse                  from '@material-ui/core/Collapse';
import CheckIcon from '@material-ui/icons/CheckRounded';
import CancelIcon from '@material-ui/icons/Close';
import TrashIcon from '@material-ui/icons/DeleteOutline';

import './style.scss';
import depositionsService from '../../../services/depositions';
import { Spinner } from 'react-bootstrap';

const Depositions = () => {
    const formatDate = (value) => {
        if (value) {
            return new Date(value).toLocaleDateString('pt-BR', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
            });
        } else {
            return ""
        }
    }
    const [ loadingID,      setLoadingID ] = useState([])
    const [ depositions,    setDepositions ] = useState([]);
    const [ pagination,     setPagination ] = useState({ });
    const [ page,           setPage ] = useState(1);
    const [ loading,        setLoading ] = useState(false);
    const [ status,         setStatus ] = useState('');
    const [ alert,          setAlert ] = useState({ show: false, severity: 'info', message: '' });

    useEffect(() => {
        const asyncFunction = async () => {
            setLoading(true);
            await getAllDepositions(1)
            setLoading(false);
        }
        asyncFunction()
    }, []);

    const getAllDepositions = async () => {
        const data = await depositionsService.getAllDepositionsService({page});
        if(data) {
            if (!data.data) {
                return paginate(page - 1)
            }
            setDepositions(data.data);
            setPagination(data.meta.pagination);
        }
    }

    useEffect(() => {
        const asyncFunction = async () => {
            setLoading(true);
            await getAllDepositions(page)
            setLoading(false);
        }
        asyncFunction()
    }, [page])

    const paginate = async (page) => {
        await setPage(parseInt(page, 10));
    }

    function removeItemFromLoading(id) {
        const index = loadingID.indexOf(2);
        const newLoadingList = loadingID.splice(index, 1);
        setLoadingID(newLoadingList)
    }

    async function handleApproveDeposition(id) {
        setLoadingID([...loadingID, id])
        await depositionsService.updateDeposition(id, "approved")
        removeItemFromLoading(id)
        await getAllDepositions(page)
    }

    async function handleRecuseDeposition(id) {
        setLoadingID([...loadingID, id])
        await depositionsService.updateDeposition(id, "unapproved")
        removeItemFromLoading(id)
        await getAllDepositions(page)
    }

    async function handleDeleteDeposition(id) {
        setLoadingID([...loadingID, id])
        await depositionsService.deleteDeposition(id)
        removeItemFromLoading(id)
        await getAllDepositions(page)
    }

    function getStatusColor(status) {
        switch(status) {
            case "pending":
                return "#9B8C00";
            case "approved":
                return "#19D25C";
            case "unapproved":
                return "#DF2A3C";
            default:
                return "#DF2A3C";
        }
    }

    function formatStatus(status) {
        switch(status) {
            case "pending":
                return "Pendente";
            case "approved":
                return "Aprovado";
            case "unapproved":
                return "Recusado";
            default:
                return "Erro.";
        }
    }

    return (
        <main id="banners">
            <Title label="Depoimentos" />

            <div id="infos">
                <span>Total de <strong>{ pagination?.total }</strong> depoimentos encontrados.</span>
            </div>

            <div id="list">
                <Collapse className="feedback" in={ alert.show }>
                    <Alert severity={ alert.severity }>{ alert.message }</Alert>
                </Collapse><br/>

                <table  className="table table-responsive-sm">
                    <thead>
                        <tr>
                            <th scope="col">Data de criação</th>
                            <th scope="col">Status</th>
                            <th scope="col">Usuário</th>
                            <th scope="col">Depoimento</th>
                            <th scope="col">Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            !loading && depositions && depositions.length ? depositions.map(deposition =>
                                {
                                    return (
                                      <tr key={ deposition.id }>
                                          <td className='col-date'>{ formatDate(deposition.created_at)}</td>
                                          <td className="col-status" style={{fontWeight: 500,color: getStatusColor(deposition.status)}}>{ formatStatus(deposition.status)}</td>
                                          <td className='col-user'>
                                              <div className='user-avatar'>
                                                  <img className='avatar-image' width={50} height={50} src={deposition.user_avatar} title={deposition.user_name} alt={deposition.user_name} />
                                                  <div className='user-info'>
                                                      <span className='username'>{deposition.user_name}</span>
                                                  </div>
                                              </div>
                                          </td>
                                          <td className='col-deposition'>{deposition.statement}</td>
                                          <td className='actions'>
                                              <>
                                                  <button disabled={loadingID.includes(deposition.id) || deposition.status === "approved"} type="button" onClick={() => handleApproveDeposition(deposition.id)} className='action approve'>
                                                      <CheckIcon titleAccess='Aprovar' />
                                                      Aprovar
                                                  </button>
                                                  <button disabled={loadingID.includes(deposition.id) || deposition.status === "unapproved"} type="button" onClick={() => handleRecuseDeposition(deposition.id)} className='action unapproved'>
                                                      <CancelIcon titleAccess='Recusar' />
                                                      Recusar
                                                  </button>
                                                  <button disabled={loadingID.includes(deposition.id)} onClick={() => handleDeleteDeposition(deposition.id)} type="button" className='action exclude'>
                                                      <TrashIcon titleAccess='Excluir' />
                                                      Excluir
                                                  </button>
                                              </>
                                          </td>
                                          {loadingID.includes(deposition.id) ? <Loading size={30} /> : null}
                                      </tr>
                                )})
                            : (<tr key="empty"><td colSpan="8" className="text-center empty"><span>Não há depoimentos para moderar</span></td></tr>)
                        }
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="8">
                                <div>
                                    { pagination?.current_page > 1  ? <ArrowBackIosIcon className="link" onClick={ event => paginate(pagination?.current_page - 1) }/> : null }
                                    { pagination?.count ? <span className="labels">{ pagination?.current_page } de { pagination?.total_pages } { pagination?.total_pages > 1 ? 'Páginas' : 'Página' }</span> : null }
                                    { pagination?.total_pages !== pagination?.current_page  ? <ArrowForwardIosIcon className="link" onClick={ event => paginate(pagination?.current_page + 1) }/> : null }
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </table>

                { loading && <Loading/> }
            </div>
        </main>
    );

}

export default Depositions;
