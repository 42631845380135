import React    from 'react';
import { createRoot } from 'react-dom/client';

import { composeWithDevTools }                           from 'redux-devtools-extension';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { Provider }                                      from 'react-redux';
import thunk                                             from 'redux-thunk';
import { Router } from 'react-router-dom';
import * as reducers                          from './store/reducers';
import App from './App';
import { interceptRequest, interceptResponse } from './services/axiosInstances'
import { history } from './helpers/history'

const store = createStore(combineReducers(reducers), composeWithDevTools(applyMiddleware(thunk)));

interceptRequest();
interceptResponse();

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

const CustomRouter = ({
	basename,
	children,
	historyRouter,
}) => {
	const [state, setState] = React.useState({
		action: historyRouter.action,
		location: historyRouter.location,
	});

	React.useLayoutEffect(() => historyRouter.listen(setState), [historyRouter]);

	return (
		<Router
			basename={basename}
			children={children}
			location={state.location}
			navigationType={state.action}
			navigator={historyRouter}
		/>
	);
};

root.render(
	<Provider store={ store }>
		<CustomRouter historyRouter={history}>
			<App />
		</CustomRouter>
	</Provider>
);
