import api from './axiosInstances';

const terms = {
    async getTerms(params) {
        try {
            let _url  = '/admin/general/terms?';
                _url += (params.page) ? 'page='+ params.page : '' ;
            const { data } = await api.get(_url);
            return data;
        }
        catch(error) {
            return false;
        }
    },
    async getTermByID(params) {
        try {
            let _url  = `/admin/general/terms/${params?.id}`;
            const { data } = await api.get(_url);
            return data;
        }
        catch(error) {
            return false;
        }
    },
    async addTerm(params) {
        try {
            await api.post('/admin/general/terms', params);
            return true ;
        }
        catch(error) {
            console.log(error);
        }
    }
}
export default terms;
