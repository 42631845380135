import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import moment from "moment";

import { history } from "../../../helpers/history";
import currencyFormat from "../../../helpers/currencyFormat";
import Button from "../../../components/Button";
import Loading from "../../../components/Loading";
import concoursesService from "../../../services/concourse";
import Title from "../../../components/Title";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import LocalAtmIcon from "@material-ui/icons/LocalAtm";

import "./style.scss";
import { ArrowBackIos, ArrowForwardIos, Block, Language, OpenInNew, Telegram, Web } from "@material-ui/icons";
import getLotteryColor from "../../../helpers/getLotteryColor";
import TagItem from "../../../components/TagItem";
import Table from "../../../components/Table";

const Concourses = () => {
    const _status = [
        { option: "awaiting", label: "Aguardando" },
        { option: "draft", label: "Rascunho" },
        { option: "cancelled", label: "Cancelado" },
        { option: "production", label: "Em produção" },
        { option: "finished", label: "Encerrado" },
        { option: "refunded", label: "Reembolsado" },
    ];

    const concourseStatus = [
        {
            "raw": "awaiting",
            "label": "Aguardando",
            color: "#D8C364",
        },
        {
            "raw": "draft",
            "label": "Rascunho",
            color: "#B1B5C4",
        },
        {
            "raw": "cancelled",
            "label": "Cancelado",
            color: "#CB784E",
        },
        {
            "raw": "production",
            "label": "Em produção",
            color: "#209869",
        },
        {
            "raw": "finished",
            "label": "Encerrado",
            color: "#D86470",
        },
        {
            "raw": "refunded",
            "label": "Reembolsado",
            color: "#D86470",
        },
    ];

    const paidStatus = [
        {
            "raw": "paid",
            "label": "Pago",
            color: "#209869",
        },
        {
            "raw": "paying",
            "label": "Pagando",
            color: "#D8C364",
        },
        {
            "raw": "no_payment",
            "label": "Não pago",
            color: "#D86470",
        },
    ]

    const [terms, setTerms] = useState('');
    const [has_paid, setHas_paid] = useState('');
    const [status, setStatus] = useState('');

    const [concourses, setConcourses] = useState([]);
    const [pagination, setPagination] = useState({});
    const [loading, setLoading] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [concourseId, setConcourseId] = useState(0);
    const [alert, setAlert] = useState({
        show: false,
        severity: "info",
        message: "",
    });

    const handleConfirm = () => {
        setConfirm(!confirm);
    };

    const confirmPayPrize = (concourseId) => {
        setConfirm(true);
        setConcourseId(concourseId);
    };

    const doPayPrize = async () => {
        if (!concourseId) return;
        setConfirm(false);
        setLoading(true);
        const response = await concoursesService.doPayment(concourseId);
        if (response) {
            setAlert({
                show: true,
                severity: "success",
                message: "Pagamento dos prêmios agendado com sucesso!",
            });
            setTimeout(() => {
                setAlert({ show: false, severity: "warning", message: "" });
            }, 5000);
        } else {
            setAlert({
                show: true,
                severity: "warning",
                message: "Falha ao agendar os pagamentos dos prêmios.",
            });
            setTimeout(() => {
                setAlert({ show: false, severity: "warning", message: "" });
            }, 5000);
        }
        setLoading(false);
    };

    useEffect(() => {
        getConcourses();
    }, []);

    const getConcourses = async () => {
        setLoading(true);
        const { data } = await concoursesService.getConcourses();

        if (!data) return setLoading(false);

        setConcourses(data.result);
        setPagination(data.pagination);
        setLoading(false);
    };

    const paginate = async (page) => {
        setLoading(true);
        const _input = { page, status, has_paid };
        const { data } = await concoursesService.getConcourses(_input);

        if (!data) return setLoading(false);

        setConcourses(data.result);
        setPagination(data.pagination);
        setLoading(false);
    };

    const filterStatus = async (event) => {
        setTerms('');

        if (event.target.value) {
            setStatus(event.target.value);
        }
        else {
            setStatus('');
            getConcourses();
        }
        setLoading(true);
        const { data } = await concoursesService.getConcourses({ status: event.target.value, has_paid });

        if (!data)
            return setLoading(false);

        setConcourses(data.result);
        setPagination(data.pagination);
        setLoading(false);

    }

    const filterPayment = async (event) => {
        setTerms('');

        if (event.target.value) {
            setHas_paid(event.target.value);
        }
        else {
            setHas_paid('');
        }

        setLoading(true);
        const { data } = await concoursesService.getConcourses({ has_paid: event.target.value, status });

        if (!data)
            return setLoading(false);

        setConcourses(data.result);
        setPagination(data.pagination);
        setLoading(false);
    }

    const handleTerms = (event) => {
        setTerms(event.target.value)
    }

    const search = async (event) => {
        event.preventDefault();

        setLoading(true);
        const { data } = await concoursesService.getConcourses({ search: terms, has_paid: has_paid, status: status });

        if (!data)
            return setLoading(false);

        setConcourses(data.result);
        setPagination(data.pagination);
        setLoading(false);
    }

    const clear = async () => {
        setTerms('');
        getConcourses();
    }

    function getChannelType(target_channel) {
        switch (target_channel) {
            case 'all':
                return <span className="flex items-center gap-1"><Web style={{ color: "#209869" }} /> Todos</span>;
            case 'bot_telegram':
                return <span className="flex items-center gap-1"><Telegram style={{ color: "#209869" }} /> Telegram</span>;
            case 'web_site':
                return <span className="flex items-center gap-1"><Language style={{ color: "#209869" }} /> Site</span>;
            default:
                return <span className="flex items-center gap-1"><Block style={{ color: "#209869" }} /> Sem canal</span>;
        }
    }

    function getPaidStatus(concourse) {
        // if (!concourse.has_paid && !concourse.is_paying) {
        if (!concourse.has_paid && !concourse.is_paying) {
            const item = paidStatus.find((item) => item.raw === "no_payment")
            return (
                <div className="flex items-center w-fit px-3 py-1 text-red-500 rounded-full gap-x-2 bg-red-100/60">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 3L3 9M3 3L9 9" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg>

                    <span className="whitespace-nowrap text-sm font-normal">{item.label}</span>
                </div>
            )
        }

        // if (!concourse.has_paid && concourse.is_paying) {
        if (!concourse.has_paid && concourse.is_paying) {
            const item = paidStatus.find((item) => item.raw === "paying")
            return (
                <div className="flex items-center w-fit px-3 py-1 text-gray-500 rounded-full gap-x-2 bg-gray-100/60">
                    <svg width={8} height={8} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z" />
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>


                    <span className="text-sm text-black font-normal">{item.label}</span>
                </div>
            )
        }
        const item = paidStatus.find((item) => item.raw === "paid")
        return (
            <div className="flex items-center px-3 w-fit py-1 rounded-full gap-x-2 text-emerald-500 bg-emerald-100/60">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 3L4.5 8.5L2 6" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                </svg>

                <span className="text-sm font-normal">{item.label}</span>
            </div>
        )
    }

    return (
        <main id="concursos">
            {loading && <Loading />}
            <Title label="Concursos" />

            <div id="filter">
                <div className="inputs">
                    <select name="status" id="status" onChange={filterStatus} className="form-control">
                        <option value="">Filtre por status</option>
                        <option value="">Todos</option>
                        {_status.map((item, index) => (<option key={index} value={item.option}>{item.label}</option>))}
                    </select>
                    <select name="has_paid" id="has_paid" onChange={filterPayment} className="form-control">
                        <option value="">Filtre por pagamento</option>
                        <option value="">Todos</option>
                        <option value="1">Pago</option>
                        <option value="0">Não pago</option>
                    </select>
                    <form onSubmit={search} className="search">
                        <input type="text" className="form-control" placeholder="Buscar pela referência" required value={terms} onChange={handleTerms} />
                        <button type="submit"><SearchIcon /></button>
                        {terms ? (<button type="submit" className="cancel" onClick={clear}><CloseIcon /></button>) : null}
                    </form>
                </div>
            </div>

            <div id="filter">
                <div className="inputs"></div>
                <Button
                    type="button"
                    color="green"
                    label="Cadastrar"
                    action={(event) =>
                        history.push("/campanhas/concourses/novo")
                    }
                />
            </div>

            <div id="infos">
                <span>
                    Total de <strong>{pagination.total}</strong> concursos encontrados.
                </span>
            </div>

            <Collapse className="feedback" in={alert.show}>
                <Alert severity={alert.severity}>{alert.message}</Alert>
            </Collapse>

            <Table
                rows={concourses}
                renderRow={(data) => [
                    { headerName: "Número", field: "number" },
                    { headerName: "Concurso", hiddenResponsive: true, field: "name" },
                    {
                        headerName: "Tipo", render: () =>
                            <span style={{ background: getLotteryColor(data.type_game.slug) }} className="relative inline-flex items-center px-3 py-1 text-sm text-white rounded-full whitespace-nowrap">
                                {data.type_game.name}
                            </span>
                    },
                    {
                        headerName: "Status", render: () => {
                            const statusItem = concourseStatus.filter((item) => item.raw === data.status)[0]
                            return (
                                <TagItem item={{ color: statusItem?.color, label: statusItem?.label }} />
                            )
                        }
                    },
                    { headerName: "Pagamento", render: () => getPaidStatus(data) },
                    { headerName: "Canal", render: () => getChannelType(data.target_channel) },
                    { headerName: "Prêmio estimado", hiddenResponsive: true, render: () => currencyFormat(data.premium_estimate) },
                    { headerName: "Data do sorteio", render: () => moment(data.date_draw, "DD-MM-YYYY HH:mm:ss").format("DD/MM/YYYY HH:mm") },
                    { headerName: "Data de início", render: () => moment(data.start_date, "DD-MM-YYYY HH:mm:ss").format("DD/MM/YYYY HH:mm") },
                    { headerName: "Data de término", render: () => moment(data.end_date, "DD-MM-YYYY HH:mm:ss").format("DD/MM/YYYY HH:mm") },
                    {
                        headerName: "Ações", render: () => <>
                            <Tooltip title="Editar" aria-label="edit">
                                <EditOutlinedIcon className="flex-shrink-0 link" onClick={() => history.push("/campanhas/concourses/editar/" + data.id)} />
                            </Tooltip>

                            <Tooltip title="Ver bolão" aria-label="show">
                                <NavLink to={`/campanhas/concourses/${data.id}`}>
                                    <OpenInNew className="flex-shrink-0 link" />
                                </NavLink>
                            </Tooltip>

                            {data.can_pay === 1 && data.has_paid === 0 && data.is_paying === 0 ? (
                                <Tooltip title="Pagar prêmio" aria-label="pay">
                                    <LocalAtmIcon className="flex-shrink-0 link" onClick={() => confirmPayPrize(data.id)} />
                                </Tooltip>
                            ) : null}
                        </>
                    },
                ]}
                pagination={pagination}
                paginate={paginate}
                loading={loading}
            />

            <Dialog
                open={confirm}
                onClose={handleConfirm}
                className="confirmation"
            >
                <DialogTitle
                    style={{ cursor: "move" }}
                    id="draggable-dialog-title"
                >
                    Confirmação
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Você deseja realizar os pagamentos para esse concurso?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <span className="link" onClick={handleConfirm}>
                        Cancel
                    </span>
                    <span className="link" onClick={doPayPrize}>
                        Sim!
                    </span>
                </DialogActions>
            </Dialog>
        </main>
    );
};

export default Concourses;
