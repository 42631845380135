import React, { useEffect, useState } from 'react';

import Title from '../../components/Title';
import orderService from '../../services/orders';
import moment from 'moment';
import { FilterAltOff } from '@mui/icons-material'
import Button from '../../components/Button';
import { CheckPicker, DateRangePicker, Drawer, Input, MaskedInput } from 'rsuite'
import { addDays, addMonths, endOfMonth, endOfWeek, startOfMonth, startOfWeek, subDays } from 'date-fns';
import TagItem from '../../components/TagItem';
import Table from '../../components/Table';
import withdrawSevices from '../../services/withdraw'
import DrawerFilters from '../../components/DrawerFilters'
import concoursesService from '../../services/concourse'

const predefinedRanges = [
	{
		label: 'Hoje', value: [new Date(), new Date()], placement: 'left',
	}, {
		label: 'Ontem', value: [addDays(new Date(), -1), addDays(new Date(), -1)], placement: 'left',
	}, {
		label: 'Essa semana', value: [startOfWeek(new Date()), endOfWeek(new Date())], placement: 'left',
	}, {
		label: 'Últimos 7 dias', value: [subDays(new Date(), 6), new Date()], placement: 'left',
	}, {
		label: 'Últimos 30 dias', value: [subDays(new Date(), 29), new Date()], placement: 'left',
	}, {
		label: 'Esse mês', value: [startOfMonth(new Date()), new Date()], placement: 'left',
	}, {
		label: 'Mês passado', value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))], placement: 'left',
	}]

const musStatus = [
	{
		"raw": "scheduled",
		"value": "scheduled",
		"label": "Agendado",
		color: "#1186ea",
	},
	{
		"raw": "pending",
		"value": "pending",
		"label": "Pendente",
		color: "#D8C364",
	},
	{
		"raw": "cancelled",
		"value": "cancelled",
		"label": "Cancelado",
		color: "#CB784E",
	},
	{
		"raw": "deposited",
		"value": "deposited",
		"label": "Depositado",
		color: "#209869",
	},
	{
		"raw": "failed",
		"value": "failed",
		"label": "Falha",
		color: "#D86470",
	},
];

const cpfMask = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]

const tableService = concoursesService.getMusSchedule

export default function Mus(pageProps) {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);

	const [openDrawerFilters, setOpenDrawerFilters] = useState(false)
	const [filters, setFilters] = useState({})

	useEffect(() => {
		getTableData()
	}, [])

	function getFilters() {
		const activeFilters = {}

		Object.entries(filters).filter(([key, value]) => {
			const currentValue = value?.formatted || value.raw
			if (!currentValue || currentValue === '' || currentValue.length === 0) return false
			return true
		}).map(([key, value]) => {
			activeFilters[key] = value?.formatted || value.raw
		})

		return activeFilters
	}

	const getTableData = async (reset = false) => {
		setLoading(true)

		let data = await tableService({
			page: 1,

			...(!reset && getFilters()),
		})

		setData(data?.data)
		setLoading(false)
	}

	const paginate = async (page) => {
		setLoading(true)
		const payload = {
			page,
			...getFilters(),
		}

		await tableService(payload).then(data => {
			if (!data) return setLoading(false)

			setData(data?.data)
		}).finally(() => setLoading(false))
	}

	async function onSubmitFilters(reset = false) {
		if (reset) {
			await setFilters({})
		}

		setOpenDrawerFilters(false)
		await getTableData(reset)
	}

	const filterCount = Object.entries(getFilters()).length

	return (
		<main>
			<Title label="Liberações" />

			<DrawerFilters
				open={openDrawerFilters}
				setOpen={setOpenDrawerFilters}
				onSubmit={() => onSubmitFilters()}
				filters={[
					{
						label: "Email",
						name: "customer_email",
						field: () => (
							<Input
								id="input-email"
								value={filters?.customer_email?.raw}
								onChange={(value) =>
									setFilters((state) => ({
											...state,
											customer_email: {
												raw: value,
											},
										})
									)}
								placeholder="Email do cliente"
								type='email' className="w-full"
							/>
						),
					},
					{
						label: "Data de criação",
						name: "range_date",
						field: () => (
							<DateRangePicker
								placement="auto"
								id="input-period"
								value={filters?.range_date?.raw}
								format="dd/MM/yyyy"
								onChange={(value) => {
									setFilters((state) => {
										return ({
											...state,
											range_date: {
												raw: value,
												formatted: !value ? null : encodeURI([moment(value && value[0]).format('YYYY-MM-DD'), moment(value && value[1]).format('YYYY-MM-DD')]),
											},
										})
									})
								}}
								showOneCalendar
								ranges={predefinedRanges}
								placeholder="Escolha um período"
								className='w-full'
							/>
						),
					},
					{
						label: "Status",
						name: "status",
						field: () => (
							<CheckPicker
								autoComplete="false"
								id="input-status"
								value={filters?.status?.raw}
								onChange={(value) =>
									setFilters((state) => ({
											...state,
											status: {
												raw: value,
											},
										})
									)}
								placeholder="Escolha um status"
								searchable={false}
								sticky
								className={'w-full'}
								data={musStatus}
							/>
						),
					},
					{
						label: "CPF",
						name: "customer_cpf",
						field: () => (
							<MaskedInput
								id="input-cpf"
								value={filters?.customer_cpf?.raw}
								onChange={(value) =>
									setFilters((state) => ({
											...state,
											customer_cpf: {
												raw: value,
												formatted: value?.replace(/\D/g, ''),
											},
										})
									)}
								placeholder="CPF do cliente"
								mask={cpfMask} className="w-full"
							/>
						),
					},
				]}
			/>

			<div id="infos" className="flex justify-end w-full mb-4">
				<span>Total de <strong>{data?.pagination?.total}</strong> registros encontrados.</span>
			</div>

			<div className="flex items-center justify-end w-full gap-2 mb-4">
				<div className="flex items-center gap-2">
					{filterCount > 0 && (<FilterAltOff onClick={() => onSubmitFilters(true)} style={{ cursor: 'pointer', fill: '#209869' }}/>)}
					<Button
						small
						color="green"
						action={setOpenDrawerFilters}
						label={`Aplicar filtros ${filterCount > 0 ? `(${filterCount})` : ''}`}
						disabled={loading}
					/>
				</div>
			</div>

			<Table
				rows={data?.result}
				renderRow={(data) => ([
					{ headerName: 'ID', render: () => data?.id },
					{ headerName: 'Qtd. MUS', render: () => data?.points },
					{
						headerName: 'Status', render: () => {
							const statusItem = musStatus.filter((item) => item.raw === data.status)[0]
							return (<TagItem item={{ color: statusItem?.color, label: statusItem?.label }} />
							)
						}
					},
					{ headerName: "Usuário", render: () => data?.user?.first_name + ' ' + data?.user?.last_name },
					{ headerName: "Data de liberação", render: () => moment(data?.deposit_date).format("DD/MM/YYYY HH:mm") },
					{ headerName: "Data de criação", render: () => moment(data?.earned_date).format("DD/MM/YYYY HH:mm") },
				])}
				pagination={data?.pagination}
				paginate={paginate}
				loading={loading}
			/>
		</main>
	)
}
