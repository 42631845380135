export default function parseApiParams(parsedParams, params) {
	const translateList = (key) => {
		const filtered = parsedParams.find((item) => item.raw === key)

		return (filtered?.correctSpelling || filtered?.raw) || null
	}

	return params && Object.entries(params)?.reduce(
		(acc, [key, value]) => {
			if (!value || value.length === 0) return null
			const correctKey = translateList(key)

			if (correctKey) {
				return {
					...acc,
					[correctKey]: value
				}
			}

			return {...acc}
		}
	, {})
};
