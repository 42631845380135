export default function TagItem({ item }) {
  if (!item) return null

	let LeftItem = () => null

	if (item?.color) {
		LeftItem = () => <span style={{ background: item.color, width: 10, height: 10 }} className="rounded-full"/>
	}

	if (item?.icon) {
		LeftItem = () => item.icon
	}


	return (
		<div className="relative inline-flex items-center gap-2 px-3 py-1 text-sm bg-white border border-gray-300 rounded-full whitespace-nowrap">
      <LeftItem />
      <span>{item.label || '-'}</span>
    </div>
  )
}
