import api from './axiosInstances'
import { jackpotFileRequest, jackpotRequest, publicRequest } from './axiosInstances'
import parseApiParams from '../helpers/parseApiParams'

const jackpots = {
    async getJackpotsType() {
        try {
            const { data } = await publicRequest.get('/uploads');
            return data.data;
        }
        catch (error) {
            console.log(error);
            return false;
        }
    },
    async getJackpot(concourse, id) {
        try {
            const { data } = await api.get(`/admin/concourses/${concourse}/jackpots/${id}`);
            return data;
        }
        catch (error) {
            return false;
        }
    },
    async getProcessingJackpots(page, concourseID) {
        try {
            // search com status P para pegar os processandos/pendentes (gamba)
            const { data } = await jackpotFileRequest.get(`/uploads?search=status:p;model.id:${concourseID}&page=${page}&sortedBy=desc&orderBy=created_at&limit=12&searchFields=status:like&searchJoin=and`);
            return data;
        }
        catch (error) {
            return false;
        }
    },
    async getJackpotsFile(page = 1, concourseID) {
        try {
            const { data } = await jackpotFileRequest.get(`/jackpot-files?search=concourse_id:${concourseID}&page=${page}&include=upload&limit=20&sortedBy=desc&orderBy=created_at`);
            return data;
        }
        catch (error) {
            return false;
        }
    },
    async updateUpload(payload) {
        try {
            const { data } = await jackpotFileRequest.patch(`/uploads?sortedBy=desc&orderBy=created_at`, payload);
            return data;
        }
        catch (error) {
            return false;
        }
    },
    async createJackpot(id) {
        try {
            const { data } = await jackpotFileRequest.post('/jackpot-files', { upload_ids: id });
            return data;
        }
        catch (error) {
            return false;
        }
    },
    async downloadUpload(id) {
        try {
            const { data } = await jackpotFileRequest.get(`/uploads/${id}/download`);
            return data;
        }
        catch (error) {
            return false;
        }
    },
    async deleteUploads(ids) {
        try {
            const { data } = await jackpotFileRequest.delete(`/uploads`, {
                data: {
                    ids
                }
            });
            return data;
        }
        catch (error) {
            return false;
        }
    },
    async getJackpots(id, params = null) {
        try {
            let _url = `/admin/concourses/${id}/jackpots?`;

            _url += params && params.date ? '&draw_date=' + params.date : '';
            _url += params && params.range ? '&min_price=' + params.range[0] + '&max_price=' + params.range[1] : '';
            _url += params && params.type ? '&type_game=' + params.type : '';
            _url += params && params.page ? '&page=' + params.page : '';
            _url += params && params.status ? '&status=' + params.status : '';
            _url += '&sort-by=date_desc';
            _url += params && params.search ? '&q=' + params.search : '';

            const { data } = await publicRequest.get(_url);
            return data;
        }
        catch (error) {
            console.log(error);
            return false;
        }
    },

    async getJackpotsByConcourse(id, params = null) {
				const paramsList = [
					{ raw: "date", correctSpelling: "draw_date" },
					{ raw: "range", correctSpelling: "min_price" },
					{ raw: "type", correctSpelling: "type_game" },
					{ raw: "page", correctSpelling: "page" },
					{ raw: "status", correctSpelling: "status" },
					{ raw: "sort", correctSpelling: "sort-by" },
					{ raw: "search", correctSpelling: "q" },
					{ raw: "min_price", correctSpelling: "min_price" },
					{ raw: "max_price", correctSpelling: "max_price" },
				]

        try {
          const { data } = await api.get(`/admin/concourses/${id}/jackpots`, {
						params: parseApiParams(paramsList, params),
          });

          return data;
        } catch (error) {
          console.log(error);
          return false;
        }
    },
    async getLogsByConcourse(id, params = null) {
        try {

            let _url = `/admin/concourses/${id}/logs?`;

            const { data } = await api.get(_url);
            return data;
        }
        catch (error) {
            console.log(error);
            return false;
        }
    },

    async getLogsByConcourseId(id, params = null) {
        try {
            let _url = `/jackpot-receipts`;

            const { data } = await jackpotFileRequest.get(_url, {
                params: {
                    search: `concourse_id:${id}`,
                    sortedBy: "desc",
                    orderBy: "id",
                    // include: "jackpot,concourse,upload",
                    ...params
                }
            });
            return data;
        }
        catch (error) {
            console.log(error);
            return false;
        }
    },

	async cancelJackpot(concourseID, jackpotID) {
		try {
			let _url = `/admin/concourses/${concourseID}/jackpots/cancel`;

			const { data } = await publicRequest.post(_url, null, {
				params: {
					id: jackpotID
				}
			});
			return data;
		}
		catch (error) {
			console.log(error);
			return false;
		}
	},

    async getGame(params) {
        try {
            const { data } = await publicRequest.get('/jackpots/' + params.jackpot + '/games', { params: { page: params.page || 1 } });
            return data;
        }
        catch (error) {
            return false;
        }
    },
    async getMessageByLog(concourse, log) {
        try {

            let _url = `/admin/concourses/${concourse}/logs/${log}`;

            const { data } = await api.get(_url);
            return data;
        }
        catch (error) {
            console.log(error);
            return false;
        }
    },
    async uploadGames(formData) {
        try {
            const { data } = await jackpotRequest.post('/jackpot/import', formData);
            return data.success;
        }
        catch (error) {
            return false;
        }
    },

    async uploadTikets(concourseID, formData) {
        try {
            const { data } = await api.post(`/admin/concourses/${concourseID}/receipt/import`, formData);
            return data;
        }
        catch (error) {
            return false;
        }
    },

    async deleteTikets(receiptId) {
        try {
            const { data } = await api.delete('/admin/receipt/delete/' + receiptId);
            return data
        }
        catch (error) {
            return false
        }
    },

    async updateJackpot(formData, concourse, id) {
        try {
            const { data } = await api.post(`/admin/concourses/${concourse}/jackpots/${id}`, formData);

            return data;
        }
        catch (error) {
            return false;
        }
    },
}
export default jackpots;
