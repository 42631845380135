import { Route, Navigate, Outlet } from 'react-router-dom'

export function canWhiteList(whiteListGroup) {
	const storage = JSON.parse(localStorage.getItem('unindo_sonhos_admin_data'));

	const whiteListUsers = {
		usersPage: [
			"chellepalmeira@hotmail.com",
			"nathaliamouradesouza@gmail.com",
			"guimaraesslara@gmail.com",
			"hillaryximenes@gmail.com",
			"vitoriabrunkhorst@hotmail.com",

			"jefferson@plastikastudio.com.br",
			"guilherme@plastikastudio.com.br",
		],
		bannersPage: [
			"vitoriabrunkhorst@hotmail.com",
			"guimaraesslara@gmail.com",

			"guilherme@plastikastudio.com.br",
			"jefferson@plastikastudio.com.br",
		],
		depositionsPage: [
			"nathaliamouradesouza@gmail.com",

			"guilherme@plastikastudio.com.br",
			"jefferson@plastikastudio.com.br",
		],
		termsPage: [
	    'guimaraesslara@gmail.com',
	    'nathaliamouradesouza@gmail.com',

	    'guilherme@plastikastudio.com.br',
	    'jefferson@plastikastudio.com.br'
		],
	}

	return storage && whiteListUsers[whiteListGroup]?.includes(storage.user.email)
}

const redirect = (props) => {
	const storage = JSON.parse(localStorage.getItem('unindo_sonhos_admin_data'));

	if(!storage && props.userData)
		props.reset();

	return (<Navigate state={{ from: props.location }} to="/" />)
}

const PrivateRoute = ({ component: Component, ...rest }) => {
		const storage = JSON.parse(localStorage.getItem('unindo_sonhos_admin_data'));

		if (rest.whiteListGroup && !canWhiteList(rest.whiteListGroup)) {
			return <Navigate to={{ pathname: '/404' }} />
		}

		return (storage && storage.token)
			? <Outlet />
			: redirect(rest);
}

export default PrivateRoute;
