import Title from '../../components/Title'
import React, { useEffect, useState } from 'react'
import Button from '../../components/Button'
import Alert from '@material-ui/lab/Alert'
import Collapse from '@material-ui/core/Collapse'
import rules from '../../services/rules'
import { formatCurrency } from '../../helpers/formatCurrency'
import affiliateService from '../../services/affiliate'
import { twMerge } from 'tailwind-merge'
import { Autocomplete, AutocompleteItem, Select, SelectItem, Skeleton } from '@nextui-org/react'
import { Bar } from 'react-chartjs-2'
import { BarElement, LineElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, LineController, PointElement, Tooltip } from 'chart.js'
import userService from '../../services/users'
import { useInfiniteScroll } from '@nextui-org/use-infinite-scroll'
import { useAsyncList } from '@react-stately/data'

ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, PointElement, Tooltip, LineController, Legend)

function CardInfo ({ title, loading = true, value, content, className }) {
  return (<div className={twMerge('bg-neutral-7 w-full p-4 rounded-lg', className)}>
    <span className="text-2xs text-neutral-4 font-medium">{title}</span>
    <Skeleton
      isLoaded={!loading}
      className={twMerge('rounded', loading && 'mt-2')}
    >
      {content || <p className="mt-1 text-2xl font-bold">{value}</p>}
    </Skeleton>
  </div>)
}

function BarChart ({ graphData }) {
  const data = {
    labels: graphData?.paid?.labels,
    datasets: [
      {
        borderJoinStyle: "bevel",
        label: 'Pagos',
        data: graphData?.paid?.data,
        borderColor: "#2C745B",
        backgroundColor: "#209869",
        tension: 0.3,
      },
      {
        borderJoinStyle: "bevel",
        label: 'Cancelados',
        data: graphData?.canceled?.data,
        borderColor: "#FF6384",
        backgroundColor: "#FFB1C1",
        tension: 0.3,
      },
    ]
  }

  const footer = (tooltipItems) => {
    let sum = 0

    tooltipItems.forEach((tooltipItem) => {
      sum += parseInt(tooltipItem.raw?.order_total, 10)
    })

    return `Vendas: ${sum}`
  }

  return (<Bar
      data={data}
      options={{
        responsive: true,
        parsing: {
          xAxisKey: 'label',
          yAxisKey: 'amount_commissions'
        },
        plugins: {
          tooltip: {
            callbacks: {
              footer,
              label: (tooltipItem) => `${tooltipItem?.dataset?.label}: ${formatCurrency(tooltipItem?.raw?.amount_commissions)}`,
            }
          }
        },
        interaction: {
          mode: 'index',
          intersect: false,
        },
        stacked: false,
        scales: {
          y: {
            type: 'linear',
            display: true,
            position: 'left',
            ticks: {
              callback: (value) => formatCurrency(value),
              stepSize: 1,
            },
          },
        }
      }}
    />)
}

const AffiliatesDetails = () => {
  const [data, setData] = useState({})

  const [isOpen, setIsOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState("")
  const [loading, setLoading] = useState(false)
  const [periodFilter, setPeriodFilter] = useState(new Set(['1_week']))
  const [alert, setAlert] = useState({ show: false, severity: 'info', message: '' })

  useEffect(() => {
    getData()
  }, [selectedUser, periodFilter])

  const getData = async () => {
    const period = periodFilter?.values()
    const user = list?.items?.find((i) => i.id === selectedUser)

    affiliateService.getMetrics({
      params: {
        user_id: user?.id,
        period: period.next().value,
      }
    }).then(setData)
  }

  const list = useAsyncList({
    async load({signal, filterText}) {
      const first_name = filterText?.split(' ')[0]
      const last_name_array = filterText?.split(' ')
      last_name_array?.shift()

      const res = await userService.getUsers(
        {
          first_name: { raw: first_name },
          last_name: { raw: last_name_array?.join(' ') },
          'roles.name': { raw: "Afiliado" }
        }, 
        { include: "roles" },
        signal
      )

      return {
        items: res?.data || [],
      };
    },
  })

  return (<main id="rules-config">
      <Title label="Afiliados"/>

      <Collapse className="feedback" in={alert.show}>
        <div className="mb-4">
          <Alert severity={alert.severity}>{alert.message}</Alert>
        </div>
      </Collapse>

      <div className="flex items-start gap-2 pt-4">
        <Autocomplete
          items={list.items}
          inputValue={list.filterText}
          onInputChange={(value) => {
            list.setFilterText(value)
            setSelectedUser((prevState) => value === "" ? null : prevState)
          }}
          isLoading={list.isLoading}
          selectedKey={selectedUser}
          onSelectionChange={key => {
            const item = list.items.find((i) => i.id === key)

            setSelectedUser(key)
            list.setFilterText(item?.fullname)
          }}
          onOpenChange={(isOpen) => setIsOpen(isOpen)}
          className="max-w-xs"
          label="Selecione um usuário"
          placeholder="Nome do usuário"
          variant="bordered"
          labelPlacement="outside"
          color="primary"
          listboxProps={{
            classNames: {
              emptyContent: "text-center",
            },
          }}
        >
          {(item) => (<AutocompleteItem key={item.id} className="capitalize">
              {item.fullname}
            </AutocompleteItem>)}
        </Autocomplete>
        <Select
          items={[
            { key: '1_week', label: 'Últimos 7 dias' }, { key: '1_month', label: 'Últimos 30 dias' }, { key: '2_months', label: 'Últimos 60 dias' }, { key: '3_months', label: 'Últimos 90 dias' }]}
          disallowEmptySelection
          selectedKeys={periodFilter}
          onSelectionChange={setPeriodFilter}
          label="Período"
          labelPlacement="outside"
          placeholder="Selecione um período"
          className="max-w-60 mt-8 mb-2 self-end"
          classNames={{
            trigger: 'bg-white',
          }}
          color="primary"
          listboxProps={{
            color: 'primary',
          }}
          variant="bordered"
        >
          {(item) => <SelectItem>{item.label}</SelectItem>}
        </Select>
      </div>
      
      <div className="w-full lg:flex-row lg:flex gap-2">
        <CardInfo
          loading={loading}
          title="Vendas"
          className="w-1/2"
          value={formatCurrency(data?.total_sales_paid)}
        />
        <CardInfo
          loading={loading}
          className={"w-1/2"}
          title="Comissão"
          value={formatCurrency(data?.total_commissions_paid)}
        />
        <CardInfo
          loading={loading}
          className={"w-1/2"}
          title="Comissão a liberar"
          value={formatCurrency(data?.total_commissions_pending)}
        />
        <CardInfo
          loading={loading}
          className={"w-1/2"}
          title="Ticket médio"
          value={formatCurrency(data?.average_ticket)}
        />
        <CardInfo
          loading={loading}
          className={"w-1/2"}
          title="Taxa de conversão"
          value={`${parseInt(data?.conversion_rate).toFixed(2).toString().replaceAll('.', ',')}%`}
        />
      </div>

      <div className="flex my-2 w-1/2 gap-2">
        <CardInfo
          className="self-center"
          loading={false}
          title="Histórico de comissões"
          content={(<BarChart graphData={data}/>)}
        />
      </div>
    </main>)
}

export default AffiliatesDetails
