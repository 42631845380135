import api from './axiosInstances';

const couponService = {
    async createCoupon(payload) {
        try {
            const response = await api.post('/admin/coupons', payload);
            return response;
        }
        catch(error) {
          return error
            console.log(error)
        }
    },
    async toggleStatusCoupon(payload) {
        try {
            const response = await api.put(`/admin/coupons/${payload.id}/toogle`);
            return response;
        }
        catch(error) {
          return error
            console.log(error)
        }
    },
    async getCoupons(payload) {
      try {
        const { data } = await api.get('/admin/coupons', {params: payload});
        return data?.data;
      }
      catch(error) {
        return error
        console.log(error)
      }
    },
    async getCoupon(payload) {
      try {
        const { data } = await api.get(`/admin/coupons/${payload.id}`);
        return data
      }
      catch(error) {
        return error
        console.log(error)
      }
    }
}
export default couponService;
