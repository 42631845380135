import React, { useState, useEffect } from 'react';
import { useParams, NavLink } from "react-router-dom";
import moment from 'moment';

import { history }     from '../../../helpers/history';
import currencyFormat  from '../../../helpers/currencyFormat';
import Button          from '../../../components/Button';
import Loading         from '../../../components/Loading';
import jackpotsSevices from '../../../services/jackpots';
import Title           from '../../../components/Title';

import ArrowBackIosIcon    from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import VisibilityIcon      from '@material-ui/icons/Visibility';
import ReceiptIcon         from '@material-ui/icons/Receipt';
import SearchIcon          from '@material-ui/icons/Search';
import CloseIcon           from '@material-ui/icons/Close';
import Tooltip             from '@material-ui/core/Tooltip';

import './style.scss';

const Games = () => {
    const {id} = useParams()
    const _status = [
        { "option": "awaiting",   "label": "Aguardando",  },
        { "option": "draft",      "label": "Rascunho",    },
        { "option": "blocked",    "label": "Bloqueado",   },
        { "option": "production", "label": "Em produção", },
        { "option": "finished",   "label": "Encerrado"    }
    ];

    const [ jackpots,     setJackpots ] = useState([]);
    const [ pagination, setPagination ] = useState({});
    const [ loading,       setLoading ] = useState(false);
    const [ status,         setStatus ] = useState('');
    const [ terms,           setTerms ] = useState('');
    
    useEffect(()=> {
        getJackpots();
    }, [ ]);

    const getJackpots = async () => {
        setLoading(true);
        const { data } = await jackpotsSevices.getJackpotsByConcourse(id,{ sort: 'date_desc' });
        
        if(!data)
            return setLoading(false);
            
        setJackpots(data.result);
        setPagination(data.pagination);
        setLoading(false);
    }

    const paginate = async (page) => {
        setLoading(true);
        const _input = { page, status, sort: 'date_desc' };
        
        if(terms)
            _input['search'] = terms;

        const { data } = await jackpotsSevices.getJackpotsByConcourse(id,_input);

        if(!data)
            return setLoading(false);

        setJackpots(data.result);
        setPagination(data.pagination);
        setLoading(false);
    }

    const filter = async (event) => {
        setTerms('');

        if(event.target.value) {
            setLoading(true);
            setStatus(event.target.value);
            const { data } = await jackpotsSevices.getJackpots({ status: event.target.value, sort: 'date_desc' });

            if(!data)
                return setLoading(false);

            setJackpots(data.result);
            setPagination(data.pagination);
            setLoading(false);
        }
        else {
            setStatus('');
            getJackpots();
        }
    }

    const handleTerms = (event) => {
        setTerms(event.target.value)
    }

    const search = async (event) => {
        event.preventDefault();
        
        setLoading(true);
        const { data } = await jackpotsSevices.getJackpots({ sort: 'date_desc', search: terms });
        
        if(!data)
            return setLoading(false);
            
        setJackpots(data.result);
        setPagination(data.pagination);
        setLoading(false);
    }

    const clear = async () => {
        setTerms('');
        getJackpots();
    }

    return (
        <main id="games">
            
            <Title label="Bolões" />
            
            <div id="filter">
                <div className="inputs">
                    <select name="status" id="status" onChange={ filter } className="form-control">
                        <option value="">Filtre por status</option>
                        <option value="">Todos</option>
                        { _status.map((item, index) => (<option key={ index } value={ item.option }>{ item.label }</option>)) }
                    </select>
                    <form onSubmit={ search } className="search">
                        <input type="text" className="form-control" placeholder="Buscar pela referência" required value={ terms } onChange={ handleTerms }/>
                        <button type="submit"><SearchIcon/></button>
                        { terms ? (<button type="submit" className="cancel" onClick={ clear }><CloseIcon /></button>) : null }
                    </form>
                </div>
                <Button type="button" color="green" label="Cadastrar" action={ event => history.push('/campanhas/boloes/novo') } />
            </div>

            <div id="infos">
                <span>Total de <strong>{ pagination.total }</strong> bolões encontrados.</span>
            </div>
            
            <div id="list">
                <table  className="table table-responsive-sm">
                    <thead>
                        <tr>
                            <th scope="col">Ref</th>
                            <th scope="col">Título</th>
                            <th scope="col">Concurso</th>
                            <th scope="col">Valor</th>
                            <th scope="col">Status</th>
                            <th scope="col">Cadastrado em</th>
                            <th scope="col">Arquivo</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            !loading && jackpots.length
                                ? jackpots.map(jackpot =>
                                    (<tr key={ jackpot.id }>
                                        <td className="reference">{ jackpot.ref }</td>
                                        <td>{ jackpot.name }</td>
                                        <td>{ jackpot.sku }</td>
                                        <td>{ currencyFormat(jackpot.shares_regular_price) }</td>
                                        <td>{ jackpot.status && _status.filter((item) => item.option === jackpot.status)[0].label }</td>
                                        <td>{ moment(jackpot.created_at, "DD-MM-YYYY HH:mm:ss").format("DD/MM/YYYY HH:mm:ss") }</td>
                                        <td>{ jackpot.source_file || '' }</td>
                                        <th scope="col" className="text-center">
                                            { 
                                                jackpot.receipt.length 
                                                    ? <Tooltip title="Ver PDF" aria-label="open"><ReceiptIcon className="link" onClick={ event => window.open(jackpot.receipt[0].s3_url) }/></Tooltip>
                                                    : null 
                                            }
                                            <Tooltip title="Ver bolão" aria-label="show"><NavLink to={ `/campanhas/boloes/${jackpot.id}` }><VisibilityIcon/></NavLink></Tooltip>
                                        </th>
                                    </tr>))
                                : (<tr key="empty"><td colSpan="8" className="text-center empty"><span>Não há bolões cadastrados</span></td></tr>)
                        }
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="8">
                                <div>
                                    { pagination.prev  ? <ArrowBackIosIcon className="link" onClick={ event => paginate(pagination.prev) }/> : null }
                                    { pagination.count ? <span className="labels">{ pagination.current } de { pagination.total_pages } { pagination.total_pages > 1 ? 'Páginas' : 'Página' }</span> : null }
                                    { pagination.next  ? <ArrowForwardIosIcon className="link" onClick={ event => paginate(pagination.next) }/> : null }
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </table>

                { loading && <Loading/> }
            </div>
            
        </main>
    )
}

export default Games;