export default function getLotteryColor(lottery) {
  switch (lottery) {
    case 'mega-sena':
      return '#209869';
    case 'dupla-sena':
      return '#490B1B';
    case 'lotomania':
      return '#FF8C2A';
    case 'quina':
      return '#6B5BE2';
    case 'lotofacil':
      return '#930089';
    case 'timemania':
      return '#9CD33B';
    case 'dia-de-sorte':
      return '#CFAA29';
    case 'super-sete':
      return '#A8CF45';
    case 'mais-milionaria':
      return '#2E3078';
    default:
  }
}
