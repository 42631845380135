import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined'
import Alert from '@material-ui/lab/Alert'
import Collapse from '@material-ui/core/Collapse'

import * as accountActions from '../../store/account/actions'
import * as appActions from '../../store/app/actions'
import AuthService from '../../services/auth'

import { history } from '../../helpers/history'
import Button from '../../components/Button'
import bigLogo from '../../assets/images/logotipo-unindo-sonhos.png'

import './style.scss'

function Login (props) {
	const [user_id, setUserID] = useState('')
	const [password, setPassword] = useState('')
	const [showPass, setShowPass] = useState(false)
	const [feedback, setFeedback] = useState({})
	const [loading, setLoading] = useState(false)
	const [authCode, setAuthCode] = useState(null)

	useEffect(() => {
		if (props.appData.sessionExpired) {
			setFeedback({ show: true, severity: 'error', message: 'Sua sessão expirou, faça seu login novamente.' })
			setTimeout(() => {
				props.appActions.alertSessionExpired()
				setFeedback({ show: false })
			}, 7000)
		}
	}, [])

	async function handleChange (event) {
		const authCodeTruncated = event.target.value.slice(0, 6)

		if (event.target.id === 'user_id') return setUserID(event.target.value)
		if (event.target.id === 'password') return setPassword(event.target.value)
		if (event.target.id === 'authCode') return setAuthCode(authCodeTruncated)
	}

	async function doLogin (event) {
		event.preventDefault()

		setLoading(true)
		await AuthService.authenticate(user_id, password, authCode).then(async (response) => {
			if (response?.message === 'Invalid Login code.') {
				setLoading(false)
				setFeedback({ show: true, severity: 'warning', message: 'Código inválido. Tente novamente.' })
				setTimeout(() => setFeedback({ show: false, severity: 'warning' }), 5000)

				return
			}

			if (!response?.access_token) {
				setLoading(false)
				setFeedback({ show: true, severity: 'warning', message: 'Usuário ou senha inválidos.' })
				setTimeout(() => setFeedback({ show: false, severity: 'warning' }), 5000)

				return
			}

			setFeedback({ show: true, severity: 'success', message: 'Login efetuado com sucesso!' })
			setTimeout(() => setFeedback({ show: false, severity: 'success' }), 5000)
			localStorage.setItem('unindo_sonhos_admin_data', JSON.stringify({ 'token': response.access_token }))

			await props.accountActions.me()
			history.push('/dashboard')
		}).catch((error) => {
			setFeedback({ show: true, severity: 'error', message: 'Ocorreu um erro ao tentar fazer login. Tente novamente mais tarde.' })
			setLoading(false)
			setTimeout(() => setFeedback({ show: false, severity: 'error' }), 5000)
		})

	}

	return (<section id="login" className="animated delay-1s fadeIn">
			<div className="wrapper">
				<figure>
					<img src={bigLogo} alt="Logotipo da Unindo Sonhos"/>
				</figure>
				<form onSubmit={doLogin}>
					<Collapse className="feedback" in={feedback.show}>
						<Alert severity={feedback.severity}>{feedback.message}</Alert>
					</Collapse>
					<div className="form-group">
						<label htmlFor="user_id">E-mail</label>
						<input required type="text" id="user_id" value={user_id} className="form-control" autoComplete="username" onChange={handleChange}/>
					</div>
					<div className="form-group">
						<label htmlFor="password">Senha</label>
						<span className="eye">
	            <input required type={showPass ? 'text' : 'password'} id="password" value={password} className="form-control" autoComplete="current-password" onChange={handleChange}/>
							{showPass ? <VisibilityOffOutlinedIcon onClick={event => setShowPass(!showPass)}/> : <VisibilityOutlinedIcon onClick={event => setShowPass(!showPass)}/>}
	          </span>
					</div>
					<div className="form-group">
						<label htmlFor="user_id">Código do autenticador</label>
						<input required type="number" id="authCode" value={authCode} className="form-control" onChange={handleChange}/>
					</div>
					<div className="form-group">
						<Button type="submit" width="100%" disabled={loading || authCode?.length !== 6} color="green" label="Entrar" loading={loading}/>
					</div>
				</form>
			</div>
		</section>)
}

const mapStateToProps = state => {
	return {
		userData: state.accountReducer.userData, appData: state.appReducer,
	}
}
const mapDispatchToProps = dispatch => ({
	accountActions: bindActionCreators(accountActions, dispatch), appActions: bindActionCreators(appActions, dispatch),
})
export default connect(mapStateToProps, mapDispatchToProps)(Login)
