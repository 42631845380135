import Axios from 'axios'
import { onboardRequest } from './axiosInstances'

const validations = {
  async getUsersValidations(userID) {
    const params = {
      search: `user_id:${userID}`,
      include: "validations,ocrs"
    }

    try {
      const { data } = await onboardRequest.get('/admin/validations/ocrs', { params });
      return data;
    }
    catch(error) {
      return false;
    }
  },
  async getUserActivities(id) {
    try {
      const { data } = await onboardRequest.get('/admin/activities', {
        params: {
          search: `user_id:${id}`,
          include: "subject",
          sortedBy: "desc",
          withOrderBy: "",
          orderBy: "id",
          limit: 0,
        }
      });
      return data;
    }
    catch(error) {
      return false;
    }
  },
  async requestManualValidation(id) {
    try {
      const { data } = await onboardRequest.put('/admin/validations/ocrs/manual', {
        id,
      });
      return data;
    }
    catch(error) {
      return { error: true };
    }
  },
  async approveManualValidation(id) {
    try {
      const { data } = await onboardRequest.patch('/admin/validations/ocrs/manual', {
        id,
      });
      return data;
    }
    catch(error) {
      return { error: true };
    }
  },
}
export default validations;
