import api from './axiosInstances';

const orders = {
    async getOrders(payload) {
        try {
            const { data } = await api.get("/admin/orders", {
                params: {
	                order_by: 'created_at',
	                sort_by: 'desc',
                  per_page: 20,
                  ...payload,
                },
            })
            return data;
        }
        catch(error) {
            return false;
        }
    },
		async getTransactions(payload) {
			try {
				const { data } = await api.get("/admin/transactions", {
					params: {
						order_by: 'created_at',
						sort_by: 'desc',
						per_page: 20,
						...payload,
					},
				})
				return data;
			}
			catch(error) {
				return false;
			}
		},
    async getOrder(id) {
        try {
            const { data } = await api({
                method: "GET",
                url: '/admin/orders/' + id,
            })
            return data;
        }
        catch(error) {
            return false;
        }
    },
}
export default orders;
