import React, {useState, useEffect} from "react";
import moment from "moment";

import Loading from "../../../../components/Loading";
import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";
import "./style.scss";
import TagItem from "../../../../components/TagItem";
import Table from "../../../../components/Table";
import rules from "../../../../services/rules";
import {Switch} from "@mui/material";

const MusRules = ({concourse, jackpot = {}, activeTab}) => {
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({});
    const [loading, setLoading] = useState(false);
    const [loadingRows, setLoadingRows] = useState([])
    const [attachedRules, setAttachedRules] = useState([])
    
    useEffect(() => {
        getData();
        getAttachedRules();
    }, [activeTab]);
    
    const getData = async (withLoading = true) => {
        withLoading && setLoading(true);
        const {data} = await rules.getRules({status: 'active'});
        
        if (!data) return setLoading(false);
        
        setData(data.result);
        setPagination(data.pagination);
        // setSelectedRows(data.result);
        withLoading && setLoading(false);
    };
    
    const getAttachedRules = async () => {
        const {data} = await rules.getAttachedRules({jackpotID: jackpot?.id, concourseID: concourse?.id});
        
        setAttachedRules(data?.result?.map((item) => item?.id))
        
        if (!data) return setLoading(false);
    };
    
    const paginate = async (page) => {
        setLoading(true);
        const _input = {page, status: 'active'};
        const {data} = await rules.getRules(_input);
        
        if (!data) return setLoading(false);
        
        setData(data.result);
        setPagination(data.pagination);
        setLoading(false);
    };
    
    function toggleLoadingRow(id) {
        setLoadingRows(state => {
            if (state.includes(id)) {
                return state.filter((item) => item !== id)
            }
            
            return [...state, id]
        })
    }
    
    async function refetchAll() {
        await Promise.all([
            getAttachedRules(),
            getData(false)
        ])
    }
    
    async function handleSelectRow(row, isRuleAttached) {
        toggleLoadingRow(row?.id)
        
        await rules.attachRule({
            jackpotID: jackpot?.id,
            concourseID: concourse?.id,
            rule_id: row?.id
        })
        
        await refetchAll()
        
        toggleLoadingRow(row?.id)
    }
    
    return (
        <main id="rules">
            {loading && <Loading/>}
            
            <Collapse className="feedback" in={alert.show}>
                <Alert severity={alert.severity}>{alert.message}</Alert>
            </Collapse>
            
            <Table
                rows={data}
                loadingRows={loadingRows}
                renderRow={(row) => [
                    {
                        headerName: "Anexado", render: () => {
                            const isRuleAttached = attachedRules?.find((rules) => rules === row?.id)
                            
                            return (
                                <Switch color={'primary'} checked={!!isRuleAttached} onChange={() => handleSelectRow(row, isRuleAttached)} />
                            )
                        }
                    },
                    {headerName: "Nome", render: () => row?.name},
                    {headerName: "Qtd. MUS", render: () => row?.points},
                    {headerName: "Descrição", render: () => row?.description},
                    {headerName: "Modelo", render: () => row?.model === 'per_unit' ? 'Por cota' : 'Bateu ganhou'},
                    {headerName: "Data de criação", render: () => moment(row?.earned_date).format("DD/MM/YYYY HH:mm")},
                ]}
                pagination={pagination}
                paginate={paginate}
                loading={loading}
            />
        </main>
    );
};

export default MusRules;
