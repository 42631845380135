/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import jackpotServices   from '../../../../services/jackpots';
import Button   from '../../../../components/Button';

import Alert    from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';

import './style.scss';
import CurrencyInput from 'react-currency-input-field'
import RichEditor from '../../../../components/RichEditor'

const JackpotsEdit = (props) => {
    const [ channelType, setChannelType ] = useState('');
    const [ prizeAmount, setPrizeAmount ] = useState('');
    const [ description, setDescription ] = useState('');

    const [ alert,     setAlert ] = useState({ show: false, severity: 'info', message: '' });
    const [ loading, setLoading ] = useState(false);
    const { id, concourse } = useParams();

    useEffect(() => {
        const loadJackpot = async () => {
            const jackpot = await jackpotServices.getJackpot(concourse, id);
            setChannelType(jackpot.data.target_channel);
						setPrizeAmount(jackpot.data.prize_amount);
						setDescription(jackpot.data.descript);
        }
        loadJackpot()
    }, []);

    const submit = async (event) => {
        event.preventDefault();

        if(!channelType) {
            setAlert({ show: true, severity: 'warning', message: 'O campo Tipo do canal é obrigatório.' });
            setTimeout(() => setAlert({ show: false, severity: 'warning', message: '' }), 5000)
            return false;
        }

				if(!prizeAmount) {
					setAlert({ show: true, severity: 'warning', message: 'O campo Valor do prêmio é obrigatório.' });
					setTimeout(() => setAlert({ show: false, severity: 'warning', message: '' }), 5000)
					return false;
				}

				if(!description) {
					setAlert({ show: true, severity: 'warning', message: 'O campo Descrição é obrigatório.' });
					setTimeout(() => setAlert({ show: false, severity: 'warning', message: '' }), 5000)
					return false;
				}


        setLoading(true);
        const formData = new FormData();
        formData.append('target_channel', channelType);
        formData.append('prize_amount', prizeAmount);
        formData.append('description', description);

        const response = await jackpotServices.updateJackpot(formData, concourse, id);
        if(response) {
            setAlert({ show: true, severity: 'success', message: 'Atualizado com sucesso!' });
            setLoading(false);

            setTimeout(() => {
                setAlert({ show: false, severity: 'warning', message: '' });
            }, 2000);
        }
        else {
            setAlert({ show: true, severity: 'warning', message: 'Não conseguimos salvar esse bolão' });
            setLoading(false);
            setTimeout(() => { setAlert({ show: false, severity: 'warning', message: '' }); }, 5000);
        }

    }

    return (
        <main id="edit-jackpot">
            <form onSubmit={ submit } noValidate>
                <Collapse className="feedback" in={ alert.show }>
                    <Alert severity={ alert.severity }>{ alert.message }</Alert>
                </Collapse>

							<div className="row">
								<div className="col-6 form-group">
									<label htmlFor="channel-type">Tipo do canal:</label>
									<select name="channel-type" id="channel-type" className="form-control" value={channelType} onChange={event => setChannelType(event.target.value)}>
										<option value="">Selecione um canal</option>
										<option value="all">Todos</option>
										<option value="web_site">Site</option>
										<option value="bot_telegram">Telegram</option>
									</select>
								</div>
								<div className="col-6 form-group">
									<label htmlFor="prizeAmount">Valor do prêmio:</label>
									<CurrencyInput
										id="prizeAmount"
										name="prizeAmount"
										className="form-control"
										placeholder=""
										prefix="R$"
										groupSeparator="."
										decimalSeparator=","
										value={prizeAmount}
										defaultValue={0}
										decimalsLimit={2}
										onValueChange={(value, name) => setPrizeAmount(value)}
									/>
								</div>
							</div>
                <div className="row">
                    <div className="col-6 mb-20 form-group">
                        <label htmlFor="description">Descrição:</label>
                        <RichEditor className={'h-40'} value={description} onChange={setDescription}/>
                    </div>
                </div>
                <div className="form-group">
                    <Button type="submit" width="20%" color="green" label="Salvar" loading={loading}
                            disabled={loading}/>
                </div>
            </form>
        </main>);
}

export default JackpotsEdit;
