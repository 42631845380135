import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { history } from '../../../helpers/history';
import bannersSevices from '../../../services/banners';
import Loading from '../../../components/Loading';
import Button from '../../../components/Button';
import Title from '../../../components/Title';

import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';

import './style.scss';

const Banners = () => {
    const _status = [
        { "option": "draft", "label": "Rascunho" },
        { "option": "active", "label": "Ativo" },
        { "option": "inactive", "label": "Inativo" }
    ]

    const [banners, setBanners] = useState([]);
    const [banner, setBanner] = useState({});
    const [pagination, setPagination] = useState({});
    const [loading, setLoading] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [status, setStatus] = useState('');
    const [alert, setAlert] = useState({ show: false, severity: 'info', message: '' });

    useEffect(() => {
	    getBanners()
    }, []);

    const getBanners = async () => {
        setLoading(true);
        const { data } = await bannersSevices.getBanners({ page: 0 });
        if (data) {
            setBanners(data.result);
            setPagination(data.pagination);
        }
        setLoading(false);
    }

    const paginate = async (page) => {
        setLoading(true);
        const _input = { page, status, sort: 'date_desc' };
        const { data } = await bannersSevices.getBanners(_input);

        if (!data)
            return setLoading(false);

        setBanners(data.result);
        setPagination(data.pagination);
        setLoading(false);
    }

    const handleConfirm = (banner = {}) => {
        setBanner(banner);
        setConfirm(!confirm);
        return;
    }

    const removeBanner = async () => {
        setConfirm(false);
        const response = await bannersSevices.removeBanner(banner);
        if (response) {
            getBanners();
            setAlert({ show: true, severity: 'success', message: 'Banner removido com sucesso!' });
            setTimeout(() => { setAlert({ show: false, severity: 'warning', message: '' }); }, 5000);
        }
        else {
            setAlert({ show: true, severity: 'warning', message: 'Falha ao remover o banner.' });
            setTimeout(() => { setAlert({ show: false, severity: 'warning', message: '' }); }, 5000);
        }
    }

    return (
        <main id="banners">

            <Title label="Banners" />

            <div id="filter">
                <div className="inputs"></div>
                <Button type="button" color="green" label="Cadastrar" action={event => history.push('/campanhas/banners/novo')} />
            </div>

            <div id="infos">
                <span>Total de <strong>{pagination.total}</strong> banners encontrados.</span>
            </div>

            <div id="list">
                <Collapse className="feedback" in={alert.show}>
                    <Alert severity={alert.severity}>{alert.message}</Alert>
                </Collapse><br />

                <table className="table table-responsive-sm">
                    <thead>
                        <tr>
                            <th scope="col">Imagens</th>
                            <th scope="col">Título</th>
                            <th scope="col">Data de Início</th>
                            <th scope="col">Data de encerramento</th>
                            <th scope="col">Status</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            !loading && banners.length
                                ? banners.map(banner =>
                                (<tr key={banner.id}>
                                    <td className="images">
                                        {banner.image_desktop ? <figure><img src={banner.image_desktop} alt="" title="desktop" /></figure> : null}
                                        {banner.image_mobile ? <figure><img src={banner.image_mobile} alt="" title="mobile" /></figure> : null}
                                    </td>
                                    <td>{banner.title}</td>
                                    <td>{banner.start_date ? moment(banner.start_date, "DD-MM-YYYY HH:mm:ss").format("DD/MM/YYYY HH:mm") : 'Sem data definida'}</td>
                                    <td>{banner.end_date ? moment(banner.end_date, "DD-MM-YYYY HH:mm:ss").format("DD/MM/YYYY HH:mm") : 'Sem data definida'}</td>
                                    <td>{banner.status && _status.filter((item) => item.option === banner.status)[0].label}</td>
                                    <th scope="col" className="text-center">
                                        <Tooltip title="Editar" aria-label="edit"><EditOutlinedIcon className="link" onClick={event => history.push('/campanhas/banners/editar/' + banner.id)} /></Tooltip>
                                        <Tooltip title="Excluir" aria-label="delete"><DeleteForeverOutlinedIcon className="link" onClick={event => handleConfirm(banner)} /></Tooltip>
                                    </th>
                                </tr>))
                                : (<tr key="empty"><td colSpan="8" className="text-center empty"><span>Não há banners cadastrados</span></td></tr>)
                        }
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="8">
                                <div>
                                    {pagination.prev ? <ArrowBackIosIcon className="link" onClick={event => paginate(pagination.prev)} /> : null}
                                    {pagination.count ? <span className="labels">{pagination.current} de {pagination.total_pages} {pagination.total_pages > 1 ? 'Páginas' : 'Página'}</span> : null}
                                    {pagination.next ? <ArrowForwardIosIcon className="link" onClick={event => paginate(pagination.next)} /> : null}
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </table>

                {loading && <Loading />}
            </div>

            <Dialog open={confirm} onClose={handleConfirm} className="confirmation">
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">Confirmação</DialogTitle>
                <DialogContent>
                    <DialogContentText>Você deseja excluir esse banner?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <span className="link" onClick={handleConfirm} >Cancel</span>
                    <span className="link" onClick={removeBanner} >Sim!</span>
                </DialogActions>
            </Dialog>


        </main>
    );

}

export default Banners;
