import api from './axiosInstances';

const payments = {
    async getWithdrawals(params = {}) {
        try {
	        console.log(params.status)
          const { data } = await api.get('/admin/withdraws', {
            params: {
	            order_by: 'created_at',
	            sort_by: 'desc',
	            per_page: 20,
	            ...params,
	            ...(params.status && { status: params.status.join(',') }),
            },
          });

          return data;
        }
        catch(error) {
            console.log(error);
            return false;
        }
    },
    async doneWithdraw(params) {
        try {
            const _input   = { id: params.id, status: params.status };
            await api.put('/admin/withdraws/'+ params.id, _input);
            return true;
        }
        catch(error) {
            console.log(error);
            return false;
        }
    },
    async processManualWithdraw(id) {
        try {
            await api.post(`/admin/withdraws/${id}/manual-payment`);
            return true;
        }
        catch(error) {
            console.log(error);
            return false;
        }
    },
    async processWithdraws(array) {
        try{
            const response = await api.post('/admin/withdraws/payment',{batch:array});
            return response;
        }
        catch(error){
            console.log(error)
        }
    }
}
export default payments;
