import Axios from 'axios'
import api from './axiosInstances'
import { onboardRequest } from './axiosInstances'

const account = {
    async getMe() {
        try {
            const { data } = await api.get('/me');
            return data;
        }
        catch(error) {
            return false;
        }
    },
    async getOnboardProfile() {
        try {
            const { data } = await onboardRequest.get('/user/profile');
            return data;
        }
        catch(error) {
            return false;
        }
    },
}
export default account;
