/**
 * Essa funcionalidade foi implementada prevento um "Receipt" somente
 * porque o fluxo definido para esse momento é de 1 arquivo para cada bolão no back-end.
 *
 * Por esse motivo e pelo tempo de entrega (teríamos que mudar algumas coisas nas consultas do back)
 * foi implementado dessa forma. Mas para mudar com mais tempo será simples.
 */

import React, { useState, useEffect } from 'react';
import { TransitionGroup, CSSTransition } from "react-transition-group";
import moment from 'moment';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import Button from '../../../../components/Button';
import { history } from '../../../../helpers/history';
import currencyFormat from '../../../../helpers/currencyFormat';
import jackpotServices from '../../../../services/jackpots';
import Loading from '../../../../components/Loading';

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import './style.scss';

import JackpotsEdit from '../Edit';
import MusRules from "../../Concourses/View/MusRules";
import { useParams } from 'react-router-dom'

const JackpotSingle = (props) => {
	const {id, concourse} = useParams()
	const _status = [
		{ "option": "awaiting", "label": "Aguardando", },
		{ "option": "draft", "label": "Rascunho", },
		{ "option": "blocked", "label": "Bloqueado", },
		{ "option": "cancelled", "label": "Cancelado", },
		{ "option": "production", "label": "Em produção", },
		{ "option": "finished", "label": "Encerrado" }
	];

	const [loading, setLoading] = useState(false);
	const [loadingList, setLoadingList] = useState(false);
	const [finished, setFinished] = useState(false);
	const [jackpot, setJasckpot] = useState({});
	const [pagination, setPagination] = useState({});
	const [games, setGames] = useState([]);
    const [activeTab, setActiveTab] = useState("games");
	const lines = [];

	useEffect(() => {
		loadJackpot()
	}, []);

	const loadJackpot = async () => {
		setLoadingList(true);

		const _id = id;
		const _concourse = concourse;
		const _jackpot = await jackpotServices.getJackpot(_concourse, _id);
		setJasckpot(_jackpot.data);

		const _game = await jackpotServices.getGame({ jackpot: _id });
		setGames(_game.data.result);
		setPagination(_game.data.pagination);
		setFinished(_game.data.pagination.total > _game.data.pagination.per_page ? false : true)
		setLoadingList(false);
	}

	const loadMore = async () => {
		setLoading(true);
		const { data } = await jackpotServices.getGame({ jackpot: jackpot.id, page: pagination.next });
		let _array = games;

		if (data && data.result.length)
			await data.result.map((item) => _array.push(item));

		setLoading(false);
		data.pagination && setPagination(data.pagination);
		data.pagination && setFinished(data.pagination.next ? false : true);

	}

	return (
		<main id="view">

			<div id="title">
				<span className="back" onClick={event => history.back()}><ArrowBackIosIcon /> <span>Voltar</span></span>
				<h1>{jackpot.name}</h1>
			</div>

			<section id="data">
				<div className="row">
					<span><strong>Tipo: </strong>{jackpot.name}</span>
					<span><strong>Ref.: </strong>{jackpot.ref}</span>
					<span><strong>Concurso: </strong>{jackpot.sku}</span>
					<span><strong>Valor: </strong>{currencyFormat(jackpot.shares_sale_price)}</span>
					<span><strong>Status: </strong>{jackpot.status ? _status.filter((item) => item.option === jackpot.status)[0]?.label : ''}</span>
				</div>
				<div className="row">
					<span><strong>Prêmio: </strong>{currencyFormat(jackpot.prize_amount)}</span>
					<span><strong>Cotas: </strong>{jackpot.shares_quantity}</span>
					<span><strong>Início: </strong>{moment(jackpot.created_at, "DD-MM-YYYY HH:mm").format("DD/MM/YYYY HH:mm")}</span>
					<span><strong>Início: </strong>{moment(jackpot.start_date, "DD-MM-YYYY HH:mm").format("DD/MM/YYYY HH:mm")}</span>
					<span><strong>Final: </strong>{moment(jackpot.end_date, "DD-MM-YYYY HH:mm").format("DD/MM/YYYY HH:mm")}</span>
					<span><strong>Sorteio: </strong>{moment(jackpot.draw_date, "DD-MM-YYYY HH:mm").format("DD/MM/YYYY HH:mm")}</span>
				</div>
				<div className="row">
					<span className="descript" dangerouslySetInnerHTML={{ __html: jackpot.descript || '' }}></span>
				</div>
			</section>

			<Tabs defaultActiveKey="games" activeKey={activeTab} onSelect={setActiveTab} id="jackpot-tabs">
				<Tab eventKey="games" title="Jogos">
					<TransitionGroup>
						{
							(!loading && games.length) ? games.map((item, index) => {
								const numbers = item.dezenas.split(" ");
								return (
									<CSSTransition key={index} timeout={{ enter: index * 100, exit: index * 100 }} classNames={'fade'}>
										<div className="line" key={index} ref={ref => lines.push(ref)}>
											<span className="id">{item.id}</span>
											<span className="numbers">
                                                {numbers.map((num, i) => (<span key={i}>{num}</span>))}
												{item.complement ? (<span className="complement">{item.complement}</span>) : null}
                                            </span>
										</div>
									</CSSTransition>
								)
							}) : null
						}
						<span className="pagination">
                            {
	                            !finished
		                            ? <Button type="button" className="loadMore" color="green" label="Carregar mais jogos" action={loadMore} />
		                            : null
                            }
                        </span>
					</TransitionGroup>

					{loadingList ? <Loading /> : null}
				</Tab>

				<Tab eventKey="infos" title="Informações adicionais">
					<JackpotsEdit />
				</Tab>

                <Tab eventKey="musRules" title="Regras">
                    <MusRules activeTab={activeTab} jackpot={jackpot} concourse={{id: concourse}} />
                </Tab>
			</Tabs>

		</main>
	);
}

export default JackpotSingle;
