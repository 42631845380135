import React, { useEffect, useState } from 'react'
import moment from 'moment'

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import Button from '../../components/Button'
import { history } from '../../helpers/history'
import Table from '../../components/Table'
import TagItem from '../../components/TagItem'
import { ContentCopy, FilterAltOff } from '@mui/icons-material'
import DrawerFilters from '../../components/DrawerFilters'
import { getStatusItem, predefinedPastRanges } from '../../constants/default'
import couponService from '../../services/coupon'
import Tooltip from '@material-ui/core/Tooltip'
import { OpenInNew } from '@material-ui/icons'
import { NavLink } from 'react-router-dom'
import TrashIcon from '@material-ui/icons/DeleteOutline'
import affiliateService from '../../services/affiliate'
import { formatCurrency } from '../../helpers/formatCurrency'
import { formatStringByMask } from '../../helpers/formatStringByMask'
import formatDate from '../../helpers/formatDate'
import { CheckPicker, DateRangePicker, Input, MaskedInput } from 'rsuite'
import { cpfMask, phoneMask } from '../../constants/masks'
import { toast } from 'react-toastify'

function TableFilter({filters, newItemAction = null, newItemLabel = '', queryFn, ...props}) {
	const [data, setData] = useState({})
	const [loading, setLoading] = useState(false)
	const [openDrawerFilters, setOpenDrawerFilters] = useState(false)
	const [apiFilters, setApiFilters] = useState({})
	const filterCount = Object.entries(getFilters()).length || 0

	function getFilters() {
		const activeFilters = {}

		Object.entries(apiFilters).filter(([key, value]) => {
			const currentValue = value?.formatted || value.raw
			if (!currentValue || currentValue === '' || currentValue.length === 0) return false
			return true
		}).map(([key, value]) => {
			activeFilters[key] = value?.formatted || value.raw
		})

		return activeFilters
	}

	useEffect(() => {
		getTableData()
	}, [])

	const getTableData = async (reset = false) => {
		setLoading(true)

		const data = await queryFn({
			page: 1,

			...(!reset && getFilters()),
		})

		if (!data) {
			setLoading(false)
			setData([])
			return
		}

		setData(data)
		setLoading(false)
	}

	const paginate = async (page) => {
		setLoading(true)
		const payload = {
			page,
			...getFilters(),
		}

		await queryFn(payload).then(data => {
			if (!data) return setLoading(false)

			setData(data)
		}).finally(() => setLoading(false))
	}

	async function onSubmitFilters(reset = false) {
		if (reset) {
			await setApiFilters({})
		}

		setOpenDrawerFilters(false)
		await getTableData(reset)
	}

	return (
		<>
      {filters && (
        <>
          <DrawerFilters
            open={openDrawerFilters}
            setOpen={setOpenDrawerFilters}
            onSubmit={() => onSubmitFilters()}
            filters={filters(apiFilters, setApiFilters)}
          />
        </>
      )}

			<div className="flex flex-col items-end w-full mt-10 mb-4 gap-4">
        {filters && (
          <div className="flex items-center gap-2">
            {!!filterCount && (<FilterAltOff onClick={() => onSubmitFilters(true)} style={{ cursor: 'pointer', fill: '#209869' }}/>)}
            <Button
              small
              color="green"
              className={'min-w-[200px]'}
              action={setOpenDrawerFilters}
              label={`Aplicar filtros ${!!filterCount ? `(${filterCount})` : ''}`}
              disabled={loading}
            />
          </div>
        )}

				<div className={'flex flex-col items-end justify-center'}>
					{newItemAction && (
						<Button
							small
							color="green"
							className="min-w-[200px] mb-4"
							action={newItemAction}
							label={newItemLabel}
							disabled={loading}
						/>
					)}
					<span>Total de <strong>{data?.pagination?.total}</strong> registros encontrados.</span>
				</div>
			</div>

			<Table
				loading={loading}
				rows={data?.result}
				pagination={data?.pagination}
				paginate={paginate}
				{...props}
        renderRow={(row) => props.renderRow(row, getTableData)}
			/>
		</>
	)
}

const _filter = [
  { "value": 'paid', "label": 'Pago' },
  { "value": 'canceled', "label": 'Cancelado' },
  { "value": 'pending', "label": 'Pendente' },
]

const AffiliatesSales = () => {
  async function handleExclude(row, refetch) {
    await couponService.toggleStatusCoupon(row)
    await refetch()
  }

	return (
		<main id="withdraw">
			<div id="title">
        <span className="back" onClick={() => history.back()}>
          <ArrowBackIosIcon/>
          <span>Voltar</span>
        </span>
				<h1>Comissões</h1>
			</div>

			<TableFilter
				queryFn={affiliateService.getComissions}
        filters={(apiFilters, setApiFilters) => ([
          {
            label: 'Email',
            name: 'customer_email',
            field: () => (
              <Input
                id="input-email"
                value={apiFilters?.customer_email?.raw}
                onChange={(value) =>
                  setApiFilters((state) => ({
                      ...state,
                      customer_email: {
                        raw: value,
                      },
                    })
                  )}
                placeholder="Email do cliente"
                type='email' className="w-full"
              />
            ),
          },
          {
            label: 'ID da transação',
            name: 'uuid',
            field: () => (
              <Input
                value={apiFilters?.uuid?.raw}
                onChange={(value) =>
                  setApiFilters((state) => ({
                    ...state,
                    uuid: {
                      raw: value,
                    },
                  })
                )}
                placeholder="ID da transação"
                className="w-full"
              />
            ),
          },
          {
            label: "CPF",
            name: "customer_cpf",
            field: () => (
              <MaskedInput
                id="input-cpf"
                value={apiFilters?.customer_cpf?.raw}
                onChange={(value) =>
                  setApiFilters((state) => ({
                      ...state,
                      customer_cpf: {
                        raw: value,
                        formatted: value?.replace(/\D/g, ''),
                      },
                    })
                  )}
                placeholder="CPF do cliente"
                mask={cpfMask} className="w-full"
              />
            ),
          },
          {
            label: "Data de criação",
            name: "range_date",
            field: () => (
              <DateRangePicker
                placement="auto"
                id="input-period"
                value={apiFilters?.range_date?.raw}
                format="dd/MM/yyyy"
                onChange={(value) =>
                  setApiFilters((state) => {
                    return ({
                      ...state,
                      range_date: {
                        raw: value,
                        formatted: encodeURI([moment(value[0]).format('YYYY-MM-DD'), moment(value[1]).format('YYYY-MM-DD')]),
                      }
                    })
                  })
                }
                showOneCalendar
                ranges={predefinedPastRanges}
                placeholder="Escolha um período"
                className='w-full'
              />
            ),
          },
          {
            label: "Data de pagamento",
            name: "paid_at_range",
            field: () => (
              <DateRangePicker
                placement="auto"
                id="input-period"
                value={apiFilters?.paid_at_range?.raw}
                format="dd/MM/yyyy"
                onChange={(value) =>
                  setApiFilters((state) => {
                    return ({
                      ...state,
                      paid_at_range: {
                        raw: value,
                        formatted: encodeURI([moment(value[0]).format('YYYY-MM-DD'), moment(value[1]).format('YYYY-MM-DD')]),
                      }
                    })
                  })
                }
                showOneCalendar
                ranges={predefinedPastRanges}
                placeholder="Escolha um período"
                className='w-full'
              />
            ),
          },
          {
            label: "Status",
            name: "status",
            field: () => (
              <CheckPicker
                autoComplete="false"
                id="input-status"
                value={apiFilters?.status?.raw?.split(',')}
                onChange={(value) =>
                  setApiFilters((state) => ({
                      ...state,
                      status: {
                        raw: value.join(','),
                      },
                    })
                  )}
                placeholder="Escolha um status"
                searchable={false}
                sticky
                className={'w-full'}
                data={_filter}
              />
            ),
          },
        ])}
        renderRow={(row, refetch) => [
          { headerName: 'ID', field: 'id' },
          { headerName: 'Valor compra', render: () => formatCurrency(row?.order_total_amount) },
          { headerName: 'Comissão', render: () => formatCurrency(row?.amount) },
          { headerName: 'Email do usuário', render: () => row?.user?.email },
          { headerName: 'CPF do usuário', render: () => formatStringByMask('XXX.XXX.XXX-XX', row?.user?.cpf) },
          { headerName: 'Status', render: () => <TagItem item={getStatusItem(row.status)}/> },
          {
            headerName: "Cód. da transação", render: () => <div className="flex items-center gap-1">
              <div style={{ width: 100 }} className='truncate' title={row.uuid || '-'}>
                {row.uuid || '-'}
              </div>
              {row.uuid && (
                <span className='flex-shrink-0 cursor-pointer'>
                  <ContentCopy onClick={() => {
                    navigator.clipboard.writeText(row.uuid)
                    toast.success("Código copiado com sucesso!")
                  }} style={{ fontSize: 18, fill: "#209869" }}/>
                </span>
              )}
            </div>
          }, { headerName: 'Data compra', render: () => formatDate(row.created_at) }, { headerName: 'Data liberação', render: () => formatDate(row.locked_until) },]}
      />
    </main>)
}

export default AffiliatesSales
