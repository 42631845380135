import { types }        from './types';
import accountServices  from '../../services/account';

export function me() {
    return async dispatch => {
        try {
            const { data } = await accountServices.getMe();
            dispatch({ type: types.SET_USER_DATA, userData: data.customer.personal_information });
						const storage = JSON.parse(localStorage.getItem('unindo_sonhos_admin_data'));
						localStorage.setItem("unindo_sonhos_admin_data", JSON.stringify({ ...storage, user: data.customer.personal_information }))

            return data.customer;
        }
        catch(error) {
            console.log(error);
        }
    }
};
export function logout() {
    return async dispatch => {
        try {
            dispatch({ type: types.SET_USER_DATA, userData: { } });
            localStorage.removeItem('unindo_sonhos_admin_data');
            const { data } = await accountServices.getMe();
            return data;
        }
        catch(error) {
            console.log(error);
        }
    }
};
export function reset() {
    return async dispatch => {
        dispatch({ type: types.RESET });
        return true;
    }
};
