import Title from '../../../components/Title';
import React, {useState} from "react";
import termsService from "../../../services/terms";
import {history} from "../../../helpers/history";
import Button from "../../../components/Button";
import Alert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import rules from "../../../services/rules";

const RulesNew = () => {
    const [name, setName] = useState('')
    const [value, setValue] = useState('')
    const [model, setModel] = useState('per_unit')
    const [description, setDescription] = useState('')
    const [loading, setLoading] = useState(false)
    const [conditions, setConditions] = useState({
        id: 'qty',
        condition_id: 'qty',
        type: 'criterion',
    })
    const [alert, setAlert] = useState({show: false, severity: 'info', message: ''});
    
    // type, id, value, clause
    
    const onSubmit = async (event) => {
        event.preventDefault();
        
        console.log({
            name,
            value,
            description,
            model,
            conditions,
        })
        
        if (!name) {
            setAlert({show: true, severity: 'warning', message: 'O campo Nome é obrigatório.'});
            setTimeout(() => setAlert({show: false, severity: 'warning', message: ''}), 5000)
            return false;
        }
        
        if (!model) {
            setAlert({show: true, severity: 'warning', message: 'O campo Modelo é obrigatório.'});
            setTimeout(() => setAlert({show: false, severity: 'warning', message: ''}), 5000)
            return false;
        }
        
        if (!conditions?.id || !conditions.input?.clause || !conditions.input?.value) {
            setAlert({show: true, severity: 'warning', message: 'O campo de Condicionais é obrigatório.'});
            setTimeout(() => setAlert({show: false, severity: 'warning', message: ''}), 5000)
            return false;
        }
        
        setLoading(true);
        
        const response = await rules.addRule({
            name,
            value,
            description,
            model,
            conditions,
        });

        if (response) {
            setAlert({show: true, severity: 'success', message: 'Cadastrado com sucesso!'});
            setLoading(false);

            setTimeout(() => {
                setAlert({show: false, severity: 'warning', message: ''});
                history.push('/rules');
            }, 3000);
        } else {
            setAlert({
                show: true,
                severity: 'warning',
                message: 'Não conseguimos salvar essa regra. Tente novamente mais tarde.'
            });
            setLoading(false);
            setTimeout(() => {
                setAlert({show: false, severity: 'warning', message: ''});
            }, 5000);
        }
    }
    
    return (
        <main id="new-rule">
            <Title label="Cadastrar Regra"/>
            
            <Collapse className="feedback" in={ alert.show }>
                <Alert severity={ alert.severity }>{ alert.message }</Alert>
            </Collapse>
            
            <form onSubmit={onSubmit} noValidate>
                <div className="row mb-4 mt-12">
                    <div className="col-6 form-group">
                        <label htmlFor="number">Nome:</label>
                        <input
                            type="text"
                            className="form-control"
                            id="banner-title"
                            required
                            value={name}
                            onChange={e => setName(e.target.value)}
                        />
                    </div>
                    <div className="col-6 form-group">
                        <label htmlFor="name">Modelo:</label>
                        <select
                            name="banner-status"
                            id="banner-status"
                            onChange={e => setModel(e.target.value)}
                            className="form-control"
                            value={model}
                        >
                            <option value="">
                                Selecione
                            </option>
                            <option value="hit_win">Bateu ganhou</option>
                            <option value="per_unit">Por cota</option>
                        </select>
                        <small className={'text-gray-400 text-xs'}>
                            <ul>
                                <li>
                                    Bateu ganhou: Escolha esta opção se desejar que o usuário ganhe uma quantidade fixa de pontos, independentemente da quantidade de produtos comprados.        
                                </li>
                                <br/>
                                <li>
                                    Por cota: Selecione esta opção para configurar de acordo com a quantidade de cotas compradas, onde a quantidade de pontos ganhos varia de acordo com cada cota adquirida.
                                </li>
                            </ul>
                            
                        </small>
                    </div>
                </div>
                
                <div className="row mb-4">
                    <div className="col-12 form-group">
                        <div className="flex flex-col">
                            <label htmlFor="number">Descrição:</label>
                        </div>
                        <textarea 
                            className="form-control" 
                            id="banner-title"
                            required 
                            value={description}
                            onChange={e => setDescription(e.target.value)}
                        />
                        <small className={'text-gray-400 text-xs'}>
                            Esta descrição será exibida para o usuário ao visualizar o bolão.
                        </small>
                    </div>
                </div>
                
                <div className="row mb-4">
                    <div className="col-12 form-group">
                        <div className="flex flex-col">
                            <label htmlFor="number">Quantidade MUS:</label>
                        </div>
                        <input type="number" className="form-control" id="banner-title" required value={value}
                               onChange={e => setValue(e.target.value)}/>
                        <small className={'text-gray-400 text-xs'}>Digite a quantidade de pontos MUS que o usuário
                            ganhará.</small>
                    </div>
                </div>
                
                <label>Condicionais:</label>
                <div className="row mb-4">
                    <div className="col-4 form-group">
                        <select
                            name="banner-status"
                            id="banner-status"
                            className="form-control"
                            value={conditions?.id}
                            onChange={e => setConditions(prev => ({
                                ...prev,
                                id: e.target.value,
                                condition_id: e.target.value,
                            }))}
                        >
                            <option value="">
                                Selecione
                            </option>
                            <option value="qty">Quantidade</option>
                        </select>
                    </div>
                    <div className="col-4 form-group">
                        <select
                            name="banner-status"
                            id="banner-status"
                            className="form-control"
                            value={conditions?.input?.clause}
                            onChange={e => setConditions(prev => ({
                                ...prev,
                                input: {
                                    ...prev.input,
                                    clause: e.target.value
                                }
                            }))}
                        >
                            <option value="">
                                Selecione
                            </option>
                            <option value="eq">
                                Igual
                            </option>
                            <option value="gteq">
                                Maior ou igual
                            </option>
                            <option value="lteq">
                                Menor igual
                            </option>
                            <option value="gt">
                                Maior que
                            </option>
                            <option value="lt">
                                Menor que
                            </option>
                        </select>
                    </div>
                    <div className="col-4 form-group">
                        <input
                            type="number"
                            placeholder={'Quantidade de cotas'}
                            className="form-control"
                            id="banner-title"
                            required
                            value={conditions?.input?.value}
                            onChange={e => setConditions(prev => ({
                                ...prev,
                                input: {
                                    ...prev.input,
                                    value: e.target.value
                                }
                            }))}
                        />
                        <small className={'text-gray-400 text-xs'}>
                            Digite a quantidade de cotas necessárias para aplicar a regra.
                        </small>
                    </div>
                </div>
                
                <div className="form-group">
                    <Button
                        type="submit"
                        width="40%"
                        color="green submit-button"
                        label="Cadastrar"
                        loading={loading}
                        disabled={loading}
                    />
                </div>
            </form>
        
        </main>
    );
}

export default RulesNew;