import api from './axiosInstances';

const depositionsService = {
    async getAllDepositionsService(params) {
        try {
            let _url  = process.env.REACT_APP_API_ONBOARD_URL + '/testimonials?limit=20&filter=id;user_name;pagination;user_avatar;created_at;status;statement&sortedBy=desc&orderBy=created_at';
                _url += (params.page) ? '&page='+ params.page : '' ;
            const { data } = await api.get(_url);
            return data;
        }
        catch(error) {
            return false;
        }
    },
    async updateDeposition(id, status) {
      try {
          const url = process.env.REACT_APP_API_ONBOARD_URL + `/testimonials/${id}/${status}`;
          await api.patch(url);
          return true;
      }
      catch(error) {
          console.log(error);
      }
    },
    async deleteDeposition(id) {
      try {
          const url = process.env.REACT_APP_API_ONBOARD_URL + `/testimonials/${id}`;
          await api.delete(url);
          return true;
      }
      catch(error) {
          console.log(error);
      }
    },
}
export default depositionsService;
