import api from './axiosInstances';

const affiliateService = {
    async getComissions(payload) {
      try {
        const { data } = await api.get('/admin/affiliates/commissions', {
          params: payload,
        });
        return data?.data;
      }
      catch(error) {
        return error
        console.log(error)
      }
    },
    async transformUser(payload) {
      try {
        const { data } = await api.post('/admin/affiliates/transform', {
          user_id: payload.id,
        });
        return data?.data;
      }
      catch(error) {
        return error
        console.log(error)
      }
    },
    async getMetrics(payload) {
      try {
        const { data } = await api.get('/admin/affiliates/metrics', payload);
        return data?.data;
      }
      catch(error) {
        return error
        console.log(error)
      }
    },
    async getDetails(payload) {
      try {
        const { data } = await api.get(`/admin/affiliates/${payload.id}`);
        return data;
      }
      catch(error) {
        return error
        console.log(error)
      }
    },
    async toggleAffiliate(payload) {
      try {
        const { data } = await api.post(`/admin/affiliates/${payload.id}/toggle-status`);
        return data;
      }
      catch(error) {
        return error
        console.log(error)
      }
    },
}
export default affiliateService;
