import { Drawer } from 'rsuite'
import Button from '../Button'

export default function DrawerFilters({open, onSubmit, filters, setOpen}) {
  return (
    <Drawer size='lg' open={open} onClose={() => setOpen(false)}>
      <Drawer.Header>
        <Drawer.Title>Filtros</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body style={{ padding: 20 }}>
        <form className="grid grid-cols-2 gap-4">
          {filters.map((filter) => {
            return (
              <div key={filter.name}>
                <label htmlFor={`input-${filter.name}`} className="text-sm font-medium">{filter.label}</label>
                {filter.field()}
              </div>
            )
          })}
          <div className='flex items-center col-span-2'>
            <Button
              color="green"
              label="Aplicar"
              action={onSubmit}
            />
          </div>
        </form>
      </Drawer.Body>
    </Drawer>
  )
}
