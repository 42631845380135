import { types } from './types';

export function toggleMenu() {
    return async dispatch => {
        dispatch({ type: types.OPEN_MENU });
        return true;
    }
};
export function alertSessionExpired() {
    return async dispatch => {
        dispatch({ type: types.SESSION_EXPIRED });
        return true;
    }
};