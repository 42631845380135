export function nFormatter(num, digits) {
  if (!num) return "0";
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "Kb" },
    { value: 1e6, symbol: "Mb" },
    { value: 1e9, symbol: "Gb" },
    { value: 1e12, symbol: "Tb" },
    { value: 1e15, symbol: "Pb" },
    { value: 1e18, symbol: "Eb" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits || 1).replace(rx, "$1") + item.symbol
    : "0";
}