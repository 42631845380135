import React, { Fragment }  from 'react';
import AppRoutes               from './routes';
import { ToastContainer } from 'react-toastify';

import './assets/tailwind.css';
import 'rsuite/dist/rsuite.min.css';
import 'react-toastify/dist/ReactToastify.min.css';
import "react-step-progress-bar/styles.css";
import './assets/rsuite.css';
import './assets/sass/app.scss';
import ptBR from 'rsuite/cjs/locales/pt_BR.js'
import { CustomProvider } from 'rsuite'
import {createTheme, ThemeProvider} from "@mui/material";
import { NextUIProvider } from '@nextui-org/react'
import { useNavigate } from 'react-router-dom'

function App() {
	const navigate = useNavigate();

  const muiTheme = createTheme({
      palette: {
          primary: {
              main: '#209869',
          },
      },
  });

  return (
    <ThemeProvider theme={muiTheme}>
      <ToastContainer />
      <CustomProvider locale={ptBR}>
        <NextUIProvider locale="pt-BR" navigate={navigate}>
	        <AppRoutes />
        </NextUIProvider>
      </CustomProvider>
    </ThemeProvider>
  );
}

export default App;
