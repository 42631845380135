import { types } from './types';
const initialState  = { userData: { } };

const reducer = (state = initialState, action = { }) => { 
    switch(action.type) {
        case types.SET_USER_DATA:
            return { ...state, userData: action.userData };
        case types.RESET:
            return initialState;
        default:
            return state;
    }
}
export default reducer;