import React, { useState } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './style.scss'

export default function RichEditor(props) {
  return (
		<ReactQuill className="w-full" readOnly={props.disabled} scrollingContainer theme="snow" value={props.value} onChange={props.onChange} {...props} />
  );
}
