import api from './axiosInstances';

const rules = {
    async getRules(params) {
        try {
            let _url = '/admin/mus-reward-rule?';
            _url += params && params.page       ? "&page="          + params.page       : "";
            _url += params && params.status     ? "&status="        + params.status     : "";
            _url += params && params.search     ? "&q="             + params.search     : "";
            const { data } = await api.get(_url,params);
            return data;
        }
        catch(error) {
            console.log(error);
            return false;
        }
    },
    async getAttachedRules({jackpotID, concourseID}) {
        try {
            let _url = `/admin/concourses/${concourseID}/mus-reward-rule`;
            
            if (jackpotID) {
                _url = `/admin/concourses/${concourseID}/jackpots/${jackpotID}/mus-reward-rule`;
            }

            const { data } = await api.get(_url);
            return data;
        }
        catch(error) {
            console.log(error);
            return false;
        }
    },
    async addRule(params) {
        try {
            const { data } = await api.post('/admin/mus-reward-rule/create', params);
            
            return data
        }
        catch(error) {
            console.log(error);
        }
    },
    async updateConfig(params) {
        try {
            const { data } = await api.post('/admin/mus-reward-config', params);
            
            return data
        }
        catch(error) {
            console.log(error);
        }
    },
    async getConfig() {
        try {
            const { data } = await api.get('/admin/mus-reward-config');
            
            return data?.data
        }
        catch(error) {
            console.log(error);
        }
    },
    async attachRule({rule_id, concourseID, jackpotID}) {
        try {
            if (jackpotID) {
                const { data } = await api.post(`/admin/concourses/${concourseID}/jackpots/${jackpotID}/mus-reward-rule/attach`, {
                    rule_id
                });
                
                return data
            }
            
            if (concourseID) {
                const { data } = await api.post(`/admin/concourses/${concourseID}/mus-reward-rule/attach`, {
                    rule_id
                });

                return data
            }
        }
        catch(error) {
            console.log(error);
        }
    }
}
export default rules;
